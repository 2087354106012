<script>
	import Select from 'svelte-select';
	import { sentenceCase } from 'change-case';
	import { jsonldArticleType } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldArticleType } from '../../stores/jsonld/UpdateJsonldArticleType';

	let jsonldArticleTypeValue;
	let selectedValue = {};

	const options = ['Article', 'NewsArticle', 'BlogPosting'].map((value) => ({
		value,
		label: sentenceCase(value),
	}));

	jsonldArticleType.subscribe((value) => {
		jsonldArticleTypeValue = value;
		selectedValue = options.find((item) => value === item.value);
	});

	const jsonldArticleTypeChange = (event) => updateJsonldArticleType(event?.detail?.value || '');
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldArticleType">Article Type:</label>
	<Select
		name="jsonldArticleType"
		items={options}
		value={selectedValue}
		on:select={jsonldArticleTypeChange}
		on:clear={jsonldArticleTypeChange}
	/>
</div>
