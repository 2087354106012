<script>
	import { jsonldBrandName } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldBrandName } from '../../stores/jsonld/UpdateJsonldBrandName';

	let jsonldBrandNameValue;

	jsonldBrandName.subscribe((value) => {
		jsonldBrandNameValue = value;
	});

	const onJsonldBrandNameChange = (event) => updateJsonldBrandName(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldBrandName">Brand Name:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldBrandName"
		value={jsonldBrandNameValue}
		on:input={onJsonldBrandNameChange}
	/>
</div>
