<script>
	import { oembedData } from '../../stores/oembed/OembedStore';

	let oembedDataValue;
	let container;

	const updatePreview = () => {
		// console.log(oembedDataValue);
		if (!container) {
			return;
		}
		if (oembedDataValue?.html) {
			container.innerHTML = oembedDataValue?.html;
		} else {
			container.innerHTML = '';
		}
	};

	oembedData.subscribe((value) => {
		oembedDataValue = value;
		updatePreview();
	});
</script>

<div data-oembed bind:this={container} class={oembedDataValue?.html ? '' : 'hidden'} />
