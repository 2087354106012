<script>
	import { jsonldAddressCountry } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAddressCountry } from '../../stores/jsonld/UpdateJsonldAddressCountry';

	let jsonldAddressCountryValue;

	jsonldAddressCountry.subscribe((value) => {
		jsonldAddressCountryValue = value;
	});

	const onJsonldAddressCountryChange = (event) => updateJsonldAddressCountry(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAddressCountry">Address Country:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAddressCountry"
		value={jsonldAddressCountryValue}
		on:input={onJsonldAddressCountryChange}
	/>
</div>
