<script>
	import {
		bodyBgGradient1,
		bodyBgGradient2,
		bodyBgGradientAngle,
		bodyBgGradientCode,
		bodyBgWidth,
		bodyBgBase64,
		bodyBgPattern,
		bodyBgSizeIsActive,
		bodyLayoutWidth,
		bodyLayoutWidthMin,
		bodyLayoutWidthMax,
	} from '../../stores/body-bg/BodyBgStore';

	import { bodyTextColor, bodyTextRadius } from '../../stores/body-text/BodyTextStore';

	// body bg store data
	let bodyBgGradientCodeValue;
	let bodyBgGradientAngleValue;
	let bodyBgGradient1Value;
	let bodyBgGradient2Value;
	let bodyBgSizeIsActiveValue;
	let bodyBgWidthValue;
	let bodyBgBase64Value;
	let bodyBgPatternValue;
	let bodyLayoutWidthValue;
	let bodyLayoutWidthMinValue;
	let bodyLayoutWidthMaxValue;

	// body text store data
	let bodyTextColorValue;
	let bodyTextRadiusValue;

	const clearStyles = (ele) => {
		ele.style.removeProperty('background');
		ele.style.removeProperty('background-color');
		ele.style.removeProperty('background-image');
		ele.style.removeProperty('background-repeat');
		ele.style.removeProperty('background-size');
	};

	const updateBodyBgStyles = async () => {
		const eleBody = document.body;

		// clear styles
		clearStyles(eleBody);

		// apply flat color
		if (bodyBgGradient1Value) {
			eleBody.style.backgroundColor = bodyBgGradient1Value;
		}

		// apply gradient
		if (bodyBgGradient1Value && bodyBgGradient2Value) {
			eleBody.style.backgroundImage = `linear-gradient(${
				bodyBgGradientAngleValue || 0
			}deg, ${bodyBgGradient1Value} 0%, ${bodyBgGradient2Value} 100%)`;
		}

		// apply gradient raw code
		if (bodyBgGradientCodeValue) {
			const doc = document.implementation.createHTMLDocument('');
			doc.body.style.cssText = bodyBgGradientCodeValue;
			eleBody.style.background = doc.body.style.background;
			eleBody.style.backgroundImage = doc.body.style.backgroundImage;
		}

		// apply bg pattern
		if (bodyBgBase64Value || bodyBgPatternValue?.upload?.url) {
			// eleBody.style.backgroundColor = 'transparent';
			eleBody.style.backgroundSize = 'cover';
			eleBody.style.backgroundRepeat = 'repeat';
			if (bodyBgSizeIsActiveValue) {
				eleBody.style.backgroundSize = `${bodyBgWidthValue}rem`;
			}
			if (bodyBgBase64Value) {
				eleBody.style.backgroundImage = `url(${bodyBgBase64Value})`;
			}
			if (!bodyBgBase64Value && bodyBgPatternValue?.upload?.url) {
				eleBody.style.backgroundImage = `url(${bodyBgPatternValue.upload.url})`;
			}
		}
	};

	const updateBodyTextStyles = async () => {
		const eleEditor = document.getElementById('main');
		if (!eleEditor) {
			return;
		}

		clearStyles(eleEditor);

		// body text border radius
		if (bodyTextRadiusValue !== undefined) {
			eleEditor.style.borderRadius = `${bodyTextRadiusValue}px`;
		}

		// body text background color
		if (bodyTextColorValue) {
			eleEditor.style.backgroundColor = bodyTextColorValue;
		}
	};

	const updateBodyLayoutStyles = async () => {
		const ele = document.getElementById('main');
		if (!ele) {
			return;
		}
		ele.style.maxWidth = '100%';
		ele.style.maxWidth = `min(${bodyLayoutWidthValue}rem, 100vw)`;
	};

	/**
	 * Apply current styles to body
	 */
	const updateStyles = async () => {
		updateBodyBgStyles();
		updateBodyTextStyles();
		updateBodyLayoutStyles();
	};

	// =========================================================================
	// Body Bg Subscriptions
	// =========================================================================

	bodyBgGradientCode.subscribe((value) => {
		bodyBgGradientCodeValue = value;
		updateStyles();
	});

	bodyBgGradientAngle.subscribe((value) => {
		bodyBgGradientAngleValue = value;
		updateStyles();
	});

	bodyBgGradient1.subscribe((value) => {
		bodyBgGradient1Value = value;
		updateStyles();
	});

	bodyBgGradient2.subscribe((value) => {
		bodyBgGradient2Value = value;
		updateStyles();
	});

	bodyBgSizeIsActive.subscribe((value) => {
		bodyBgSizeIsActiveValue = value;
		updateStyles();
	});

	bodyBgWidth.subscribe((value) => {
		bodyBgWidthValue = value;
		updateStyles();
	});

	bodyBgBase64.subscribe((value) => {
		bodyBgBase64Value = value;
		updateStyles();
	});

	bodyBgPattern.subscribe((value) => {
		bodyBgPatternValue = value;
		updateStyles();
	});

	// =========================================================================
	// Body Text Subscriptions
	// =========================================================================

	bodyTextColor.subscribe((value) => {
		bodyTextColorValue = value;
		updateStyles();
	});

	bodyTextRadius.subscribe((value) => {
		bodyTextRadiusValue = value;
		updateStyles();
	});

	// =========================================================================
	// Body Layout Width
	// =========================================================================

	bodyLayoutWidth.subscribe((value) => {
		bodyLayoutWidthValue = value;
		updateStyles();
	});

	bodyLayoutWidthMin.subscribe((value) => {
		bodyLayoutWidthMinValue = value;
		updateStyles();
	});

	bodyLayoutWidthMax.subscribe((value) => {
		bodyLayoutWidthMaxValue = value;
		updateStyles();
	});
</script>
