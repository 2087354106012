<script>
	import { jsonldRecipeCuisine } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldRecipeCuisine } from '../../stores/jsonld/UpdateJsonldRecipeCuisine';

	let jsonldRecipeCuisineValue;

	jsonldRecipeCuisine.subscribe((value) => {
		jsonldRecipeCuisineValue = value;
	});

	const onJsonldRecipeCuisineChange = (event) => updateJsonldRecipeCuisine(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldRecipeCuisine">Cuisine:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldRecipeCuisine"
		value={jsonldRecipeCuisineValue}
		on:input={onJsonldRecipeCuisineChange}
	/>
</div>
