<script>
	import IconRemove from '../../icons/IconRemove.svelte';
	import { getIkResponseData, getSelectedImage, uploadToImageKit } from '../../utils';

	import { seoFavicon } from '../../stores/seo/SeoStore';
	import { seoFaviconDefault } from '../../stores/seo/SeoStoreDefaults';
	import { updateSeoFavicon } from '../../stores/seo/UpdateSeoFavicon';

	let seoFaviconValue;
	let file;
	let isBusy = false;

	seoFavicon.subscribe((value) => {
		seoFaviconValue = value;
	});

	const onClear = () => {
		updateSeoFavicon(seoFaviconDefault);
	};

	const onImageChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}
		try {
			const ikResponse = await uploadToImageKit(file, file.name);
			updateSeoFavicon(getIkResponseData(file, ikResponse));
		} catch (err) {
			console.log('Favicon change error - ', err);
			return;
		}
	};
</script>

<div class="proteus-control proteus-group">
	<label for="seo-favicon-file" class="proteus-file-zone">
		<span class="proteus-file-zone-name">
			{file || seoFaviconValue?.upload?.url ? 'Change Favicon' : 'Select Favicon'}
		</span>
		<input
			type="file"
			on:input={onImageChange}
			name="seo-favicon"
			id="seo-favicon-file"
			disabled={isBusy}
			class="proteus-file-zone-file"
		/>
		{#if file || seoFaviconValue?.upload?.url}
			<span on:click|preventDefault={onClear} class="proteus-file-zone-remove">
				<IconRemove />
			</span>
		{/if}
	</label>
</div>
