<svg
	class="svg-icon"
	viewBox="0 0 1024 1024"
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M768.794 298.83L588.2 479.425 545.576 436.8l180.593-180.594H578.52V195.97H829.03v250.51h-60.236V298.83zM300.831 766.795h147.65v60.236H197.97V576.52h60.236v147.65L438.8 543.575l42.624 42.624-180.593 180.594z m-42.625-510.588v212.826H197.97V195.97h273.062v60.236H258.206z m510.588 297.762h60.236V827.03H555.968v-60.236h212.826V553.968z"
	/>
</svg>
