<script>
	import { jsonldGitHub } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldGitHub } from '../../stores/jsonld/UpdateJsonldGitHub';

	let jsonldGitHubValue;

	jsonldGitHub.subscribe((value) => {
		jsonldGitHubValue = value;
	});

	const onJsonldGitHubChange = (event) => updateJsonldGitHub(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldGitHub">GitHub:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldGitHub"
		value={jsonldGitHubValue}
		on:input={onJsonldGitHubChange}
	/>
</div>
