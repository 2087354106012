<script>
	import { onMount } from 'svelte';
	import axios from 'axios';
	import localStorage from 'localforage';
	import LoaderFullscreen from './LoaderFullscreen.svelte';

	import { authIsBusy, authIsLoggedIn } from './stores/auth/AuthStore';
	import { updateAuthIsBusy } from './stores/auth/UpdateAuthIsBusy';
	import { updateAuthIsLoggedIn } from './stores/auth/UpdateAuthIsLoggedIn';
	import { updateAuthUsername } from './stores/auth/UpdateAuthUsername';
	import { updateAuthFirstName } from './stores/auth/UpdateAuthFirstName';
	import { updateAuthLastName } from './stores/auth/UpdateAuthLastName';
	import { updateAuthAvatarId } from './stores/auth/UpdateAuthAvatarId';
	import { updateAuthAvatarUrl } from './stores/auth/UpdateAuthAvatarUrl';
	import { updateAuthAvatarThumbUrl } from './stores/auth/UpdateAuthAvatarThumbUrl';
	import { updateAuthAddress } from './stores/auth/UpdateAuthAddress';

	export let hasLoader = false;

	let authIsBusyValue;
	let authIsLoggedInValue;

	authIsBusy.subscribe((value) => {
		authIsBusyValue = value;
	});

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});

	onMount(async () => {
		if (authIsLoggedInValue) {
			return;
		}
		let response;
		updateAuthIsBusy(true);
		try {
			const { id, userId, otp, domain } = (await localStorage.getItem('otp')) || {};
			// console.log({ id, userId, otp, domain });
			if (!id || !userId || !otp) {
				updateAuthIsBusy(false);
				return;
			}
			response = await axios.post('/api/register', { id, username: userId, otp, domain });
			const userData = response.data.user;
			updateAuthIsLoggedIn(true);
			updateAuthUsername(userData.id);
			updateAuthFirstName(userData.firstName);
			updateAuthLastName(userData.lastName);
			updateAuthAvatarId(userData.avatarId);
			updateAuthAvatarUrl(userData.avatarUrl);
			updateAuthAvatarThumbUrl(userData.avatarThumbUrl);
			updateAuthAddress(userData.address);
		} catch (err) {
			console.log('Error validating OTP', err);
			updateAuthIsLoggedIn(false);
		}
		updateAuthIsBusy(false);
	});
</script>

{#if authIsBusyValue && hasLoader}
	<LoaderFullscreen />
{/if}
