<script>
	import classNames from 'classnames';
	import { isToolbarOembedActive } from '../../stores/toolbar/ToolbarStore';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';
	import ToolbarOembedStyler from './ToolbarOembedStyler.svelte';
	import ToolbarOembedUrl from './ToolbarOembedUrl.svelte';
	import ToolbarOembedSubmit from './ToolbarOembedSubmit.svelte';
	import ToolbarOembedClear from './ToolbarOembedClear.svelte';
	import ToolbarOembedPreview from './ToolbarOembedPreview.svelte';
	import { authSubscription } from '../../stores/auth/AuthStore';
	import SubscribeButton from '../../SubscribeButton.svelte';
	import SubscribeNotice from '../../SubscribeNotice.svelte';

	let isTrial;
	let authSubscriptionValue;
	let isToolbarOembedActiveValue;

	authSubscription.subscribe((value) => {
		authSubscriptionValue = value;
		isTrial = value?.status !== 'active';
	});

	isToolbarOembedActive.subscribe((value) => {
		isToolbarOembedActiveValue = value;
	});
</script>

<ToolbarOembedStyler />
<div class={classNames('proteus-modal', { 'is-active': isToolbarOembedActiveValue })}>
	<ToolbarOverlay />
	<div class="proteus-modal-dialog">
		<ToolbarOembedUrl />
		<ToolbarOembedPreview />
		{#if !isTrial}
			<div class="proteus-control proteus-group proteus-toolbar-dropdown-footer">
				<ToolbarOembedClear />
				<ToolbarOembedSubmit />
			</div>
		{/if}
		{#if isTrial}
			<SubscribeNotice title="embedded YouTube/Vimeo videos" />
			<SubscribeButton />
		{/if}
	</div>
</div>
