<script>
	import IconFontSizeMinus from '../../icons/IconFontSizeMinus.svelte';

	import { bodyLayoutWidth, bodyLayoutWidthMin } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyLayoutWidth } from '../../stores/body-bg/UpdateBodyLayoutWidth';

	let bodyLayoutWidthValue;
	let bodyLayoutWidthMinValue;

	bodyLayoutWidth.subscribe((value) => {
		bodyLayoutWidthValue = value;
	});

	bodyLayoutWidthMin.subscribe((value) => {
		bodyLayoutWidthMinValue = value;
	});

	const onClick = () => {
		if (bodyLayoutWidthValue > bodyLayoutWidthMinValue) {
			updateBodyLayoutWidth(bodyLayoutWidthValue - 1);
		}
	};
</script>

<button class="proteus-toolbar-button" on:click={onClick} disabled={bodyLayoutWidthValue <= bodyLayoutWidthMinValue}>
	<IconFontSizeMinus />
</button>
