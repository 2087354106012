// border radius
export const bodyShadowColorDefault = '';

// x
export const bodyShadowXDefault = 0;

// y
export const bodyShadowYDefault = 0;

// blur
export const bodyShadowBlurDefault = 0;

// spread
export const bodyShadowSpreadDefault = 0;
