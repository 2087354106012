<script>
	import { jsonldYouTube } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldYouTube } from '../../stores/jsonld/UpdateJsonldYouTube';

	let jsonldYouTubeValue;

	jsonldYouTube.subscribe((value) => {
		jsonldYouTubeValue = value;
	});

	const onJsonldYouTubeChange = (event) => updateJsonldYouTube(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldYouTube">YouTube:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldYouTube"
		value={jsonldYouTubeValue}
		on:input={onJsonldYouTubeChange}
	/>
</div>
