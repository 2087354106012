<script>
	import { bodyTextRadius } from '../../stores/body-text/BodyTextStore';
	import { updateBodyTextRadius } from '../../stores/body-text/UpdateBodyTextRadius';
	import { resetBodyTextRadius } from '../../stores/body-text/ResetBodyTextRadius';

	let bodyTextRadiusValue;

	bodyTextRadius.subscribe((value) => {
		bodyTextRadiusValue = value;
	});

	const onBodyTextRadiusChange = (event) => updateBodyTextRadius(event.target.value);

	const onBodyTextRadiusReset = () => resetBodyTextRadius();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Border Radius: {bodyTextRadiusValue}px
		<span class="proteus-reset" on:click={onBodyTextRadiusReset}>Reset</span>
	</label>
	<input
		type="range"
		min={0}
		step={1}
		max={100}
		name="angle"
		value={bodyTextRadiusValue}
		on:input={onBodyTextRadiusChange}
	/>
</div>
