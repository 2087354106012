<script>
	import axios from 'axios';
	import { onMount } from 'svelte';
	import { get } from 'lodash';
	import localStorage from 'localforage';
	import isEmpty from 'validator/lib/isEmpty';
	import isAlphanumeric from 'validator/lib/isAlphanumeric';
	import isFQDN from 'validator/lib/isFQDN';
	import IconNext from '../../icons/IconNext.svelte';
	import IconThreeDots from '../../icons/IconThreeDots.svelte';

	import { isToolbarSaveActive } from '../../stores/toolbar/ToolbarStore';
	import {
		publishIsBusy,
		publishDomain,
		publishSubdomain,
		publishTitle,
		publishDescription,
		publishPayload,
		publishIsPublished,
	} from '../../stores/publish/PublishStore';

	import { updatePublishError } from '../../stores/publish/UpdatePublishError';
	import { updatePublishIsBusy } from '../../stores/publish/UpdatePublishIsBusy';
	import { updatePublishUrl } from '../../stores/publish/UpdatePublishUrl';
	import { updatePublishIsPublished } from '../../stores/publish/UpdatePublishIsPublished';
	import { updateMenuItems } from '../../stores/menu/UpdateMenuItems';
	import { updateToolbarSaveAs } from '../../stores/toolbar/UpdateToolbarSaveAs';
	import { updateToolbarSave } from '../../stores/toolbar/UpdateToolbarSave';

	let publishIsBusyValue;
	let publishDomainValue;
	let publishSubdomainValue;
	let publishTitleValue;
	let publishDescriptionValue;
	let publishPayloadValue;
	let publishIsPublishedValue;
	let isToolbarSaveActiveValue;
	let domain;
	let subdomain;

	onMount(async () => {
		domain = await localStorage.getItem('domain');
		subdomain = await localStorage.getItem('subdomain');
	});

	const isFormValid = () => {
		if (isEmpty(domain || '') && isEmpty(subdomain || '')) {
			updatePublishError(new Error('Invalid page name'));
			return false;
		}
		if (isEmpty(publishTitleValue)) {
			updatePublishError(new Error('Invalid title'));
			return false;
		}
		if (isEmpty(publishDescriptionValue)) {
			updatePublishError(new Error('Invalid description'));
			return false;
		}
		return true;
	};

	/**
	 * @summary
	 * Rebuild ham menu and page navigation
	 * Basically we set the new subdomain / sub subdomain and then init once more
	 *
	 * @description
	 * the following 2 functions
	 * buildMenu
	 * getUserSites
	 * are defined in index.html
	 * They are used here to make sure we don't duplicate code all over again
	 */
	const reloadHamMenuAndPageNav = async () => {
		let deployDomain = publishPayloadValue.subdomain;
		if (publishPayloadValue.subSubdomain) {
			deployDomain = `${publishPayloadValue.subSubdomain}.${publishPayloadValue.subdomain}`;
		}
		window.subdomain = deployDomain;
		window.initMenu();
	};

	const onPublish = async () => {
		if (!isFormValid()) {
			return;
		}
		updatePublishError();
		updatePublishIsBusy(true);
		try {
			const { id, userId, otp } = (await localStorage.getItem('otp')) || {};
			const subdomain = await localStorage.getItem('subdomain');
			const response = await axios.post('/api/publish', {
				...publishPayloadValue,
				otpId: id,
				userId,
				otp,
				subSubdomain: subdomain,
			});
			const liveUrl = get(response, 'data.url');
			updatePublishUrl(liveUrl);
			updateToolbarSave(false);
			updateToolbarSaveAs(false);
			updatePublishIsPublished(true);
			reloadHamMenuAndPageNav();
		} catch (err) {
			const msg = 'Could not validate OTP';
			console.log(msg, err);
			updatePublishError(new Error(get(err, 'response.data.message', msg)));
		}
		updatePublishIsBusy(false);
	};

	/**
	 * @summary
	 * if site was published once in this session - then clicking on the save
	 * button should save instantly
	 */
	isToolbarSaveActive.subscribe((value) => {
		isToolbarSaveActiveValue = value;
		if (value && publishIsPublishedValue) {
			onPublish();
		}
	});

	publishIsBusy.subscribe((value) => {
		publishIsBusyValue = value;
	});

	publishDomain.subscribe((value) => {
		publishDomainValue = value;
	});

	publishSubdomain.subscribe((value) => {
		publishSubdomainValue = value;
	});

	publishTitle.subscribe((value) => {
		publishTitleValue = value;
	});

	publishDescription.subscribe((value) => {
		publishDescriptionValue = value;
	});

	publishPayload.subscribe((value) => {
		publishPayloadValue = value;
	});

	publishIsPublished.subscribe((value) => {
		publishIsPublishedValue = value;
	});
</script>

<div class="proteus-control proteus-group">
	<button class="proteus-button is-primary" on:click={onPublish}>
		{#if publishIsBusyValue}
			<IconThreeDots />
		{:else}
			Continue &nbsp;
			<IconNext />
		{/if}
	</button>
</div>
