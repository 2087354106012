<script>
	export let title;
	export let text = 'Subscribe to 4t4 premium to unlock';
</script>

<h3>{text} {title}</h3>

<style>
	h3 {
		margin: 1rem auto;
		color: var(--theme-secondary);
		text-align: center;
	}
</style>
