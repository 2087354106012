<script>
	import localStorage from 'localforage';
	import { onMount } from 'svelte';
	import { publishDomain, publishTitle, publishDescription } from '../../stores/publish/PublishStore';
	import {
		seoFavicon,
		seoOgTitle,
		seoOgDescription,
		seoOgImage,
		seoOgType,
		seoOgTwitterCard,
		seoOgTwitterTitle,
		seoOgTwitterDescription,
		seoOgTwitterImage,
		seoOgTwitterSite,
		seoOgTwitterCreator,
	} from '../../stores/seo/SeoStore';

	let url;
	let domain;
	let subdomain;

	let publishDomainValue;
	let publishTitleValue;
	let publishDescriptionValue;
	let seoFaviconValue;
	let seoOgTitleValue;
	let seoOgDescriptionValue;
	let seoOgImageValue;
	let seoOgTypeValue;
	let seoOgTwitterCardValue;
	let seoOgTwitterTitleValue;
	let seoOgTwitterDescriptionValue;
	let seoOgTwitterImageValue;
	let seoOgTwitterSiteValue;
	let seoOgTwitterCreatorValue;

	onMount(async () => {
		domain = await localStorage.getItem('domain');
		subdomain = await localStorage.getItem('subdomain');
	});

	const setOgMetaTag = (name, value) => {
		let ele = document.querySelector(`meta[name="${name}"]`);
		if (!ele) {
			ele = document.createElement('meta');
			ele.setAttribute('name', name);
			document.head.appendChild(ele);
		}
		ele.setAttribute('content', value);
	};

	const updateUrl = async () => {
		if (subdomain) {
			url = `https://${publishDomainValue}.${subdomain}.4t4.io`;
		} else {
			url = `https://${publishDomainValue}.4t4.io`;
		}
		setOgMetaTag('og:url', url);
	};

	const updateStyles = () => {
		updateUrl();

		if (publishTitleValue) {
			document.title = publishTitleValue;
		}

		if (publishDescriptionValue) {
			document.querySelector('meta[name="description"]').content = publishDescriptionValue;
		}

		if (seoFaviconValue?.upload?.url) {
			document.querySelector('link[rel="icon"]').href = seoFaviconValue.upload.url;
		}

		if (seoOgImageValue?.upload?.url) {
			setOgMetaTag('og:image', `${seoOgImageValue.upload.url}?tr=w-1200,h-630,c-maintain_ratio`);
		}

		if (seoOgTitleValue) {
			setOgMetaTag('og:title', seoOgTitleValue);
		}

		if (seoOgDescriptionValue) {
			setOgMetaTag('og:description', seoOgDescriptionValue);
		}

		if (seoOgTypeValue) {
			setOgMetaTag('og:type', seoOgTypeValue);
		}

		if (seoOgTwitterCardValue) {
			setOgMetaTag('twitter:card', seoOgTwitterCardValue);
		}

		if (seoOgTwitterTitleValue) {
			setOgMetaTag('twitter:title', seoOgTwitterTitleValue);
		}

		if (seoOgTwitterDescriptionValue) {
			setOgMetaTag('twitter:description', seoOgTwitterDescriptionValue);
		}

		if (seoOgTwitterImageValue?.upload?.url) {
			setOgMetaTag('twitter:image', `${seoOgTwitterImageValue.upload.url}?1`);
		}

		if (seoOgTwitterSiteValue) {
			setOgMetaTag('twitter:site', seoOgTwitterSiteValue);
		}

		if (seoOgTwitterCreatorValue) {
			setOgMetaTag('twitter:creator', seoOgTwitterCreatorValue);
		}
	};

	publishDomain.subscribe((value) => {
		publishDomainValue = value;
		updateStyles();
	});

	publishTitle.subscribe((value) => {
		publishTitleValue = value;
		updateStyles();
	});

	publishDescription.subscribe((value) => {
		publishDescriptionValue = value;
		updateStyles();
	});

	seoFavicon.subscribe((value) => {
		seoFaviconValue = value;
		updateStyles();
	});

	seoOgTitle.subscribe((value) => {
		seoOgTitleValue = value;
		updateStyles();
	});

	seoOgDescription.subscribe((value) => {
		seoOgDescriptionValue = value;
		updateStyles();
	});

	seoOgImage.subscribe((value) => {
		seoOgImageValue = value;
		updateStyles();
	});

	seoOgType.subscribe((value) => {
		seoOgTypeValue = value;
		updateStyles();
	});

	seoOgTwitterCard.subscribe((value) => {
		seoOgTwitterCardValue = value;
		updateStyles();
	});

	seoOgTwitterTitle.subscribe((value) => {
		seoOgTwitterTitleValue = value;
		updateStyles();
	});

	seoOgTwitterDescription.subscribe((value) => {
		seoOgTwitterDescriptionValue = value;
		updateStyles();
	});

	seoOgTwitterImage.subscribe((value) => {
		seoOgTwitterImageValue = value;
		updateStyles();
	});

	seoOgTwitterSite.subscribe((value) => {
		seoOgTwitterSiteValue = value;
		updateStyles();
	});

	seoOgTwitterCreator.subscribe((value) => {
		seoOgTwitterCreatorValue = value;
		updateStyles();
	});
</script>
