import { writable } from 'svelte/store';
import { bodyShadowBlurDefault, bodyShadowColorDefault, bodyShadowSpreadDefault, bodyShadowXDefault, bodyShadowYDefault } from './BodyShadowStoreDefaults';

// border radius
export const bodyShadowColor = writable(bodyShadowColorDefault);

// x
export const bodyShadowX = writable(bodyShadowXDefault);

// y
export const bodyShadowY = writable(bodyShadowYDefault);

// blur
export const bodyShadowBlur = writable(bodyShadowBlurDefault);

// spread
export const bodyShadowSpread = writable(bodyShadowSpreadDefault);
