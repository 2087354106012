<script>
	import { jsonldPerformerUrl } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldPerformerUrl } from '../../stores/jsonld/UpdateJsonldPerformerUrl';

	let jsonldPerformerUrlValue;

	jsonldPerformerUrl.subscribe((value) => {
		jsonldPerformerUrlValue = value;
	});

	const onJsonldPerformerUrlChange = (event) => updateJsonldPerformerUrl(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldPerformerUrl">Performer Url:</label>
	<input class="proteus-input" type="text" name="jsonldPerformerUrl" value={jsonldPerformerUrlValue} on:input={onJsonldPerformerUrlChange} />
</div>
