<script>
	import Message from '../../Message.svelte';
	import { publishError } from '../../stores/publish/PublishStore';

	let publishErrorValue;

	publishError.subscribe((value) => {
		publishErrorValue = value;
	});
</script>

{#if publishErrorValue}
	<Message msg={publishErrorValue.message} type="error" />
{/if}
