<script>
	import IconImage from '../../icons/IconImage.svelte';

	import { editorFocusedEle } from '../../stores/editor/EditorStore';
	import { isToolbarImageActive } from '../../stores/toolbar/ToolbarStore';
	import { updateToolbarImage } from '../../stores/toolbar/UpdateToolbarImage';

	let editorFocusedEleValue;
	let isToolbarImageActiveValue;

	editorFocusedEle.subscribe((value) => {
		editorFocusedEleValue = value;
	});

	isToolbarImageActive.subscribe((value) => {
		isToolbarImageActiveValue = value;
	});

	const onToggle = () => updateToolbarImage(!isToolbarImageActiveValue);
</script>

<button class="proteus-toolbar-button is-add-image" on:click={onToggle} disabled={!editorFocusedEleValue} title="Add Image">
	<IconImage />
</button>
