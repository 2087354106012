// all links
export const menuItemsDefault = [];

// active index
export const menuActiveIndexDefault = undefined;

// title
export const menuLinkTitleDefault = '';

// url
export const menuLinkUrlDefault = '';
