<script>
	import { jsonldInstagram } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldInstagram } from '../../stores/jsonld/UpdateJsonldInstagram';

	let jsonldInstagramValue;

	jsonldInstagram.subscribe((value) => {
		jsonldInstagramValue = value;
	});

	const onJsonldInstagramChange = (event) => updateJsonldInstagram(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldInstagram">Instagram:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldInstagram"
		value={jsonldInstagramValue}
		on:input={onJsonldInstagramChange}
	/>
</div>
