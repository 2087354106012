<script>
	import { removeEleFromDom } from '../../utils';

	import { updateToolbarStripe } from '../../stores/toolbar/UpdateToolbarStripe';
	import { stripeId } from '../../stores/stripe/StripeStore';
	import { resetStripe } from '../../stores/stripe/ResetStripe';

	let stripeIdValue;

	stripeId.subscribe((value) => {
		stripeIdValue = value;
	});

	const onClear = () => {
		removeEleFromDom(stripeIdValue);
		resetStripe();
		updateToolbarStripe(false);
	};
</script>

<div class="proteus-control proteus-group">
	<button class="proteus-button is-default" on:click={onClear}>Clear</button>
</div>
