<script>
	import {
		headerBgGradient1,
		headerBgGradient2,
		headerBgGradientAngle,
		headerBgGradientCode,
		headerBgBase64,
		headerBgPattern,
	} from '../../stores/header-bg/HeaderBgStore';

	import {
		headerLogoStart,
		headerLogoStartBase64,
		headerLogoCenter,
		headerLogoCenterBase64,
	} from '../../stores/header-logo/HeaderLogoStore';

	// header bg store data
	let headerBgGradientCodeValue;
	let headerBgGradientAngleValue;
	let headerBgGradient1Value;
	let headerBgGradient2Value;
	let headerBgBase64Value;
	let headerBgPatternValue;

	// header logo store data
	let headerLogoStartValue;
	let headerLogoStartBase64Value;
	let headerLogoCenterValue;
	let headerLogoCenterBase64Value;

	const clearStyles = (ele) => {
		ele.style.removeProperty('background');
		ele.style.removeProperty('background-color');
		ele.style.removeProperty('background-image');
		ele.style.removeProperty('background-repeat');
		ele.style.removeProperty('background-size');
	};

	/**
	 * @summary
	 * Update live preview
	 *
	 * @param selector {string} - target logo css selector
	 * @param upload {object} - image upload data with props - title, alt, upload: { id, url }
	 * @param base64 {object} - base 64 encoded iamge data - object with props - { id, base64 }
	 */
	const updateLivePreview = (selector, upload, base64) => {
		let alt = '';
		let title = '';
		let src = '';

		// console.log({ selector, upload, base64 });

		if (base64) {
			src = base64;
		}

		if (upload && upload.upload) {
			src = upload.upload.url;
			alt = upload.alt;
			title = upload.title;
		}

		// create new responsive logo div
		const eleDiv = document.createElement('div');

		// get existing logo
		const eleLogo = document.getElementById(selector);
		if (!eleLogo) {
			return;
		}

		// clear existing logo
		eleLogo.innerHTML = '';

		if (!src) {
			return;
		}

		// creaate new logo image
		const eleImg = document.createElement('img');

		// clear existing with and height
		eleImg.removeAttribute('width');
		eleImg.removeAttribute('height');

		// update base attributes
		eleImg.setAttribute('src', src);
		eleImg.setAttribute('title', title);
		eleImg.setAttribute('alt', alt);

		// place new logo
		eleDiv.appendChild(eleImg);
		eleLogo.appendChild(eleImg);

		// // add width and height to the image
		const bbox = eleImg.getBoundingClientRect();
		eleImg.setAttribute('width', upload?.upload?.width || bbox.width || bbox.height);
		eleImg.setAttribute('height', upload?.upload?.height || bbox.height);
	};

	const updateHeaderBgStyles = async () => {
		const eleHeader = document.getElementById('header');
		if (!eleHeader) {
			return;
		}

		// clear styles
		clearStyles(eleHeader);

		const color1 = headerBgGradient1Value === 'hsla(180, 50, 50, 1)' ? 'transparent' : headerBgGradient1Value;
		const color2 = headerBgGradient2Value === 'hsla(180, 50, 50, 1)' ? 'transparent' : headerBgGradient2Value;

		// apply flat color
		if (color1) {
			eleHeader.style.backgroundColor = color1;
		}

		// apply gradient
		if (color2 !== 'transparent') {
			eleHeader.style.removeProperty('background');
			eleHeader.style.backgroundImage = `linear-gradient(${
				headerBgGradientAngleValue || 0
			}deg, ${color1} 0%,  ${color2} 100%)`;
		}

		// apply gradient raw code
		if (headerBgGradientCodeValue) {
			const doc = document.implementation.createHTMLDocument('');
			doc.body.style.cssText = headerBgGradientCodeValue;
			eleHeader.style.background = doc.body.style.background;
			eleHeader.style.backgroundImage = doc.body.style.backgroundImage;
		}

		// apply bg pattern
		if (headerBgBase64Value || headerBgPatternValue?.upload?.url) {
			// eleHeader.style.backgroundColor = 'transparent';
			eleHeader.style.backgroundSize = 'cover';
			eleHeader.style.backgroundRepeat = 'repeat';
			if (headerBgBase64Value) {
				eleHeader.style.backgroundImage = `url(${headerBgBase64Value})`;
			}
			if (!headerBgBase64Value && headerBgPatternValue?.upload?.url) {
				eleHeader.style.backgroundImage = `url(${headerBgPatternValue.upload.url})`;
			}
		}
	};

	const updateHeaderLogoStyles = async () => {};

	/**
	 * Apply current styles to body
	 */
	const updateStyles = async (data) => {
		updateHeaderBgStyles();
		updateHeaderLogoStyles();
	};

	// =========================================================================
	// Header Bg Subscriptions
	// =========================================================================

	headerBgGradientCode.subscribe((value) => {
		headerBgGradientCodeValue = value;
		updateStyles();
	});

	headerBgGradientAngle.subscribe((value) => {
		headerBgGradientAngleValue = value;
		updateStyles();
	});

	headerBgGradient1.subscribe((value) => {
		headerBgGradient1Value = value;
		updateStyles();
	});

	headerBgGradient2.subscribe((value) => {
		headerBgGradient2Value = value;
		updateStyles();
	});

	headerBgBase64.subscribe((value) => {
		headerBgBase64Value = value;
		updateStyles();
	});

	headerBgPattern.subscribe((value) => {
		headerBgPatternValue = value;
		updateStyles();
	});

	// =========================================================================
	// Header logo Subscriptions
	// =========================================================================

	headerLogoStart.subscribe((value) => {
		headerLogoStartValue = value;
		updateLivePreview('logo-start', value, headerLogoStartBase64Value);
	});

	headerLogoCenter.subscribe((value) => {
		headerLogoCenterValue = value;
		updateLivePreview('logo-center', value, headerLogoCenterBase64Value);
	});

	headerLogoStartBase64.subscribe((value) => {
		headerLogoStartBase64Value = value;
		updateLivePreview('logo-start', headerLogoStartValue, value);
	});

	headerLogoCenterBase64.subscribe((value) => {
		headerLogoCenterBase64Value = value;
		updateLivePreview('logo-center', headerLogoCenterValue, value);
	});
</script>
