<script>
	import SitesMenu from './SitesMenu.svelte';
</script>

<header id="header" class="header">
	<a href="/" id="logo-start" class="logo logo-start">
		<!--  -->
	</a>
	<a href="/" id="logo-center" class="logo logo-center">
		<!--  -->
	</a>
	<a href="/" id="logo-end" class="logo logo-end">
		<!--  -->
	</a>
	<SitesMenu />
</header>
