import axios from 'axios';
import moment from 'moment';
import localStorage from 'localforage';
import ImageKit from 'imagekit-javascript';
import { IMAGEKIT_PUBLIC_KEY, IMAGEKIT_ENDPOINT } from '../../env-svelte';

import { genericErrorMessage } from '../../lang/en';

/**
 * @summary
 * Format bytes
 *
 * @param bytes number of bytes
 * @returns {string} Formatted file size
 */
export const bytesToSize = (bytes, isRounded = true) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes == 0) {
		return '0 Byte';
	}
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	if (isRounded) {
		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	}
	let value = bytes / Math.pow(1024, i);
	if (value != parseInt(value.toFixed(2))) {
		value = value.toFixed(2);
	} else {
		value = parseInt(value.toString());
	}
	return value + ' ' + sizes[i];
};

/**
 * @summary
 * Handle file change event
 *
 * @description
 * Make sure that a valid image type has been selected
 *
 * @param {Event} event - the event object
 * @param {Number} maxSize - the max filesize in bytes
 *
 * @returns {void}
 */
export const getSelectedImage = (event, maxSize = 1 * 1024 * 1024) => {
	if (!event.target || !event.target.files || !event.target.files.length) {
		return alert('Please select a valid jpg / png / svg image');
	}
	const selectedFile = event.target.files[0];
	if (!selectedFile || ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(selectedFile.type) === false) {
		event.target.value = '';
		return alert('Please select a valid jpg / png / svg image');
	}
	if (!selectedFile || isNaN(selectedFile.size) === true || selectedFile.size > maxSize) {
		event.target.value = '';
		return alert(`Maximum allowed file size is ${bytesToSize(maxSize)}`);
	}
	return selectedFile;
};

/**
 * @summary
 * Get the provided file content as Base64 data string
 *
 * @param {File} file the file object
 */
export const getImageAsBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

/**
 * @summary
 * Upload image to server
 *
 * @returns {Promise}
 */
export const uploadImage = async (file) =>
	new Promise(async (resolve, reject) => {
		let data = null;
		const { size, type, name } = file;
		const alt = name;
		const title = name;
		try {
			data = await getImageAsBase64(file);
			const response = await axios.post('/api/upload', { data, size, type, title, alt, shouldResize: false });
			resolve({ ...response.data, alt, title, original: { size, type, name } });
		} catch (error) {
			alert('Something went wrong trying to upload image, please try again');
			console.log('uploadImage - error', error);
			reject(new Error(error.message));
		}
	});

/**
 * @summary
 * Get ImageKit instance
 *
 * @returns {Object}
 */
export const getImageKit = () =>
	new ImageKit({
		publicKey: IMAGEKIT_PUBLIC_KEY,
		urlEndpoint: IMAGEKIT_ENDPOINT,
		authenticationEndpoint: '/api/signature',
	});

/**
 * @summary
 * Upload image to ImageKit
 *
 * @param {File} file HTML File element to upload - must have a file
 * @param {String} fileName The image filename
 * @param {String[]} tags Array of image tags
 * @returns {Object}
 */
export const uploadToImageKit = (file, fileName, tags = []) =>
	new Promise((resolve, reject) => {
		const ik = getImageKit();
		ik.upload({ file, fileName, tags }, (err, result) => {
			if (err) {
				console.log('getImageKit error', err);
				return reject(err);
			}
			return resolve(result);
		});
	});

/**
 * @summary
 * Detect touch screen devices like mobiles, tablets
 *
 * @see
 * https://stackoverflow.com/questions/7995752/detect-desktop-browser-not-mobile-with-javascript
 *
 * @returns {boolean}
 */
export const isTouchDevice = () => 'ontouchstart' in window || 'onmsgesturechange' in window;

/**
 * @summary
 * Detect iOS device
 *
 * @see
 * https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
 *
 * @returns {boolean}
 */
export const isIOS = () =>
	['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
	// iPad on iOS 13 detection
	(navigator.userAgent.includes('Mac') && 'ontouchend' in document);

/**
 * @summary
 * Detect large devices like desktop
 *
 * @see
 * https://stackoverflow.com/questions/7995752/detect-desktop-browser-not-mobile-with-javascript
 *
 * @returns {boolean}
 */
export const isDesktop = () => (window.screenX != 0 && !isTouchDevice() ? true : false);

/**
 * @summary
 * Extract required data from a valid ImageKit upload request
 *
 * @description
 * This should be used with try catch block
 *
 * @param {File} file The raw html file object
 * @param {Object} ikResponse ImageKit HTTTP Response
 * @returns
 */
export const getIkResponseData = (file, ikResponse, imgAlt = '', imgTitle = '') => ({
	upload: ikResponse,
	width: ikResponse.width,
	height: ikResponse.height,
	url: ikResponse.url,
	alt: imgAlt,
	title: imgTitle,
	original: {
		size: file.size,
		type: file.type,
		name: file.name,
	},
	metadata: {},
});

/**
 * @summary
 * Remove the target element from DOM
 *
 * @param {string} id The ID of the element to remove
 */
export const removeEleFromDom = (id) => {
	const ele = document.getElementById(id);
	ele?.remove();
};

/**
 * @summary
 * Get image dimension from source data
 *
 * @param src {string} either url or base64 src of image
 * @returns {Promise} object with width and height on success
 */
const getImageDimensions = async (src) =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			resolve({ width: img.width, height: img.height });
		};
		img.onabort = (err) => {
			console.log('getImageDimensions error', err);
			reject(err);
		};
		img.src = src;
	});

/**
 * @summary
 * Get subdomain provided via query args in url or stored in local storage
 *
 * @returns {string|undefined}
 */
export const getActiveSubdomain = async () => {
	let subdomain = new URLSearchParams(location.search).get('subdomain');
	if (subdomain) {
		return subdomain;
	}
	subdomain = await localStorage.getItem('subdomain');
	return subdomain;
};

/**
 * @summary
 * Get domain provided via query args in url or stored in local storage
 *
 * @returns {string|undefined}
 */
export const getActiveDomain = async () => {
	let domain = new URLSearchParams(location.search).get('domain');
	if (domain) {
		return domain;
	}
	domain = await localStorage.getItem('domain');
	return domain;
};

/**
 * @summary
 * Get YouTube oembed url
 *
 * @see
 * https://stackoverflow.com/questions/10896233/how-can-i-retrieve-youtube-video-details-from-video-url-using-php
 *
 * @param {string} url the source url
 * @returns {Object} the oembed data if available else undefined
 */
export const getYouTubeOembed = async (url) => {
	const oembedUrl = `https://www.youtube.com/oembed?url=${url}&format=json`;
	try {
		const response = await fetch(oembedUrl);
		const json = await response.json();
		return json;
	} catch (err) {
		console.log('getYouTubeOembed - error - ', err);
	}
};

/**
 * @summary
 * Get Vimeo oembed url
 *
 * @see
 * https://developer.vimeo.com/api/oembed/videos
 *
 * @param {string} url the source url
 * @returns {Object} the oembed data if available else undefined
 */
export const getVimeoOembed = async (url) => {
	const oembedUrl = `https://vimeo.com/api/oembed.json?url=${url}&format=json`;
	try {
		const response = await fetch(oembedUrl);
		const json = await response.json();
		return json;
	} catch (err) {
		console.log('getYouTubeOembed - error - ', err);
	}
};

/**
 * @summary
 * Fetch oembded data for the provided url
 *
 * @supports
 * YouTube
 * Vimeo
 *
 * @param {string} url the source url
 * @returns {Object} the oembded data if available else undefined
 */
export const getOembedData = async (url) => {
	const matches = url.match(/(youtube|youtu|vimeo)/gi) || [];
	if (matches.length === 0) {
		return;
	}
	let oembed;
	if (matches.includes('youtube') || matches.includes('youtu')) {
		oembed = await getYouTubeOembed(url);
	}
	if (matches.includes('vimeo')) {
		oembed = await getVimeoOembed(url);
	}
	return oembed;
};

export const getOtpLoginData = async () => {
	const { id: otpId, userId: username, otp } = (await localStorage.getItem('otp')) || {};
	return { otpId, otp, username };
};

/**
 * @summary
 * Add success notification
 *
 * @param {string} message The message
 */
export const notifySuccess = (addNotification, message, timeout = 5000) =>
	addNotification({
		text: message,
		position: 'bottom-right',
		type: 'success',
		removeAfter: timeout,
	});

/**
 * @summary
 * Add error notification
 *
 * @param {any} err The error object
 * @param {string} message The default message
 */
export const notifyError = (addNotification, err, message = '', timeout = 5000) =>
	addNotification({
		text: err?.response?.data?.message || err?.message || message || genericErrorMessage,
		position: 'bottom-right',
		type: 'danger',
		removeAfter: timeout,
	});

export const formatUtc = (createdAt) => moment(createdAt).utc().format('ddd Do MMM, YYYY h:mm a') + ' UTC';

export const getErrorMessage = (err, message) => err?.response?.data?.message || err?.message || message || genericErrorMessage;

export const isLocalhost = () => location.hostname === 'localhost' || location.hostname === '127.0.0.1';
