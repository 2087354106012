<script>
	import { jsonldJobCompany } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldJobCompany } from '../../stores/jsonld/UpdateJsonldJobCompany';

	let jsonldJobCompanyValue;

	jsonldJobCompany.subscribe((value) => {
		jsonldJobCompanyValue = value;
	});

	const onJsonldJobCompanyChange = (event) => updateJsonldJobCompany(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldJobCompany">Job Company:</label>
	<input class="proteus-input" type="text" name="jsonldJobCompany" value={jsonldJobCompanyValue} on:input={onJsonldJobCompanyChange} />
</div>
