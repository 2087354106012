<script>
	import { jsonldUrl } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldUrl } from '../../stores/jsonld/UpdateJsonldUrl';

	let jsonldUrlValue;

	jsonldUrl.subscribe((value) => {
		jsonldUrlValue = value;
	});

	const onJsonldUrlChange = (event) => updateJsonldUrl(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldUrl">Url:</label>
	<input class="proteus-input" type="text" name="jsonldUrl" value={jsonldUrlValue} on:input={onJsonldUrlChange} />
</div>
