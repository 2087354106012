<script>
	import { publishDescription } from '../../stores/publish/PublishStore';
	import { seoOgIsActive, seoOgDescription } from '../../stores/seo/SeoStore';
	import { updateSeoOgDescription } from '../../stores/seo/UpdateSeoOgDescription';

	let publishDescriptionValue;
	let seoOgIsActiveValue;
	let seoOgDescriptionValue;

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
		if (!seoOgIsActiveValue) {
			updateSeoOgDescription(publishDescriptionValue);
		}
	});

	publishDescription.subscribe((value) => {
		publishDescriptionValue = value;
		if (!seoOgIsActiveValue) {
			updateSeoOgDescription(publishDescriptionValue);
		}
	});

	seoOgDescription.subscribe((value) => {
		seoOgDescriptionValue = value;
	});

	const onSeoOgDescriptionChange = (event) => updateSeoOgDescription(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">OG Description:</label>
	<input
		class="proteus-input"
		type="text"
		name="seoOgDescription"
		value={seoOgDescriptionValue}
		on:input={onSeoOgDescriptionChange}
		disabled={!seoOgIsActiveValue}
		readonly={!seoOgIsActiveValue}
	/>
</div>
