<script>
	import { jsonldReviewBody } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldReviewBody } from '../../stores/jsonld/UpdateJsonldReviewBody';

	let jsonldReviewBodyValue;

	jsonldReviewBody.subscribe((value) => {
		jsonldReviewBodyValue = value;
	});

	const onJsonldReviewBodyChange = (event) => updateJsonldReviewBody(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldReviewBody">Review Body:</label>
	<textarea
		class="proteus-input"
		type="text"
		name="jsonldReviewBody"
		value={jsonldReviewBodyValue}
		on:input={onJsonldReviewBodyChange}
	/>
</div>
