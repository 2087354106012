<script>
	import ColorPicker from '../../ColorPicker.svelte';

	import { bodyBgGradient2 } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyBgGradient2 } from '../../stores/body-bg/UpdateBodyBgGradient2';
	import { resetBodyBgGradient2 } from '../../stores/body-bg/ResetBodyBgGradient2';

	let bodyBgGradient2Value;

	bodyBgGradient2.subscribe((value) => {
		bodyBgGradient2Value = value;
	});

	const onBodyBgGradient2Change = (event) => updateBodyBgGradient2(event.detail);

	const onBodyBgGradient2Reset = () => resetBodyBgGradient2();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Color 2:
		<span class="proteus-reset" on:click={onBodyBgGradient2Reset}>Reset</span>
	</label>
	<ColorPicker value={bodyBgGradient2Value} on:input={onBodyBgGradient2Change} />
</div>
