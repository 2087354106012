<script>
	import { imageAlt } from '../../stores/image/ImageStore';
	import { updateImageAlt } from '../../stores/image/UpdateImageAlt';

	let imageAltValue;

	imageAlt.subscribe((value) => {
		imageAltValue = value;
	});

	const onImageAltChange = (event) => updateImageAlt(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">Alt:</label>
	<input class="proteus-input" type="text" name="imageAlt" value={imageAltValue} on:input={onImageAltChange} />
</div>
