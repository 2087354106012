<script>
	import { jsonldAuthorName } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAuthorName } from '../../stores/jsonld/UpdateJsonldAuthorName';

	let jsonldAuthorNameValue;

	jsonldAuthorName.subscribe((value) => {
		jsonldAuthorNameValue = value;
	});

	const onJsonldAuthorNameChange = (event) => updateJsonldAuthorName(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAuthorName">Author Name:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAuthorName"
		value={jsonldAuthorNameValue}
		on:input={onJsonldAuthorNameChange}
	/>
</div>
