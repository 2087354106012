<script>
	import { authIsBusy, authIsLoggedIn, authPlan } from '../stores/auth/AuthStore';
	import SubscribeButton from '../SubscribeButton.svelte';

	let authIsBusyValue;
	let authIsLoggedInValue;
	let authPlanValue;

	authIsBusy.subscribe((value) => {
		authIsBusyValue = value;
	});

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});

	authPlan.subscribe((value) => {
		authPlanValue = value;
	});

	const scrollIntoView = ({ target }) => {
		const el = document.querySelector(target.getAttribute('href'));
		if (!el) {
			return;
		}
		el.scrollIntoView({
			behavior: 'smooth',
		});
	};
</script>

<!-- <Header /> -->

<link rel="stylesheet" href="/public/home.css" />

<header>
	<img
		src="https://ik.imagekit.io/ymjlyrr8zyb/4t4ioGraphic_tclojBlDF.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1658391406819"
		width="360"
		height="150"
		alt="4t4"
		title="4t4"
	/>

	<div><a href="#pricing" on:click|preventDefault={scrollIntoView}>pricing</a></div>

	<div>
		{#if authIsLoggedInValue}
			<a href="/?page=editor"> editor </a>
		{/if}
		{#if !authIsLoggedInValue}
			<a href="/?page=login"> editor </a>
		{/if}
	</div>

	<div style="font-weight: 700">
		{#if authIsLoggedInValue}
			<a href="/?page=editor"> Log in </a>
		{/if}
		{#if !authIsLoggedInValue}
			<a href="/?page=login"> Log in </a>
		{/if}
	</div>
	<!-- <div style="font-weight: 700">
		{#if authIsLoggedInValue}
			<a href="/?page=editor"> Editor </a>
		{/if}
		{#if !authIsLoggedInValue}
			<a href="/?page=login"> Log in </a>
		{/if}
	</div> -->
</header>

<main>
	<img
		src="https://ik.imagekit.io/ymjlyrr8zyb/loveIT-turquoise_vEFTnl92L.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1660169496388/tr:w-auto-600,pr-true"
		sizes="(max-width: 600px) 50vw, 100vw"
		width="475"
		height="292"
		alt=" love it"
		title="love it"
	/>

	<h1 style="text-align: center; padding-top: 0">instant web pages</h1>
	<h4 style="font-size: 1.6rem; text-align: center; font-weight: 500; padding: 0 1rem; font-style: italic">from your browser</h4>

	<div>
		<div style="padding: 2vh 5px 2vh; font-size: 1.3rem; font-style: italic; color: hsla(0, 0%, 30%, 1)">
			no coding&nbsp;&nbsp;&nbsp; super simple&nbsp;&nbsp;&nbsp; tiny cost
		</div>

		<a class="button" href={authIsLoggedInValue? '/?page=editor' : '/?page=register'}>
			<span style="text-decoration: none;">instant</span>
			&nbsp;
			<strong style="text-decoration: none;">free</strong>
			&nbsp;
			<span style="text-decoration: none;">secure login</span>
		</a>

		<div class="container4" style="color: #424242; font-style: italic">
			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> your own personal subdomain</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> secure</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> tiny cost</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> simple browser editor</div>
			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> no coding required</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> live preview</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> ultra-fast pages served on global CDN</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> fully optimized and deployed in seconds</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> responsive on all devices</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> stripe payment links</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> start selling in moments</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> upload, store and sell your digital files</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> images automatically stored and optimized</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> video links</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> json-ld and OG enabled</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> unlimited edits</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> free ssl certificate</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> ideal for blogs</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> link pages</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> link to social media</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> no data collection</div>

			<div><span style="color: #ec0e97; font-size: 1.1rem">&#10625;</span> no transaction fees</div>
		</div>

		<div class="insert">
			<img
				src="https://ik.imagekit.io/ymjlyrr8zyb/pezq-r-hearts_hOpUAvJg9.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1659824490016"
				width="400"
				height="256"
				title="pezqi-left"
				alt="pezqi-left"
			/>
		</div>
		<div style="font-size: 1.618rem; text-align: center; font-weight: 400; padding: 0 1rem; font-style: italic">
			...check out our ultra low pricing!<br /><br />
		</div>

		<div>
			<div id="pricing" class="pricingcontainer">
				<div class="pricingbox">
					<div style="background-color: hsla(244, 194%, 61%, 1); color: white; font-weight: 400; text-align: center">
						forever <span style="font-weight: 700">free</span>
					</div>

					<div style="font-size: 1.4rem; color: #424242; font-weight: 800; text-align: center">
						<span style="font-weight: 300">€ </span>0 <span style="font-weight: 600; font-size: 1rem">per year</span>
					</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> your own subdomain</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> easy in-browser editor</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> 1 main page</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> unlimited edits</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> all device responsive</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> free hosting on global CDN</div>
				</div>

				<div class="pricingbox">
					<div style="background-color: hsla(244, 194%, 61%, 1); color: white; font-weight: 400; text-align: center">
						4t4 <span style="font-weight: 700">Pro</span>
					</div>

					<div style="font-size: 1.4rem; color: #424242; font-weight: 800; text-align: center">
						<span style="font-weight: 300">€ </span> {(authPlanValue?.unit_amount / 100).toFixed(2)} <span style="font-weight: 600; font-size: 1rem">per month</span>
					</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> your own subdomain</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> easy in-browser editor</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> 5 unique ultra fast static deploys</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> unlimited edits</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> Stripe payment links</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> video links</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> store and sell digital files</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> 100mb digital storage</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> all device responsive</div>

					<div><span style="color: #ec0e97; font-size: 1rem">&#10625;</span> hosted on global CDN</div>
				</div>
			</div>

			<!-- <div style="font-size: 1.618rem; color: hsla(0, 0%, 50%, 1); text-align: center; font-weight: 400; padding-bottom: 1rem">
				upgrade to pro anytime
			</div> -->
			<SubscribeButton />
		</div>
	</div>
</main>

<section style="background-color: hsla(232, 55%, 52%, 1)">
	<div>
		<br />
		<p style="max-width: min(34rem, 96vw); margin: auto; text-align: left; color: white; font-size: 1.1rem">
			<span style="font-weight: 750; padding-top: 1rem">welcome to 4t4.io</span><br />the quick and simple way to create and deploy a fully responsive
			single page website. Lightweight responsive pages are the future and you can create your own in a few moments<br /><br />

			<span style="font-weight: 750">your own personal subdomain</span><br />with 4t4 you choose a personal subdomain name that becomes the first part of
			the page address. Depending on the plan you choose you can create different pages which you can edit and alter as much as you like<br />

			You can add images or photos to your page which we optimize and store for you to make fast links. You can also connect Stripe payment links to sell
			goods and services from your page and with the <span style="font-weight: 700">Pro</span> plan you can store and sell digital assets<br /><br />

			<span style="font-weight: 750">why is it so quick?</span><br />
			Your page is very lightweight. It is pre-rendered into a compact file that is deployed on a CDN - a global network of fast servers. Because your page
			is a self contained file, it is very fast and very secure.<br />

			You use our easy editor to compose your page in the browser and upload your photos and files<br /><br />

			We do the rest and make sure your page will look good on any device<br /><br />
		</p>
	</div>
</section>

<footer>
	<img
		src="https://ik.imagekit.io/ymjlyrr8zyb/4t4ioGraphic_m8iNsrLoj.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1658395895436"
		width="360"
		height="150"
		alt="4t4"
		title="4t4"
	/>

	<img
		src="https://ik.imagekit.io/ymjlyrr8zyb/pezq-r-hearts_hOpUAvJg9.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1659824490016"
		width="400"
		height="256"
		title="pezqilove"
		alt="pezqilove"
	/>

	<a href="https://twitter.com/4t4io?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @4t4io</a>
	<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
</footer>
