<script>
	import { jsonldTwitter } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldTwitter } from '../../stores/jsonld/UpdateJsonldTwitter';

	let jsonldTwitterValue;

	jsonldTwitter.subscribe((value) => {
		jsonldTwitterValue = value;
	});

	const onJsonldTwitterChange = (event) => updateJsonldTwitter(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldTwitter">Twitter:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldTwitter"
		value={jsonldTwitterValue}
		on:input={onJsonldTwitterChange}
	/>
</div>
