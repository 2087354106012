<script>
	import Header from '../Header.svelte';
</script>

<Header />
<section class="proteus-section">
	<div class="proteus-container">
		<h1>404</h1>
	</div>
</section>
