<script>
	import axios from 'axios';
	import { onMount } from 'svelte';
	import { sortBy, sumBy } from 'lodash';
	import { getNotificationsContext } from 'svelte-notifications';
	import { bytesToSize, getOtpLoginData, notifyError } from '../utils';
	import Header from '../Header.svelte';
	import IconFiles from '../icons/IconFiles.svelte';
	import SectionTitle from '../SectionTitle.svelte';
	import Uploader from '../Uploader.svelte';
	import UploadItems from '../UploadItems.svelte';
	import Loader from '../Loader.svelte';

	import { authIsLoggedIn, authSubscription } from '../stores/auth/AuthStore';
	import { uploadItems, uploadIsBusy, uploadError } from '../stores/uploads/UploadStore';
	import { updateUploadItems } from '../stores/uploads/UpdateUploadItems';
	import { updateUploadIsBusy } from '../stores/uploads/UpdateUploadIsBusy';
	import { updateUploadError } from '../stores/uploads/UpdateUploadError';
	import SubscribeNotice from '../SubscribeNotice.svelte';
	import SubscribeButton from '../SubscribeButton.svelte';

	const { addNotification } = getNotificationsContext();

	let isTrial;
	let authSubscriptionValue;
	let otpLoginData;
	let user;

	let authIsLoggedInValue;
	let uploadItemsValue;
	let uploadIsBusyValue;
	let uploadErrorValue;

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});

	uploadItems.subscribe((value) => {
		uploadItemsValue = value;
	});

	uploadIsBusy.subscribe((value) => {
		uploadIsBusyValue = value;
	});

	uploadError.subscribe((value) => {
		uploadErrorValue = value;
	});

	$: totalSize = sumBy((uploadItemsValue || []).map((item) => parseInt(item.size, 10)));

	const getUser = async () => {
		otpLoginData = await getOtpLoginData();
		try {
			const response = await axios.post('/api/login', { ...otpLoginData, id: otpLoginData.otpId });
			user = response.data.user;
		} catch (err) {
			console.log(err);
			notifyError(addNotification, null, 'You must login to continue');
		}
	};

	const getUserUploads = async () => {
		if (!user?.id) {
			notifyError(addNotification, null, 'You must login to continue');
			return;
		}
		updateUploadIsBusy(true);
		try {
			const response = await axios.post('/api/storage', {
				...otpLoginData,
				method: 'GET',
			});
			updateUploadItems(response.data);
		} catch (err) {
			console.log('Error fetching user uploads: ' + err);
		}
		updateUploadIsBusy(false);
	};

	onMount(async () => {
		await getUser();
		getUserUploads();
	});

	authSubscription.subscribe((value) => {
		authSubscriptionValue = value;
		isTrial = value?.status !== 'active';
	});
</script>

<Header />
<section class="proteus-section">
	<div class="proteus-container">
		<!-- uploader -->
		<div class="proteus-block">
			<SectionTitle title={`Your uploads ${totalSize ? '(' + bytesToSize(totalSize, false) + ')' : ''}`} isIconOnly={true}>
				<span slot="icon" class>
					<IconFiles />
				</span>
			</SectionTitle>
		</div>

		{#if !isTrial}
			<!-- uploader -->
			<div class="proteus-block">
				<Uploader />
			</div>
		{/if}

		{#if isTrial}
			<SubscribeNotice title="digital downloads" />
			<SubscribeButton />
		{/if}

		<!-- uploaded files -->
		<div class="proteus-block">
			{#if uploadIsBusyValue && !uploadItemsValue.length}
				<div style="text-align:center;">
					<Loader />
				</div>
			{/if}
			<UploadItems />
		</div>
	</div>
</section>
