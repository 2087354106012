<script>
	import { jsonldType } from '../../stores/jsonld/JsonldStore';

	import ToolbarJsonldName from './ToolbarJsonldName.svelte';
	import ToolbarJsonldDescription from './ToolbarJsonldDescription.svelte';
	import ToolbarJsonldBrandName from './ToolbarJsonldBrandName.svelte';
	import ToolbarJsonldSku from './ToolbarJsonldSku.svelte';
	import ToolbarJsonldCurrency from './ToolbarJsonldCurrency.svelte';
	import ToolbarJsonldCurrencyValue from './ToolbarJsonldCurrencyValue.svelte';
	import ToolbarJsonldReviewName from './ToolbarJsonldReviewName.svelte';
	import ToolbarJsonldReviewBody from './ToolbarJsonldReviewBody.svelte';
	import ToolbarJsonldReviewAuthor from './ToolbarJsonldReviewAuthor.svelte';
	import ToolbarJsonldReviewPublisher from './ToolbarJsonldReviewPublisher.svelte';
	import ToolbarJsonldReviewBestRating from './ToolbarJsonldReviewBestRating.svelte';
	import ToolbarJsonldReviewRatingValue from './ToolbarJsonldReviewRatingValue.svelte';
	import ToolbarJsonldAggregateRatingValue from './ToolbarJsonldAggregateRatingValue.svelte';
	import ToolbarJsonldAggregateRatingBestRating from './ToolbarJsonldAggregateRatingBestRating.svelte';
	import ToolbarJsonldAggregateRatingCount from './ToolbarJsonldAggregateRatingCount.svelte';

	let jsonldTypeValue;

	jsonldType.subscribe((value) => {
		jsonldTypeValue = value;
	});
</script>

{#if jsonldTypeValue === 'product'}
	<ToolbarJsonldName />
	<ToolbarJsonldDescription />
	<ToolbarJsonldBrandName />
	<ToolbarJsonldSku />
	<!-- <ToolbarJsonldCurrency /> -->
	<!-- <ToolbarJsonldCurrencyValue /> -->
	<ToolbarJsonldReviewName />
	<ToolbarJsonldReviewBody />
	<ToolbarJsonldReviewAuthor />
	<ToolbarJsonldReviewPublisher />
	<ToolbarJsonldReviewRatingValue />
	<ToolbarJsonldReviewBestRating />
	<ToolbarJsonldAggregateRatingValue />
	<ToolbarJsonldAggregateRatingBestRating />
	<ToolbarJsonldAggregateRatingCount />
{/if}
