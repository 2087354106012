<script>
	import { jsonldRecipeYield } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldRecipeYield } from '../../stores/jsonld/UpdateJsonldRecipeYield';

	let jsonldRecipeYieldValue;

	jsonldRecipeYield.subscribe((value) => {
		jsonldRecipeYieldValue = value;
	});

	const onJsonldRecipeYieldChange = (event) => updateJsonldRecipeYield(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldRecipeYield">Yield:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldRecipeYield"
		value={jsonldRecipeYieldValue}
		on:input={onJsonldRecipeYieldChange}
	/>
</div>
