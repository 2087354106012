<script>
	import {
		isToolbarBodyActive,
		isToolbarHeaderActive,
		isToolbarImageActive,
		isToolbarStripeActive,
		isToolbarSaveActive,
		isToolbarSaveAsActive,
		isToolbarMenuActive,
		isToolbarSeoActive,
		isToolbarOembedActive,
	} from '../stores/toolbar/ToolbarStore';

	import { updateToolbarBody } from '../stores/toolbar/UpdateToolbarBody';
	import { updateToolbarHeader } from '../stores/toolbar/UpdateToolbarHeader';
	import { updateToolbarImage } from '../stores/toolbar/UpdateToolbarImage';
	import { updateToolbarStripe } from '../stores/toolbar/UpdateToolbarStripe';
	import { updateToolbarSave } from '../stores/toolbar/UpdateToolbarSave';
	import { updateToolbarSaveAs } from '../stores/toolbar/UpdateToolbarSaveAs';
	import { updateToolbarMenu } from '../stores/toolbar/UpdateToolbarMenu';
	import { updateToolbarSeo } from '../stores/toolbar/UpdateToolbarSeo';
	import { updateToolbarOembed } from '../stores/toolbar/UpdateToolbarOembed';

	let isToolbarBodyActiveValue;
	let isToolbarHeaderActiveValue;
	let isToolbarImageActiveValue;
	let isToolbarStripeActiveValue;
	let isToolbarSaveActiveValue;
	let isToolbarSaveAsActiveValue;
	let isToolbarMenuActiveValue;
	let isToolbarSeoActiveValue;
	let isToolbarOembedActiveValue;

	isToolbarBodyActive.subscribe((value) => {
		isToolbarBodyActiveValue = value;
	});

	isToolbarHeaderActive.subscribe((value) => {
		isToolbarHeaderActiveValue = value;
	});

	isToolbarImageActive.subscribe((value) => {
		isToolbarImageActiveValue = value;
	});

	isToolbarStripeActive.subscribe((value) => {
		isToolbarStripeActiveValue = value;
	});

	isToolbarSaveActive.subscribe((value) => {
		isToolbarSaveActiveValue = value;
	});

	isToolbarSaveAsActive.subscribe((value) => {
		isToolbarSaveAsActiveValue = value;
	});

	isToolbarMenuActive.subscribe((value) => {
		isToolbarMenuActiveValue = value;
	});

	isToolbarSeoActive.subscribe((value) => {
		isToolbarSeoActiveValue = value;
	});

	isToolbarOembedActive.subscribe((value) => {
		isToolbarOembedActiveValue = value;
	});

	const onClick = () => {
		updateToolbarBody(false);
		updateToolbarHeader(false);
		updateToolbarImage(false);
		updateToolbarStripe(false);
		updateToolbarSave(false);
		updateToolbarSaveAs(false);
		updateToolbarMenu(false);
		updateToolbarSeo(false);
		updateToolbarOembed(false);
	};
</script>

{#if isToolbarBodyActiveValue || isToolbarHeaderActiveValue || isToolbarImageActiveValue || isToolbarStripeActiveValue || isToolbarSaveActiveValue || isToolbarSaveAsActiveValue || isToolbarMenuActiveValue || isToolbarSeoActiveValue || isToolbarOembedActiveValue}
	<div class="proteus-toolbar-overlay" on:click={onClick} />
{/if}
