<script>
	import { onMount } from 'svelte';
	import localStorage from 'localforage';
	import axios from 'axios';
	import isMobilePhone from 'validator/lib/isMobilePhone';
	import LoaderFullscreen from './LoaderFullscreen.svelte';
	import Message from './Message.svelte';
	import { genericErrorMessage } from '../../lang/en';
	import IconContinue from './icons/IconContinue.svelte';

	export let onRequestOtp;
	export let onOtpSent;
	export let msg;
	export let msgType;

	let isBusy = false;
	let isSent = false;
	let value = '+ ';

	onMount(async () => {
		const { userId } = (await localStorage.getItem('otp')) || {};
		if (!userId) {
			return;
		}
		value = userId;
	});

	const onValueChange = (e) => {
		const newVal = e.target.value.replace(/[^\d]/g, '').trim();
		if (newVal.length === 0) {
			value = '+ ';
			return;
		}
		value = `+ ${newVal}`;
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (isBusy) {
			return;
		}
		const phone = value.replace(/[^\d\+]/gi, '');
		if (!isMobilePhone(phone, '', { strict: true })) {
			msg = 'Invalid mobile number';
			msgType = 'error';
			return;
		}
		msg = undefined;
		isBusy = true;
		await onRequestOtp(phone);
		isBusy = false;
	};
</script>

<form class="proteus-mobile" on:submit={onSubmit}>
	<fieldset disabled={isBusy || isSent} class="proteus-fieldset">
		<div class="proteus-mobile-control">
			<input type="tel" class="proteus-mobile-input" bind:value on:input={onValueChange} on:change={onSubmit} maxlength={15} />
			<!-- <button type="submit" class="proteus-login-submit">
				<IconContinue />
			</button> -->
		</div>
		<Message {msg} type={msgType} />
	</fieldset>
</form>
<!-- <p class="proteus-phone">
	<input type="tel" class="proteus-input proteus-phone-input" bind:value on:input={onValueChange} maxlength={15} placeholder="+19876543210" />
</p>
<h3 class="login-submit" on:click={onSubmit}>OK</h3> -->

{#if isBusy}
	<LoaderFullscreen />
{/if}
