<script>
	import { jsonldAuthorUrl } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAuthorUrl } from '../../stores/jsonld/UpdateJsonldAuthorUrl';

	let jsonldAuthorUrlValue;

	jsonldAuthorUrl.subscribe((value) => {
		jsonldAuthorUrlValue = value;
	});

	const onJsonldAuthorUrlChange = (event) => updateJsonldAuthorUrl(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAuthorUrl">Author Url:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAuthorUrl"
		value={jsonldAuthorUrlValue}
		on:input={onJsonldAuthorUrlChange}
	/>
</div>
