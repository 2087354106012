<script>
	import { onMount } from 'svelte';
	import { publishDomain } from '../../stores/publish/PublishStore';
	import {
		jsonldType,
		jsonldArticleType,
		jsonldName,
		jsonldTitle,
		jsonldDescription,
		jsonldKeywords,
		jsonldImage,
		jsonldUrl,
		jsonldPage,
		jsonldAuthorName,
		jsonldAuthorUrl,
		jsonldPublisherName,
		jsonldPublisherUrl,
		jsonldPerformerName,
		jsonldPerformerUrl,
		jsonldOrganizerName,
		jsonldOrganizerUrl,
		jsonldJobTitle,
		jsonldJobCompany,
		jsonldEmail,
		jsonldTwitter,
		jsonldFacebook,
		jsonldLinkedin,
		jsonldInstagram,
		jsonldYouTube,
		jsonldGitHub,
		jsonldTelephone,
		jsonldLatitude,
		jsonldLongitude,
		jsonldCurrencyValue,
		jsonldCurrency,
		jsonldBusinessFunction,
		jsonldAcceptedPaymentMethods,
		jsonldIngredient,
		jsonldRecipeYield,
		jsonldRecipeCategory,
		jsonldRecipeCuisine,
		jsonldInstructions,
		jsonldStartTime,
		jsonldEndTime,
		jsonldAddressStreet,
		jsonldAddressLocality,
		jsonldAddressPostalCode,
		jsonldAddressRegion,
		jsonldAddressCountry,
		jsonldBrandName,
		jsonldSku,
		jsonldReviewName,
		jsonldReviewBody,
		jsonldReviewAuthor,
		jsonldReviewPublisher,
		jsonldReviewRatingValue,
		jsonldReviewBestRating,
		jsonldAggregateRatingValue,
		jsonldAggregateRatingBestRating,
		jsonldAggregateRatingCount,
	} from '../../stores/jsonld/JsonldStore';

	let jsonldTypeVal;
	let jsonldArticleTypeVal;
	let jsonldNameVal;
	let jsonldTitleVal;
	let jsonldDescriptionVal;
	let jsonldKeywordsVal;
	let jsonldImageVal;
	let jsonldUrlVal;
	let jsonldPageVal;
	let jsonldAuthorNameVal;
	let jsonldAuthorUrlVal;
	let jsonldPublisherNameVal;
	let jsonldPublisherUrlVal;
	let jsonldPerformerNameVal;
	let jsonldPerformerUrlVal;
	let jsonldOrganizerNameVal;
	let jsonldOrganizerUrlVal;
	let jsonldJobTitleVal;
	let jsonldJobCompanyVal;
	let jsonldEmailVal;
	let jsonldTwitterVal;
	let jsonldFacebookVal;
	let jsonldLinkedinVal;
	let jsonldInstagramVal;
	let jsonldYouTubeVal;
	let jsonldGitHubVal;
	let jsonldTelephoneVal;
	let jsonldLatitudeVal;
	let jsonldLongitudeVal;
	let jsonldCurrencyValueVal;
	let jsonldCurrencyVal;
	let jsonldBusinessFunctionVal;
	let jsonldAcceptedPaymentMethodsVal;
	let jsonldIngredientVal;
	let jsonldRecipeYieldVal;
	let jsonldRecipeCategoryVal;
	let jsonldRecipeCuisineVal;
	let jsonldInstructionsVal;
	let jsonldStartTimeVal;
	let jsonldEndTimeVal;
	let jsonldAddressStreetVal;
	let jsonldAddressLocalityVal;
	let jsonldAddressPostalCodeVal;
	let jsonldAddressRegionVal;
	let jsonldAddressCountryVal;
	let jsonldBrandNameVal;
	let jsonldSkuVal;
	let jsonldReviewNameVal;
	let jsonldReviewBodyVal;
	let jsonldReviewAuthorVal;
	let jsonldReviewPublisherVal;
	let jsonldReviewRatingValueVal;
	let jsonldReviewBestRatingVal;
	let jsonldAggregateRatingValueVal;
	let jsonldAggregateRatingBestRatingVal;
	let jsonldAggregateRatingCountVal;

	let publishDomainVal;
	let url;
	let domain;
	let subdomain;

	onMount(async () => {
		domain = await localStorage.getItem('domain');
		subdomain = await localStorage.getItem('subdomain');
	});

	const updateUrl = async () => {
		if (subdomain) {
			url = `https://${publishDomainVal}.${subdomain}.4t4.io`;
			return;
		}
		url = `https://${publishDomainVal}.4t4.io`;
	};

	const updateJsonldScript = (template) => {
		let ele = document.getElementById('jsonld-script');
		if (!ele) {
			ele = document.createElement('script');
			ele.type = 'application/ld+json';
			ele.setAttribute('data-keep', 'data-keep');
			ele.setAttribute('id', 'jsonld-script');
			document.head.appendChild(ele);
		}
		ele.innerHTML = JSON.stringify(template, null, 2);
	};

	const getAddressTemplate = () => {
		if (
			!jsonldAddressStreetVal &&
			!jsonldAddressLocalityVal &&
			!jsonldAddressPostalCodeVal &&
			!jsonldAddressRegionVal &&
			!jsonldAddressCountryVal
		) {
			return;
		}
		let address = {};
		if (jsonldAddressStreetVal) {
			address.streetAddress = jsonldAddressStreetVal;
		}
		if (jsonldAddressLocalityVal) {
			address.addressLocality = jsonldAddressLocalityVal;
		}
		if (jsonldAddressPostalCodeVal) {
			address.postalCode = jsonldAddressPostalCodeVal;
		}
		if (jsonldAddressRegionVal) {
			address.addressRegion = jsonldAddressRegionVal;
		}
		if (jsonldAddressCountryVal) {
			address.addressCountry = jsonldAddressCountryVal;
		}
		return address;
	};

	const getRatingTemplate = () => {
		if (!jsonldAggregateRatingValueVal) {
			return;
		}
		let rating = {
			'@type': 'AggregateRating',
			ratingValue: jsonldAggregateRatingValueVal,
		};
		if (jsonldAggregateRatingBestRatingVal) {
			rating.bestRating = jsonldAggregateRatingBestRatingVal;
		}
		if (jsonldAggregateRatingCountVal) {
			rating.ratingCount = jsonldAggregateRatingCountVal;
		}
		return rating;
	};

	const getReviewTemplate = () => {
		if (
			!jsonldReviewRatingValueVal &&
			!jsonldReviewNameVal &&
			!jsonldReviewBodyVal &&
			!jsonldReviewAuthorVal &&
			!jsonldReviewPublisherVal
		) {
			return;
		}
		let review = {
			'@type': 'Review',
		};
		if (jsonldReviewRatingValueVal) {
			review.reviewRating = {
				'@type': 'Rating',
				ratingValue: jsonldReviewRatingValueVal,
			};
			if (jsonldReviewBestRatingVal) {
				review.reviewRating.bestRating = jsonldReviewBestRatingVal;
			}
		}
		if (jsonldReviewNameVal) {
			review.name = jsonldReviewNameVal;
		}
		if (jsonldReviewBodyVal) {
			review.reviewBody = jsonldReviewBodyVal;
		}
		if (jsonldReviewAuthorVal) {
			review.author = {
				'@type': 'Person',
				name: jsonldReviewAuthorVal,
			};
		}
		if (jsonldReviewPublisherVal) {
			review.publisher = {
				'@type': 'Organization',
				name: jsonldReviewPublisherVal,
			};
		}
		return review;
	};

	const getGeoTemplate = () => {
		if (!jsonldLatitudeVal && !jsonldLongitudeVal) {
			return;
		}
		let template = {
			'@type': 'GeoCoordinates',
		};
		if (jsonldLatitudeVal) {
			template.latitude = jsonldLatitudeVal;
		}
		if (jsonldLongitudeVal) {
			template.longitude = jsonldLongitudeVal;
		}
		return template;
	};

	const getArticleTemplate = () => {
		let template = {
			'@context': 'https://schema.org',
			'@type': jsonldArticleTypeVal,
			mainEntityOfPage: {
				'@type': 'WebPage',
				'@id': url,
			},
			headline: jsonldTitleVal,
		};
		if (jsonldDescriptionVal) {
			template.description = jsonldDescriptionVal;
		}
		if (jsonldAuthorNameVal || jsonldAuthorUrlVal) {
			template.author = { '@type': 'Person' };
			if (jsonldAuthorNameVal) {
				template.author.name = jsonldAuthorNameVal;
			}
			if (jsonldAuthorUrlVal) {
				template.author.url = jsonldAuthorUrlVal;
			}
		}
		if (jsonldPublisherNameVal) {
			template.publisher = { '@type': 'Organization' };
			if (jsonldPublisherNameVal) {
				template.publisher.name = jsonldPublisherNameVal;
			}
		}
		const rating = getRatingTemplate();
		if (rating) {
			template.aggregateRating = rating;
		}
		const review = getReviewTemplate();
		if (review) {
			template.review = review;
		}
		return template;
	};

	const getPersonTemplate = () => {
		let template = {
			'@context': 'https://schema.org/',
			'@type': 'Person',
		};
		if (jsonldNameVal) {
			template.name = jsonldNameVal;
		}
		if (jsonldEmailVal) {
			template.email = jsonldEmailVal;
		}
		if (jsonldUrlVal) {
			template.url = jsonldUrlVal;
		}
		if (jsonldJobTitleVal) {
			template.jobTitle = jsonldJobTitleVal;
		}
		if (jsonldJobCompanyVal) {
			template.worksFor = {
				'@type': 'Organization',
				name: jsonldJobCompanyVal,
			};
		}
		let sameAs = [];
		const socialNetworks = [
			jsonldTwitterVal,
			jsonldFacebookVal,
			jsonldLinkedinVal,
			jsonldInstagramVal,
			jsonldYouTubeVal,
			jsonldGitHubVal,
		];
		for (let i = 0; i < socialNetworks.length; i++) {
			if (socialNetworks[i]) {
				sameAs.push(socialNetworks[i]);
			}
		}
		if (sameAs.length) {
			template.sameAs = sameAs;
		}
		const address = getAddressTemplate();
		if (address) {
			template.address = address;
		}
		return template;
	};

	const getOrganizationTemplate = () => {
		let template = {
			'@context': 'https://schema.org/',
			'@type': 'Organization',
		};
		if (jsonldNameVal) {
			template.name = jsonldNameVal;
		}
		if (jsonldDescriptionVal) {
			template.description = jsonldDescriptionVal;
		}
		if (jsonldEmailVal) {
			template.email = jsonldEmailVal;
		}
		if (jsonldUrlVal) {
			template.url = jsonldUrlVal;
		}
		if (jsonldTelephoneVal) {
			template.telephone = jsonldTelephoneVal;
		}
		let sameAs = [];
		const socialNetworks = [
			jsonldTwitterVal,
			jsonldFacebookVal,
			jsonldLinkedinVal,
			jsonldInstagramVal,
			jsonldYouTubeVal,
			jsonldGitHubVal,
		];
		for (let i = 0; i < socialNetworks.length; i++) {
			if (socialNetworks[i]) {
				sameAs.push(socialNetworks[i]);
			}
		}
		if (sameAs.length) {
			template.sameAs = sameAs;
		}
		const address = getAddressTemplate();
		if (address) {
			template.address = address;
		}
		return template;
	};

	const getEventTemplate = () => {
		let template = {
			'@context': 'https://schema.org/',
			'@type': 'Event',
		};
		if (jsonldNameVal) {
			template.name = jsonldNameVal;
		}
		if (jsonldDescriptionVal) {
			template.description = jsonldDescriptionVal;
		}
		if (jsonldUrlVal) {
			template.url = jsonldUrlVal;
		}
		if (jsonldPerformerNameVal || jsonldPerformerUrlVal) {
			template.performer = {
				'@type': 'PerformingGroup',
			};
			if (jsonldPerformerNameVal) {
				template.performer.name = jsonldPerformerNameVal;
			}
			if (jsonldPerformerUrlVal) {
				template.performer.url = jsonldPerformerUrlVal;
			}
		}
		if (jsonldOrganizerNameVal || jsonldOrganizerUrlVal) {
			template.organizer = {
				'@type': 'Organization',
			};
			if (jsonldOrganizerNameVal) {
				template.organizer.name = jsonldOrganizerNameVal;
			}
			if (jsonldOrganizerUrlVal) {
				template.organizer.url = jsonldOrganizerUrlVal;
			}
		}
		const address = getAddressTemplate();
		if (address) {
			template.location = {
				'@type': 'Place',
				address: address,
			};
		}
		return template;
	};

	const getPlaceTemplate = () => {
		let template = {
			'@context': 'https://schema.org/',
			'@type': 'Place',
		};
		if (jsonldNameVal) {
			template.name = jsonldNameVal;
		}
		if (jsonldDescriptionVal) {
			template.description = jsonldDescriptionVal;
		}
		if (jsonldUrlVal) {
			template.url = jsonldUrlVal;
		}
		if (jsonldTelephoneVal) {
			template.telephone = jsonldTelephoneVal;
		}
		const geo = getGeoTemplate();
		if (geo) {
			template.geo = geo;
		}
		const address = getAddressTemplate();
		if (address) {
			template.address = address;
		}
		return template;
	};

	const getProductTemplate = () => {
		let template = {
			'@context': 'https://schema.org/',
			'@type': 'Product',
		};
		if (jsonldNameVal) {
			template.name = jsonldNameVal;
		}
		if (jsonldDescriptionVal) {
			template.description = jsonldDescriptionVal;
		}
		if (jsonldBrandNameVal) {
			template.brand = {
				'@type': 'Brand',
				name: jsonldBrandNameVal,
			};
		}
		if (jsonldSkuVal) {
			template.sku = jsonldSkuVal;
		}
		const rating = getRatingTemplate();
		if (rating) {
			template.aggregateRating = rating;
		}
		const review = getReviewTemplate();
		if (review) {
			template.review = review;
		}
		return template;
	};

	const getRecipeTemplate = () => {
		let template = {
			'@context': 'https://schema.org/',
			'@type': 'Recipe',
		};
		if (jsonldNameVal) {
			template.name = jsonldNameVal;
		}
		if (jsonldDescriptionVal) {
			template.description = jsonldDescriptionVal;
		}
		if (jsonldKeywordsVal) {
			template.keywords = jsonldKeywordsVal;
		}
		if (jsonldAuthorNameVal || jsonldAuthorUrlVal) {
			template.author = { '@type': 'Person' };
			if (jsonldAuthorNameVal) {
				template.author.name = jsonldAuthorNameVal;
			}
			if (jsonldAuthorUrlVal) {
				template.author.url = jsonldAuthorUrlVal;
			}
		}
		if (jsonldRecipeYieldVal) {
			template.recipeYield = jsonldRecipeYieldVal;
		}
		if (jsonldRecipeCategoryVal) {
			template.recipeCategory = jsonldRecipeCategoryVal;
		}
		if (jsonldRecipeCuisineVal) {
			template.recipeCuisine = jsonldRecipeCuisineVal;
		}
		if (jsonldIngredientVal) {
			template.recipeIngredient = jsonldIngredientVal.split(/\r?\n/);
		}
		if (jsonldInstructionsVal) {
			template.recipeInstructions = jsonldInstructionsVal.split(/\r?\n/).map((value, index) => ({
				'@type': 'HowToStep',
				name: `Step ${index + 1}`,
				text: value,
			}));
		}
		const rating = getRatingTemplate();
		if (rating) {
			template.aggregateRating = rating;
		}
		return template;
	};

	const updateJsonld = () => {
		let template;
		if (jsonldTypeVal === 'article') {
			template = getArticleTemplate();
		}
		if (jsonldTypeVal === 'person') {
			template = getPersonTemplate();
		}
		if (jsonldTypeVal === 'organization') {
			template = getOrganizationTemplate();
		}
		if (jsonldTypeVal === 'event') {
			template = getEventTemplate();
		}
		if (jsonldTypeVal === 'place') {
			template = getPlaceTemplate();
		}
		if (jsonldTypeVal === 'product') {
			template = getProductTemplate();
		}
		if (jsonldTypeVal === 'recipe') {
			template = getRecipeTemplate();
		}
		if (template) {
			updateJsonldScript(template);
		} else {
			document.getElementById('jsonld-script')?.remove();
		}
	};

	publishDomain.subscribe((value) => {
		publishDomainVal = value;
		updateUrl();
		updateJsonld();
	});

	jsonldType.subscribe((value) => {
		jsonldTypeVal = value;
		updateJsonld();
	});

	jsonldArticleType.subscribe((value) => {
		jsonldArticleTypeVal = value;
		updateJsonld();
	});

	jsonldName.subscribe((value) => {
		jsonldNameVal = value;
		updateJsonld();
	});

	jsonldTitle.subscribe((value) => {
		jsonldTitleVal = value;
		updateJsonld();
	});

	jsonldDescription.subscribe((value) => {
		jsonldDescriptionVal = value;
		updateJsonld();
	});

	jsonldKeywords.subscribe((value) => {
		jsonldKeywordsVal = value;
		updateJsonld();
	});

	jsonldImage.subscribe((value) => {
		jsonldImageVal = value;
		updateJsonld();
	});

	jsonldUrl.subscribe((value) => {
		jsonldUrlVal = value;
		updateJsonld();
	});

	jsonldPage.subscribe((value) => {
		jsonldPageVal = value;
		updateJsonld();
	});

	jsonldAuthorName.subscribe((value) => {
		jsonldAuthorNameVal = value;
		updateJsonld();
	});

	jsonldAuthorUrl.subscribe((value) => {
		jsonldAuthorUrlVal = value;
		updateJsonld();
	});

	jsonldPublisherName.subscribe((value) => {
		jsonldPublisherNameVal = value;
		updateJsonld();
	});

	jsonldPublisherUrl.subscribe((value) => {
		jsonldPublisherUrlVal = value;
		updateJsonld();
	});

	jsonldPerformerName.subscribe((value) => {
		jsonldPerformerNameVal = value;
		updateJsonld();
	});

	jsonldPerformerUrl.subscribe((value) => {
		jsonldPerformerUrlVal = value;
		updateJsonld();
	});

	jsonldOrganizerName.subscribe((value) => {
		jsonldOrganizerNameVal = value;
		updateJsonld();
	});

	jsonldOrganizerUrl.subscribe((value) => {
		jsonldOrganizerUrlVal = value;
		updateJsonld();
	});

	jsonldJobTitle.subscribe((value) => {
		jsonldJobTitleVal = value;
		updateJsonld();
	});

	jsonldJobCompany.subscribe((value) => {
		jsonldJobCompanyVal = value;
		updateJsonld();
	});

	jsonldEmail.subscribe((value) => {
		jsonldEmailVal = value;
		updateJsonld();
	});

	jsonldTwitter.subscribe((value) => {
		jsonldTwitterVal = value;
		updateJsonld();
	});

	jsonldFacebook.subscribe((value) => {
		jsonldFacebookVal = value;
		updateJsonld();
	});

	jsonldLinkedin.subscribe((value) => {
		jsonldLinkedinVal = value;
		updateJsonld();
	});

	jsonldInstagram.subscribe((value) => {
		jsonldInstagramVal = value;
		updateJsonld();
	});

	jsonldYouTube.subscribe((value) => {
		jsonldYouTubeVal = value;
		updateJsonld();
	});

	jsonldGitHub.subscribe((value) => {
		jsonldGitHubVal = value;
		updateJsonld();
	});

	jsonldTelephone.subscribe((value) => {
		jsonldTelephoneVal = value;
		updateJsonld();
	});

	jsonldLatitude.subscribe((value) => {
		jsonldLatitudeVal = value;
		updateJsonld();
	});

	jsonldLongitude.subscribe((value) => {
		jsonldLongitudeVal = value;
		updateJsonld();
	});

	jsonldCurrencyValue.subscribe((value) => {
		jsonldCurrencyValueVal = value;
		updateJsonld();
	});

	jsonldCurrency.subscribe((value) => {
		jsonldCurrencyVal = value;
		updateJsonld();
	});

	jsonldBusinessFunction.subscribe((value) => {
		jsonldBusinessFunctionVal = value;
		updateJsonld();
	});

	jsonldAcceptedPaymentMethods.subscribe((value) => {
		jsonldAcceptedPaymentMethodsVal = value;
		updateJsonld();
	});

	jsonldIngredient.subscribe((value) => {
		jsonldIngredientVal = value;
		updateJsonld();
	});

	jsonldRecipeYield.subscribe((value) => {
		jsonldRecipeYieldVal = value;
		updateJsonld();
	});

	jsonldRecipeCategory.subscribe((value) => {
		jsonldRecipeCategoryVal = value;
		updateJsonld();
	});

	jsonldRecipeCuisine.subscribe((value) => {
		jsonldRecipeCuisineVal = value;
		updateJsonld();
	});

	jsonldInstructions.subscribe((value) => {
		jsonldInstructionsVal = value;
		updateJsonld();
	});

	jsonldStartTime.subscribe((value) => {
		jsonldStartTimeVal = value;
		updateJsonld();
	});

	jsonldEndTime.subscribe((value) => {
		jsonldEndTimeVal = value;
		updateJsonld();
	});

	jsonldAddressStreet.subscribe((value) => {
		jsonldAddressStreetVal = value;
		updateJsonld();
	});

	jsonldAddressLocality.subscribe((value) => {
		jsonldAddressLocalityVal = value;
		updateJsonld();
	});

	jsonldAddressPostalCode.subscribe((value) => {
		jsonldAddressPostalCodeVal = value;
		updateJsonld();
	});

	jsonldAddressRegion.subscribe((value) => {
		jsonldAddressRegionVal = value;
		updateJsonld();
	});

	jsonldAddressCountry.subscribe((value) => {
		jsonldAddressCountryVal = value;
		updateJsonld();
	});

	jsonldBrandName.subscribe((value) => {
		jsonldBrandNameVal = value;
		updateJsonld();
	});

	jsonldSku.subscribe((value) => {
		jsonldSkuVal = value;
		updateJsonld();
	});

	jsonldReviewName.subscribe((value) => {
		jsonldReviewNameVal = value;
		updateJsonld();
	});

	jsonldReviewBody.subscribe((value) => {
		jsonldReviewBodyVal = value;
		updateJsonld();
	});

	jsonldReviewAuthor.subscribe((value) => {
		jsonldReviewAuthorVal = value;
		updateJsonld();
	});

	jsonldReviewPublisher.subscribe((value) => {
		jsonldReviewPublisherVal = value;
		updateJsonld();
	});

	jsonldReviewRatingValue.subscribe((value) => {
		jsonldReviewRatingValueVal = value;
		updateJsonld();
	});

	jsonldReviewBestRating.subscribe((value) => {
		jsonldReviewBestRatingVal = value;
		updateJsonld();
	});

	jsonldAggregateRatingValue.subscribe((value) => {
		jsonldAggregateRatingValueVal = value;
		updateJsonld();
	});

	jsonldAggregateRatingBestRating.subscribe((value) => {
		jsonldAggregateRatingBestRatingVal = value;
		updateJsonld();
	});

	jsonldAggregateRatingCount.subscribe((value) => {
		jsonldAggregateRatingCountVal = value;
		updateJsonld();
	});
</script>
