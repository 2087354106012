<script>
	import Select from 'svelte-select';
	import { sentenceCase } from 'change-case';
	import { jsonldType } from '../../stores/jsonld/JsonldStore';
	import { resetJsonld } from '../../stores/jsonld/ResetJsonld';
	import { updateJsonldType } from '../../stores/jsonld/UpdateJsonldType';

	let jsonldTypeValue;
	let selectedValue = {};

	const options = ['article', 'person', 'organization', 'event', 'place', 'product', 'recipe'].map((value) => ({
		value,
		label: sentenceCase(value),
	}));

	jsonldType.subscribe((value) => {
		jsonldTypeValue = value;
		selectedValue = options.find((item) => value === item.value);
	});

	const jsonldTypeChange = (event) => updateJsonldType(event?.detail?.value || '');

	const jsonldTypeClear = (event) => {
		resetJsonld();
		updateJsonldType('');
	};
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldType">Type:</label>
	<Select
		name="jsonldType"
		items={options}
		value={selectedValue}
		on:select={jsonldTypeChange}
		on:clear={jsonldTypeClear}
	/>
</div>
