<script>
	import classNames from 'classnames';
	import { Tabs, TabList, TabPanel, Tab } from '../../tabs';

	import { isToolbarBodyActive } from '../../stores/toolbar/ToolbarStore';
	import { bodyBgSizeIsActive } from '../../stores/body-bg/BodyBgStore';

	import ToolbarBodyStyler from './ToolbarBodyStyler.svelte';
	import ToolbarBodyButton from './ToolbarBodyButton.svelte';
	import ToolbarBodyBgColor1 from './ToolbarBodyBgColor1.svelte';
	import ToolbarBodyBgColor2 from './ToolbarBodyBgColor2.svelte';
	import ToolbarBodyBgAngle from './ToolbarBodyBgAngle.svelte';
	import ToolbarBodyBgPattern from './ToolbarBodyBgPattern.svelte';
	import ToolbarBodyTextRadius from './ToolbarBodyTextRadius.svelte';
	import ToolbarBodyTextBgColor from './ToolbarBodyTextBgColor.svelte';
	import ToolbarBodyBgWidth from './ToolbarBodyBgWidth.svelte';
	import ToolbarBodyIsSizeActive from './ToolbarBodyIsSizeActive.svelte';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';

	let isToolbarBodyActiveValue;
	let bodyBgSizeIsActiveValue;

	isToolbarBodyActive.subscribe((value) => {
		isToolbarBodyActiveValue = value;
	});

	bodyBgSizeIsActive.subscribe((value) => {
		bodyBgSizeIsActiveValue = value;
	});
</script>

<ToolbarBodyStyler />
<div class={classNames('proteus-modal', { 'is-active': isToolbarBodyActiveValue })}>
	<ToolbarOverlay />
	<div class="proteus-modal-dialog has-tabs">
		<Tabs>
			<TabList>
				<Tab>Background</Tab>
				<Tab>Text</Tab>
			</TabList>
			<TabPanel>
				<ToolbarBodyBgColor1 />
				<ToolbarBodyBgColor2 />
				<ToolbarBodyBgAngle />
				<ToolbarBodyBgPattern />
				<ToolbarBodyIsSizeActive />
				{#if bodyBgSizeIsActiveValue}
					<ToolbarBodyBgWidth />
				{/if}
			</TabPanel>
			<TabPanel>
				<ToolbarBodyTextRadius />
				<ToolbarBodyTextBgColor />
			</TabPanel>
		</Tabs>
	</div>
</div>
