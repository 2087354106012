// toolbar body
export const isToolbarBodyActiveDefault = false;

// toolbar header
export const isToolbarHeaderActiveDefault = false;

// toolbar stripe
export const isToolbarStripeActiveDefault = false;

// toolbar image
export const isToolbarImageActiveDefault = false;

// toolbar save
export const isToolbarSaveActiveDefault = false;

// Is save as
export const isToolbarSaveAsActiveDefault = false;

// toolbar menu
export const isToolbarMenuActiveDefault = false;

// toolbar seo
export const isToolbarSeoActiveDefault = false;

// oembed
export const isToolbarOembedActiveDefault = false;

// toolbar fullscreen mode
export const isToolbarIsFullscreenDefault = false;
