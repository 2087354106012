export const imageIsBusyDefault = false;

export const imageAllDefault = [];

export const imageIdDefault = null;

export const imageAltDefault = '';

export const imageTitleDefault = '';

export const imageBase64Default = null;

export const imageUploadDefault = null;
