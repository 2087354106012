import { writable } from 'svelte/store';
import {
	isToolbarBodyActiveDefault,
	isToolbarHeaderActiveDefault,
	isToolbarStripeActiveDefault,
	isToolbarImageActiveDefault,
	isToolbarSaveActiveDefault,
	isToolbarSaveAsActiveDefault,
	isToolbarMenuActiveDefault,
	isToolbarSeoActiveDefault,
	isToolbarOembedActiveDefault,
	isToolbarIsFullscreenDefault,
} from './ToolbarStoreDefaults';

export const isToolbarBodyActive = writable(isToolbarBodyActiveDefault);

export const isToolbarHeaderActive = writable(isToolbarHeaderActiveDefault);

export const isToolbarStripeActive = writable(isToolbarStripeActiveDefault);

export const isToolbarImageActive = writable(isToolbarImageActiveDefault);

export const isToolbarSaveActive = writable(isToolbarSaveActiveDefault);

export const isToolbarSaveAsActive = writable(isToolbarSaveAsActiveDefault);

export const isToolbarMenuActive = writable(isToolbarMenuActiveDefault);

export const isToolbarSeoActive = writable(isToolbarSeoActiveDefault);

export const isToolbarOembedActive = writable(isToolbarOembedActiveDefault);

export const isToolbarIsFullscreen = writable(isToolbarIsFullscreenDefault);
