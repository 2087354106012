<script>
	import IconBodyBg from '../../icons/IconBodyBg.svelte';

	import { isToolbarBodyActive } from '../../stores/toolbar/ToolbarStore';
	import { updateToolbarBody } from '../../stores/toolbar/UpdateToolbarBody';

	let isToolbarBodyActiveValue;

	isToolbarBodyActive.subscribe((value) => {
		isToolbarBodyActiveValue = value;
	});

	const onToggle = () => updateToolbarBody(!isToolbarBodyActiveValue);
</script>

<button class="proteus-toolbar-button" on:click={onToggle}>
	<IconBodyBg />
</button>
