<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import LoaderFullscreen from '../LoaderFullscreen.svelte';
	import MobileNumber from '../MobileNumber.svelte';
	import Otp from '../Otp.svelte';
	import { getErrorMessage } from '../utils';

	let isOtpSent = false;
	let mobileNumber = '';
	let msg = '';
	let msgType = '';
	let isBusy = false;

	const onOtpSent = (mobile) => {
		isOtpSent = mobile ? true : false;
		mobileNumber = mobile;
	};

	const onOtpResent = () => {
		isOtpSent = false;
	};

	const ids = new URLSearchParams(location.search).getAll('ids');
	const url = new URLSearchParams(location.search).get('url');

	const onRequestOtp = async (value) => {
		let response;
		try {
			response = await axios.post('/api/user-checkout', { username: `+${value}` });
			localStorage.setItem('otp', response.data);
			msg = 'code sent';
			msgType = 'success';
			onOtpSent(`+${value}`);
		} catch (err) {
			console.log('Error requesting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			onOtpSent(false);
		}
	};

	const onComplete = async (value) => {
		msg = undefined;
		isBusy = true;
		let response;
		try {
			const { id, userId } = await localStorage.getItem('otp');
			response = await axios.post('/api/user-checkout', { id, username: userId, otp: value, orderIds: ids });
			parent.location.href = url;
		} catch (err) {
			console.log('Error submitting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			isBusy = false;
		}
	};
</script>

<div class="proteus-container">
	<div class="proteus-login-card">
		<div class="proteus-login">
			<h2 class="proteus-login-title">simple checkout</h2>
			<p class="proteus-login-info">We send a secure code to your mobile number whenever you want to checkout</p>
			<p class="proteus-login-subtitle">
				<strong>Mobile Number</strong>
			</p>
			<MobileNumber {onRequestOtp} {onOtpSent} {msg} {msgType} />
			<p>
				<strong> checkout </strong>
				by entering the code we send you
			</p>
			<Otp {onComplete} {msg} {msgType} />
		</div>
	</div>
</div>

{#if isBusy}
	<LoaderFullscreen />
{/if}
