<script>
	const onClick = (e) => {
		// e.preventDefault();
	};
</script>

<a class="proteus-logo" href="/" on:click={onClick}>
	<svg
		height="100%"
		stroke-miterlimit="10"
		style="fill-rule:nonzero;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;"
		version="1.1"
		view-box="0 0 128 64"
		viewBox="0 10 128 40"
		width="100%"
		xml:space="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:vectornator="http://vectornator.io"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g id="Layer-1" vectornator:layerName="Layer 1">
			<g fill="#333dfc" opacity="1" stroke="none">
				<path
					d="M27.1575 48.3151C28.4043 48.3151 29.2844 47.3861 29.2844 46.0415L29.2844 40.5408L32.5115 40.5408C33.685 40.5408 34.5651 39.783 34.5651 38.6095C34.5651 37.436 33.685 36.6781 32.5115 36.6781L29.2844 36.6781L29.2844 15.629C29.2844 13.3554 28.0376 12.1574 25.6907 12.1574C23.7593 12.1574 22.6103 13.0131 20.6301 15.8734C15.6673 22.9876 10.8022 30.1751 8.25971 34.7224C7.45295 36.1647 7.20847 36.9226 7.20847 38.0472C7.20847 39.5629 8.18637 40.5408 9.751 40.5408L25.0306 40.5408L25.0306 46.0415C25.0306 47.3861 25.9107 48.3151 27.1575 48.3151ZM25.0306 36.6781L11.609 36.6781L11.609 36.5804C14.4693 31.5686 18.4298 25.7502 24.9573 16.4113L25.0306 16.4113L25.0306 36.6781Z"
					fill-rule="evenodd"
				/>
				<path
					d="M39.7968 42.0077C39.7968 46.0659 41.8504 48.0706 45.9575 48.0706L46.2265 48.0706C48.5979 48.0706 49.3313 47.3861 49.3313 46.0904C49.3313 45.088 48.6956 44.5257 47.3021 44.428L46.9354 44.4035C44.8085 44.2568 44.0506 43.23 44.0506 41.0787L44.0506 25.9457L47.3266 25.9457C48.549 25.9457 49.2335 25.3101 49.2335 24.1855C49.2335 23.0854 48.549 22.4498 47.3266 22.4498L44.0506 22.4498L44.0506 18.0981C44.0506 16.7535 43.1705 15.8245 41.9237 15.8245C40.6769 15.8245 39.7968 16.7535 39.7968 18.0981L39.7968 22.4498L37.6699 22.4498C36.4475 22.4498 35.763 23.0854 35.763 24.1855C35.763 25.3101 36.4475 25.9457 37.6699 25.9457L39.7968 25.9457L39.7968 42.0077Z"
					fill-rule="evenodd"
				/>
				<path
					d="M71.774 48.3151C73.0208 48.3151 73.9009 47.3861 73.9009 46.0415L73.9009 40.5408L77.1279 40.5408C78.3014 40.5408 79.1815 39.783 79.1815 38.6095C79.1815 37.436 78.3014 36.6781 77.1279 36.6781L73.9009 36.6781L73.9009 15.629C73.9009 13.3554 72.6541 12.1574 70.3071 12.1574C68.3758 12.1574 67.2267 13.0131 65.2465 15.8734C60.2837 22.9876 55.4187 30.1751 52.8761 34.7224C52.0694 36.1647 51.8249 36.9226 51.8249 38.0472C51.8249 39.5629 52.8028 40.5408 54.3674 40.5408L69.647 40.5408L69.647 46.0415C69.647 47.3861 70.5271 48.3151 71.774 48.3151ZM69.647 36.6781L56.2254 36.6781L56.2254 36.5804C59.0858 31.5686 63.0462 25.7502 69.5737 16.4113L69.647 16.4113L69.647 36.6781Z"
					fill-rule="evenodd"
				/>
			</g>
			<path
				d="M85.4779 48.663C87.6959 48.663 89.5001 46.8588 89.5001 44.6408C89.5001 42.4227 87.6959 40.6185 85.4779 40.6185C83.2599 40.6185 81.4557 42.4227 81.4557 44.6408C81.4557 46.8588 83.2599 48.663 85.4779 48.663Z"
				fill="#fc33b4"
				fill-rule="evenodd"
				opacity="1"
				stroke="none"
			/>
			<g fill="#333dfc" opacity="1" stroke="none">
				<path
					d="M97.0684 28.1088C98.2271 28.1088 99.154 27.1819 99.154 26.0563C99.154 24.9142 98.2271 24.0204 97.0684 24.0204C95.9263 24.0204 94.9994 24.9142 94.9994 26.0563C94.9994 27.1819 95.9263 28.1088 97.0684 28.1088ZM97.0684 48.4682C98.1443 48.4682 98.8561 47.7234 98.8561 46.5813L98.8561 32.3297C98.8561 31.171 98.1443 30.4261 97.0684 30.4261C95.9925 30.4261 95.2807 31.171 95.2807 32.3297L95.2807 46.5813C95.2807 47.7234 95.9925 48.4682 97.0684 48.4682Z"
					fill-rule="evenodd"
				/>
				<path
					d="M112.996 48.4848C117.978 48.4848 121.255 45.0585 121.255 39.4472C121.255 33.8525 117.962 30.4261 112.996 30.4261C108.03 30.4261 104.736 33.8525 104.736 39.4472C104.736 45.0585 108.014 48.4848 112.996 48.4848ZM112.996 45.5385C110.198 45.5385 108.378 43.3205 108.378 39.4472C108.378 35.5905 110.198 33.3725 112.996 33.3725C115.793 33.3725 117.614 35.5905 117.614 39.4472C117.614 43.3205 115.81 45.5385 112.996 45.5385Z"
					fill-rule="evenodd"
				/>
			</g>
		</g>
	</svg>
</a>
