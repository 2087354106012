<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import DomainChecker from '../DomainChecker.svelte';
	import LoaderFullscreen from '../LoaderFullscreen.svelte';
	import Logo from '../Logo.svelte';
	import MobileNumberInputAlt from '../MobileNumberInputAlt.svelte';
	import Otp from '../Otp.svelte';
	import { updateAuthIsLoggedIn } from '../stores/auth/UpdateAuthIsLoggedIn';
	import { getErrorMessage } from '../utils';

	// domain
	let domain = '';
	let isDomainValid = false;

	// otp
	let otp = '';
	let otpId = '';
	let isOtpSent = false;
	let isOtpResent = false;

	// phone number
	let phone = '';

	// msg and msg type - can be either success or error
	let msg = '';
	let msgType = '';

	let isBusy = false;

	// when value changes - update this
	const onDomainChange = (value, isValid) => {
		domain = value;
		isDomainValid = isValid;
	};

	// when mobile number changes - update this
	const onPhoneChange = (value) => {
		console.log('onPhoneChange - ', value);
		phone = `+${value}`;
	};

	// triggered after sending otp - receives phone number
	const onOtpSent = () => {
		isOtpSent = true;
	};

	const onOtpResent = () => {
		isOtpResent = true;
	};

	const onDomainSubmit = async () => {
		onOtpRequest();
	};

	const onPhoneSubmit = async () => {
		onOtpRequest();
	};

	const onOtpRequest = async () => {
		isBusy = true;
		let response;
		try {
			response = await axios.post('/api/register', { username: phone, domain });
			otpId = response.data.id;
			msg = 'code sent';
			msgType = 'success';
			onOtpSent();
		} catch (err) {
			console.log('Error requesting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			onOtpSent(false);
		}
		isBusy = false;
	};

	const onOtpSubmit = async (otpValue) => {
		if (!otpValue) {
			msg = 'Invalid OTP';
			msgType = 'error';
			return;
		}
		if (!domain) {
			msg = 'Invalid domain name';
			msgType = 'error';
			return;
		}
		isBusy = true;
		msg = undefined;
		isBusy = true;
		otp = otpValue;
		let response;
		try {
			response = await axios.post('/api/register', { username: phone, domain, otp: otpValue, id: otpId });
			const data = response.data;
			await localStorage.clear();
			await localStorage.setItem('otp', data);
			await localStorage.setItem('domain', data.domain);
			updateAuthIsLoggedIn(true);
			window.location = '/?page=editor';
		} catch (err) {
			console.log('Error submitting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			isBusy = false;
		}
		isBusy = false;
	};
</script>

<link rel="stylesheet" href="/public/home.css" />
<link rel="stylesheet" href="/public/login.css" />
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap" />

<main>
	<p class="logo">
		<Logo />
	</p>
	<h2 class="primary">
		<strong class="primary"> Welcome! </strong>
	</h2>
	<h2>
		<strong> choose your subdomain name </strong>
	</h2>
	<h3>(you can change it)</h3>

	<!-- domain input -->
	<DomainChecker {phone} onChange={onDomainChange} onSubmit={onDomainSubmit} />

	<h3 style="color: #555">secure log in</h3>
	<h2>
		<strong> enter your mobile number (with country code)</strong>
	</h2>
	<h3>and then the code we send you</h3>

	<MobileNumberInputAlt onChange={onPhoneChange} onSubmit={onPhoneSubmit} />

	<p class="terms-text">
		by signing up you are accepting our
		<a href="https://terms.4t4.io"> terms and conditions </a>
		and
		<a href="https://privacy.4t4.io"> privacy policy </a>
	</p>

	<h3>
		<strong>enter code</strong>
	</h3>
	<p>
		<Otp onSubmit={onOtpSubmit} {msg} {msgType} />
	</p>
	{#if msg}
		<p class="is-flex">
			<span style={`color: ${msgType === 'error' ? 'red' : 'green'}`}>{msg || ''}</span>
		</p>
	{/if}

	<p class="mascot">
		<img
			src="https://ik.imagekit.io/ymjlyrr8zyb/pezq-r-hearts_hOpUAvJg9.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1659824490016"
			width="256"
			title="pezqi-left"
			alt="pezqi-left"
		/>
	</p>

	{#if isBusy}
		<LoaderFullscreen />
	{/if}
</main>
