<aside id="main-nav" class="main-nav">
	<span id="main-nav-toggle" class="main-nav-toggle">
		<svg viewBox="0 0 32 32">
			<path d="M21,17H11a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" />
			<path d="M21,12H11a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" />
			<path d="M21,22H11a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" />
		</svg>
	</span>
	<nav id="main-nav-menu" class="main-nav-menu"></nav>
</aside>
