import { writable } from 'svelte/store';
import { editorInstanceDefault, editorFocusedEleDefault, editorDataDefault } from './EditorStoreDefaults';

// editor data
export const editorData = writable(editorDataDefault);

// editor instance
export const editorInstance = writable(editorInstanceDefault);

// editor focused element
export const editorFocusedEle = writable(editorFocusedEleDefault);
