<script>
	import axios from 'axios';
	import { onMount } from 'svelte';
	import localStorage from 'localforage';
	import Editor from './Editor.svelte';

	const pages = [
		// 'about',
		'home',
		'editor',
		'pricing',
		// 'how-it-works',
		'login',
		'register',
		'profile',
		'uploads',
		'logout',
		'404',
		'checkout',
		'download',
		'payment-success',
		'payment-error',
	];

	$: activePage = new URLSearchParams(location.search).get('page');
	$: dataChanged(activePage);
	function dataChanged(value) {
		if (!value || value?.trim() === '') {
			activePage = 'home';
		}
		if (pages.includes(value) !== true) {
			if (window.location.pathname === '/') {
				activePage = 'home';
			} else {
				activePage = '404';
			}
		}
		console.log(activePage);
	}

	// =========================================================================
	// Editor store
	// =========================================================================

	import { editorData, editorInstance, editorFocusedEle } from './stores/editor/EditorStore';

	import { updateEditorData } from './stores/editor/UpdateEditorData';
	import { updateEditorInstance } from './stores/editor/UpdateEditorInstance';
	import { updateEditorFocusedEle } from './stores/editor/UpdateEditorFocusedEle';

	// =========================================================================
	// Body bg store
	// =========================================================================

	import {
		bodyBgType,
		bodyBgColor,
		bodyBgGradientCode,
		bodyBgGradientAngle,
		bodyBgGradient1,
		bodyBgGradient2,
		bodyBgSizeIsActive,
		bodyBgWidth,
		bodyBgHeight,
		bodyBgPosition,
		bodyBgRepeat,
		bodyBgSize,
		bodyBgPattern,
		bodyLayoutWidth,
	} from './stores/body-bg/BodyBgStore';

	import { updateBodyBgType } from './stores/body-bg/UpdateBodyBgType';
	import { updateBodyBgColor } from './stores/body-bg/UpdateBodyBgColor';
	import { updateBodyBgGradientCode } from './stores/body-bg/UpdateBodyBgGradientCode';
	import { updateBodyBgGradientAngle } from './stores/body-bg/UpdateBodyBgGradientAngle';
	import { updateBodyBgGradient1 } from './stores/body-bg/UpdateBodyBgGradient1';
	import { updateBodyBgGradient2 } from './stores/body-bg/UpdateBodyBgGradient2';
	import { updateBodyBgSizeIsActive } from './stores/body-bg/UpdateBodyBgSizeIsActive';
	import { updateBodyBgWidth } from './stores/body-bg/UpdateBodyBgWidth';
	import { updateBodyBgHeight } from './stores/body-bg/UpdateBodyBgHeight';
	import { updateBodyBgPosition } from './stores/body-bg/UpdateBodyBgPosition';
	import { updateBodyBgRepeat } from './stores/body-bg/UpdateBodyBgRepeat';
	import { updateBodyBgSize } from './stores/body-bg/UpdateBodyBgSize';
	import { updateBodyBgPattern } from './stores/body-bg/UpdateBodyBgPattern';

	import { bodyTextColor, bodyTextRadius } from './stores/body-text/BodyTextStore';
	import { updateBodyTextRadius } from './stores/body-text/UpdateBodyTextRadius';
	import { updateBodyTextColor } from './stores/body-text/UpdateBodyTextColor';

	import { updateBodyLayoutWidth } from './stores/body-bg/UpdateBodyLayoutWidth';

	import { bodyShadowBlur, bodyShadowColor, bodyShadowSpread, bodyShadowX, bodyShadowY } from './stores/body-shadow/BodyShadowStore';

	import { updateBodyShadowX } from './stores/body-shadow/UpdateBodyShadowX';
	import { updateBodyShadowY } from './stores/body-shadow/UpdateBodyShadowY';
	import { updateBodyShadowBlur } from './stores/body-shadow/UpdateBodyShadowBlur';
	import { updateBodyShadowSpread } from './stores/body-shadow/UpdateBodyShadowSpread';
	import { updateBodyShadowColor } from './stores/body-shadow/UpdateBodyShadowColor';

	// =========================================================================
	// Header store
	// =========================================================================

	import {
		headerBgType,
		headerBgColor,
		headerBgGradientCode,
		headerBgGradientAngle,
		headerBgGradient1,
		headerBgGradient2,
		headerBgWidth,
		headerBgHeight,
		headerBgPosition,
		headerBgRepeat,
		headerBgSize,
		headerBgPattern,
	} from './stores/header-bg/HeaderBgStore';

	import { updateHeaderBgType } from './stores/header-bg/UpdateHeaderBgType';
	import { updateHeaderBgColor } from './stores/header-bg/UpdateHeaderBgColor';
	import { updateHeaderBgGradientCode } from './stores/header-bg/UpdateHeaderBgGradientCode';
	import { updateHeaderBgGradientAngle } from './stores/header-bg/UpdateHeaderBgGradientAngle';
	import { updateHeaderBgGradient1 } from './stores/header-bg/UpdateHeaderBgGradient1';
	import { updateHeaderBgGradient2 } from './stores/header-bg/UpdateHeaderBgGradient2';
	import { updateHeaderBgWidth } from './stores/header-bg/UpdateHeaderBgWidth';
	import { updateHeaderBgHeight } from './stores/header-bg/UpdateHeaderBgHeight';
	import { updateHeaderBgPosition } from './stores/header-bg/UpdateHeaderBgPosition';
	import { updateHeaderBgRepeat } from './stores/header-bg/UpdateHeaderBgRepeat';
	import { updateHeaderBgSize } from './stores/header-bg/UpdateHeaderBgSize';
	import { updateHeaderBgPattern } from './stores/header-bg/UpdateHeaderBgPattern';
	import { resetHeaderBgStore } from './stores/header-bg/ResetHeaderBgStore';

	// =========================================================================
	// Logo store
	// =========================================================================

	import {
		headerLogoStart,
		headerLogoCenter,
		headerLogoEnd,
		headerLogoStartAlt,
		headerLogoStartTitle,
		headerLogoCenterAlt,
		headerLogoCenterTitle,
		headerLogoEndAlt,
		headerLogoEndTitle,
	} from './stores/header-logo/HeaderLogoStore';

	import { updateHeaderLogoStart } from './stores/header-logo/UpdateHeaderLogoStart';
	import { updateHeaderLogoStartAlt } from './stores/header-logo/UpdateHeaderLogoStartAlt';
	import { updateHeaderLogoStartTitle } from './stores/header-logo/UpdateHeaderLogoStartTitle';

	import { updateHeaderLogoCenter } from './stores/header-logo/UpdateHeaderLogoCenter';
	import { updateHeaderLogoCenterAlt } from './stores/header-logo/UpdateHeaderLogoCenterAlt';
	import { updateHeaderLogoCenterTitle } from './stores/header-logo/UpdateHeaderLogoCenterTitle';

	import { updateHeaderLogoEnd } from './stores/header-logo/UpdateHeaderLogoEnd';
	import { updateHeaderLogoEndAlt } from './stores/header-logo/UpdateHeaderLogoEndAlt';
	import { updateHeaderLogoEndTitle } from './stores/header-logo/UpdateHeaderLogoEndTitle';

	// =========================================================================
	// Stripe store
	// =========================================================================

	import {
		stripeAll,
		stripeId,
		stripeSecretKey,
		stripePriceId,
		stripeIsIDealEnabled,
		stripeTitle,
		stripeUrl,
		stripeRadius,
		stripeBgColor,
		stripeFgColor,
	} from './stores/stripe/StripeStore';

	import { updateStripeAll } from './stores/stripe/UpdateStripeAll';
	import { updateStripeId } from './stores/stripe/UpdateStripeId';
	import { updateStripeSecretKey } from './stores/stripe/UpdateStripeSecretKey';
	import { updateStripePriceId } from './stores/stripe/UpdateStripePriceId';
	import { updateStripeIsIDealEnabled } from './stores/stripe/UpdateStripeIsIDealEnabled';
	import { updateStripeTitle } from './stores/stripe/UpdateStripeTitle';
	import { updateStripeUrl } from './stores/stripe/UpdateStripeUrl';
	import { updateStripeRadius } from './stores/stripe/UpdateStripeRadius';
	import { updateStripeBgColor } from './stores/stripe/UpdateStripeBgColor';
	import { updateStripeFgColor } from './stores/stripe/UpdateStripeFgColor';

	// =========================================================================
	// Toolbar store
	// =========================================================================

	import { isToolbarSaveAsActive } from './stores/toolbar/ToolbarStore';

	// =========================================================================
	// Image store
	// =========================================================================

	import { imageAll, imageId, imageAlt, imageTitle, imageUpload } from './stores/image/ImageStore';

	import { updateImageAll } from './stores/image/UpdateImageAll';
	import { updateImageId } from './stores/image/UpdateImageId';
	import { updateImageAlt } from './stores/image/UpdateImageAlt';
	import { updateImageTitle } from './stores/image/UpdateImageTitle';
	import { updateImageUpload } from './stores/image/UpdateImageUpload';

	// =========================================================================
	// Publish store
	// =========================================================================

	import {
		publishDomain,
		publishSubdomain,
		publishIsUpdate,
		publishTitle,
		publishDescription,
		publishUsername,
		publishOtp,
		publishIsOtpSent,
	} from './stores/publish/PublishStore';

	import { updatePublishDomain } from './stores/publish/UpdatePublishDomain';
	import { updatePublishSubdomain } from './stores/publish/UpdatePublishSubdomain';
	import { updatePublishIsUpdate } from './stores/publish/UpdatePublishIsUpdate';
	import { updatePublishTitle } from './stores/publish/UpdatePublishTitle';
	import { updatePublishDescription } from './stores/publish/UpdatePublishDescription';
	import { updatePublishPayload } from './stores/publish/UpdatePublishPayload';
	import { updatePublishUsername } from './stores/publish/UpdatePublishUsername';
	import { updatePublishOtp } from './stores/publish/UpdatePublishOtp';
	import { updatePublishIsOtpSent } from './stores/publish/UpdatePublishIsOtpSent';

	// =========================================================================
	// Menu store
	// =========================================================================

	import { menuItems, menuActiveIndex, menuLinkTitle, menuLinkUrl } from './stores/menu/MenuStore';

	// =========================================================================
	// SEO store
	// =========================================================================

	import {
		seoOgIsActive,
		seoFavicon,
		seoOgTitle,
		seoOgDescription,
		seoOgImage,
		seoOgType,
		seoOgTwitterCard,
		seoOgTwitterTitle,
		seoOgTwitterDescription,
		seoOgTwitterImage,
		seoOgTwitterSite,
		seoOgTwitterCreator,
	} from './stores/seo/SeoStore';
	import { updateSeoOgIsActive } from './stores/seo/UpdateSeoOgIsActive';
	import { updateSeoFavicon } from './stores/seo/UpdateSeoFavicon';
	import { updateSeoOgTitle } from './stores/seo/UpdateSeoOgTitle';
	import { updateSeoOgDescription } from './stores/seo/UpdateSeoOgDescription';
	import { updateSeoOgImage } from './stores/seo/UpdateSeoOgImage';
	import { updateSeoOgType } from './stores/seo/UpdateSeoOgType';
	import { updateSeoOgTwitterCard } from './stores/seo/UpdateSeoOgTwitterCard';
	import { updateSeoOgTwitterTitle } from './stores/seo/UpdateSeoOgTwitterTitle';
	import { updateSeoOgTwitterDescription } from './stores/seo/UpdateSeoOgTwitterDescription';
	import { updateSeoOgTwitterImage } from './stores/seo/UpdateSeoOgTwitterImage';
	import { updateSeoOgTwitterSite } from './stores/seo/UpdateSeoOgTwitterSite';
	import { updateSeoOgTwitterCreator } from './stores/seo/UpdateSeoOgTwitterCreator';

	// =========================================================================
	// Oembed store
	// =========================================================================

	import { oembedId, oembedUrl, oembedData, oembedAll } from './stores/oembed/OembedStore';
	import { updateOembedId } from './stores/oembed/UpdateOembedId';
	import { updateOembedUrl } from './stores/oembed/UpdateOembedUrl';
	import { updateOembedData } from './stores/oembed/UpdateOembedData';
	import { updateOembedAll } from './stores/oembed/UpdateOembedAll';

	// =========================================================================
	// jsonld store
	// =========================================================================

	import {
		jsonldType,
		jsonldArticleType,
		jsonldName,
		jsonldTitle,
		jsonldDescription,
		jsonldKeywords,
		jsonldImage,
		jsonldUrl,
		jsonldPage,
		jsonldAuthorName,
		jsonldAuthorUrl,
		jsonldPublisherName,
		jsonldPublisherUrl,
		jsonldPerformerName,
		jsonldPerformerUrl,
		jsonldOrganizerName,
		jsonldOrganizerUrl,
		jsonldJobTitle,
		jsonldJobCompany,
		jsonldEmail,
		jsonldTelephone,
		jsonldTwitter,
		jsonldFacebook,
		jsonldLinkedin,
		jsonldInstagram,
		jsonldYouTube,
		jsonldGitHub,
		jsonldLatitude,
		jsonldLongitude,
		jsonldCurrencyValue,
		jsonldCurrency,
		jsonldBusinessFunction,
		jsonldAcceptedPaymentMethods,
		jsonldIngredient,
		jsonldRecipeYield,
		jsonldRecipeCategory,
		jsonldRecipeCuisine,
		jsonldInstructions,
		jsonldStartTime,
		jsonldEndTime,
		jsonldAddressStreet,
		jsonldAddressLocality,
		jsonldAddressPostalCode,
		jsonldAddressRegion,
		jsonldAddressCountry,
		jsonldBrandName,
		jsonldSku,
		jsonldReviewName,
		jsonldReviewBody,
		jsonldReviewAuthor,
		jsonldReviewPublisher,
		jsonldReviewRatingValue,
		jsonldReviewBestRating,
		jsonldAggregateRatingValue,
		jsonldAggregateRatingBestRating,
		jsonldAggregateRatingCount,
	} from './stores/jsonld/JsonldStore';
	import { updateJsonldType } from './stores/jsonld/UpdateJsonldType';
	import { updateJsonldArticleType } from './stores/jsonld/UpdateJsonldArticleType';
	import { updateJsonldName } from './stores/jsonld/UpdateJsonldName';
	import { updateJsonldTitle } from './stores/jsonld/UpdateJsonldTitle';
	import { updateJsonldDescription } from './stores/jsonld/UpdateJsonldDescription';
	import { updateJsonldKeywords } from './stores/jsonld/UpdateJsonldKeywords';
	import { updateJsonldImage } from './stores/jsonld/UpdateJsonldImage';
	import { updateJsonldUrl } from './stores/jsonld/UpdateJsonldUrl';
	import { updateJsonldPage } from './stores/jsonld/UpdateJsonldPage';
	import { updateJsonldAuthorName } from './stores/jsonld/UpdateJsonldAuthorName';
	import { updateJsonldAuthorUrl } from './stores/jsonld/UpdateJsonldAuthorUrl';
	import { updateJsonldPublisherName } from './stores/jsonld/UpdateJsonldPublisherName';
	import { updateJsonldPublisherUrl } from './stores/jsonld/UpdateJsonldPublisherUrl';
	import { updateJsonldPerformerName } from './stores/jsonld/UpdateJsonldPerformerName';
	import { updateJsonldPerformerUrl } from './stores/jsonld/UpdateJsonldPerformerUrl';
	import { updateJsonldOrganizerName } from './stores/jsonld/UpdateJsonldOrganizerName';
	import { updateJsonldOrganizerUrl } from './stores/jsonld/UpdateJsonldOrganizerUrl';
	import { updateJsonldJobTitle } from './stores/jsonld/UpdateJsonldJobTitle';
	import { updateJsonldJobCompany } from './stores/jsonld/UpdateJsonldJobCompany';
	import { updateJsonldEmail } from './stores/jsonld/UpdateJsonldEmail';
	import { updateJsonldTelephone } from './stores/jsonld/UpdateJsonldTelephone';
	import { updateJsonldTwitter } from './stores/jsonld/UpdateJsonldTwitter';
	import { updateJsonldFacebook } from './stores/jsonld/UpdateJsonldFacebook';
	import { updateJsonldLinkedin } from './stores/jsonld/UpdateJsonldLinkedin';
	import { updateJsonldInstagram } from './stores/jsonld/UpdateJsonldInstagram';
	import { updateJsonldYouTube } from './stores/jsonld/UpdateJsonldYouTube';
	import { updateJsonldGitHub } from './stores/jsonld/UpdateJsonldGitHub';
	import { updateJsonldLatitude } from './stores/jsonld/UpdateJsonldLatitude';
	import { updateJsonldLongitude } from './stores/jsonld/UpdateJsonldLongitude';
	import { updateJsonldCurrencyValue } from './stores/jsonld/UpdateJsonldCurrencyValue';
	import { updateJsonldCurrency } from './stores/jsonld/UpdateJsonldCurrency';
	import { updateJsonldBusinessFunction } from './stores/jsonld/UpdateJsonldBusinessFunction';
	import { updateJsonldAcceptedPaymentMethods } from './stores/jsonld/UpdateJsonldAcceptedPaymentMethods';
	import { updateJsonldIngredient } from './stores/jsonld/UpdateJsonldIngredient';
	import { updateJsonldRecipeYield } from './stores/jsonld/UpdateJsonldRecipeYield';
	import { updateJsonldRecipeCategory } from './stores/jsonld/UpdateJsonldRecipeCategory';
	import { updateJsonldRecipeCuisine } from './stores/jsonld/UpdateJsonldRecipeCuisine';
	import { updateJsonldInstructions } from './stores/jsonld/UpdateJsonldInstructions';
	import { updateJsonldStartTime } from './stores/jsonld/UpdateJsonldStartTime';
	import { updateJsonldEndTime } from './stores/jsonld/UpdateJsonldEndTime';
	import { updateJsonldAddressStreet } from './stores/jsonld/UpdateJsonldAddressStreet';
	import { updateJsonldAddressLocality } from './stores/jsonld/UpdateJsonldAddressLocality';
	import { updateJsonldAddressPostalCode } from './stores/jsonld/UpdateJsonldAddressPostalCode';
	import { updateJsonldAddressRegion } from './stores/jsonld/UpdateJsonldAddressRegion';
	import { updateJsonldAddressCountry } from './stores/jsonld/UpdateJsonldAddressCountry';
	import { updateJsonldBrandName } from './stores/jsonld/UpdateJsonldBrandName';
	import { updateJsonldSku } from './stores/jsonld/UpdateJsonldSku';
	import { updateJsonldReviewName } from './stores/jsonld/UpdateJsonldReviewName';
	import { updateJsonldReviewBody } from './stores/jsonld/UpdateJsonldReviewBody';
	import { updateJsonldReviewAuthor } from './stores/jsonld/UpdateJsonldReviewAuthor';
	import { updateJsonldReviewPublisher } from './stores/jsonld/UpdateJsonldReviewPublisher';
	import { updateJsonldReviewRatingValue } from './stores/jsonld/UpdateJsonldReviewRatingValue';
	import { updateJsonldReviewBestRating } from './stores/jsonld/UpdateJsonldReviewBestRating';
	import { updateJsonldAggregateRatingValue } from './stores/jsonld/UpdateJsonldAggregateRatingValue';
	import { updateJsonldAggregateRatingBestRating } from './stores/jsonld/UpdateJsonldAggregateRatingBestRating';
	import { updateJsonldAggregateRatingCount } from './stores/jsonld/UpdateJsonldAggregateRatingCount';

	// =========================================================================
	// App core variables
	// @todo port to stores
	// =========================================================================

	import { getNotificationsContext } from 'svelte-notifications';
	import Toolbar from './toolbar/Toolbar.svelte';
	import { getActiveDomain, getActiveSubdomain } from './utils';
	import Login from './pages/Login.svelte';
	import Register from './pages/Register.svelte';
	import Logout from './pages/Logout.svelte';
	import Pricing from './pages/Pricing.svelte';
	import Profile from './pages/Profile.svelte';
	import Uploads from './pages/Uploads.svelte';
	import HowItWorks from './pages/HowItWorks.svelte';
	import About from './pages/About.svelte';
	import PageHeader from './PageHeader.svelte';
	import PageNav from './PageNav.svelte';
	import PageEditor from './PageEditor.svelte';
	import AutoLogin from './AutoLogin.svelte';
	import Home from './pages/Home.svelte';
	import Error404 from './pages/Error404.svelte';
	import Checkout from './pages/Checkout.svelte';
	import Download from './pages/Download.svelte';
	import Header from './Header.svelte';
	import LoaderFullscreen from './LoaderFullscreen.svelte';
	import ExitFullScreen from './ExitFullScreen.svelte';
	import Subscribe from './Subscribe.svelte';
	import PaymentSuccess from './pages/PaymentSuccess.svelte';
	import PaymentError from './pages/PaymentError.svelte';
	import { updateAuthSubscription } from './stores/auth/UpdateAuthSubscription';
	import { resetAuthSubscription } from './stores/auth/ResetAuthSubscription';
	import { resetAuthPlan } from './stores/auth/ResetAuthPlan';
	import { updateAuthPlan } from './stores/auth/UpdateAuthPlan';
	const { addNotification } = getNotificationsContext();

	// core variables
	let isAutosaveDisabled = true;
	let domain = '';
	let subdomain = '';

	// busy and loading variables
	let isBusy = false;
	let isLoading = true;

	// =========================================================================
	// Toolbar store values
	// =========================================================================

	let isToolbarSaveAsActiveValue;

	// =========================================================================
	// Editor store values
	// =========================================================================

	let editorDataValue;
	let editorInstanceValue;
	let editorFocusedEleValue;

	// =========================================================================
	// Body store values
	// =========================================================================

	let bodyBgTypeValue;
	let bodyBgColorValue;
	let bodyBgGradientCodeValue;
	let bodyBgGradientAngleValue;
	let bodyBgGradient1Value;
	let bodyBgGradient2Value;
	let bodyBgSizeIsActiveValue;
	let bodyBgWidthValue;
	let bodyBgHeightValue;
	let bodyBgPositionValue;
	let bodyBgRepeatValue;
	let bodyBgSizeValue;
	let bodyBgPatternValue;

	let bodyTextColorValue;
	let bodyTextRadiusValue;

	let bodyShadowXValue;
	let bodyShadowYValue;
	let bodyShadowBlurValue;
	let bodyShadowSpreadValue;
	let bodyShadowColorValue;

	let bodyLayoutWidthValue;

	// =========================================================================
	// Header store values
	// =========================================================================

	let headerBgTypeValue;
	let headerBgColorValue;
	let headerBgGradientCodeValue;
	let headerBgGradientAngleValue;
	let headerBgGradient1Value;
	let headerBgGradient2Value;
	let headerBgWidthValue;
	let headerBgHeightValue;
	let headerBgPositionValue;
	let headerBgRepeatValue;
	let headerBgSizeValue;
	let headerBgPatternValue;

	// =========================================================================
	// Logo store values
	// =========================================================================

	let headerLogoStartValue;
	let headerLogoCenterValue;
	let headerLogoEndValue;

	let headerLogoStartAltValue;
	let headerLogoStartTitleValue;
	let headerLogoCenterAltValue;

	let headerLogoCenterTitleValue;
	let headerLogoEndAltValue;
	let headerLogoEndTitleValue;

	// =========================================================================
	// Stripe store values
	// =========================================================================

	let stripeAllValue;
	let stripeIdValue;
	let stripeSecretKeyValue;
	let stripePriceIdValue;
	let stripeIsIDealEnabledValue;
	let stripeTitleValue;
	let stripeUrlValue;
	let stripeBgColorValue;
	let stripeFgColorValue;
	let stripeRadiusValue;

	// =========================================================================
	// Stripe store values
	// =========================================================================

	let imageAllValue;
	let imageIdValue;
	let imageAltValue;
	let imageTitleValue;
	let imageUploadValue;

	// =========================================================================
	// Publish store values
	// =========================================================================

	let publishDomainValue;
	let publishSubdomainValue;
	let publishIsUpdateValue;
	let publishTitleValue;
	let publishDescriptionValue;
	let publishUsernameValue;
	let publishOtpValue;
	let publishIsOtpSentValue;

	// =========================================================================
	// Menu store values
	// =========================================================================

	let menuItemsValue;
	let menuActiveIndexValue;
	let menuLinkTitleValue;
	let menuLinkUrlValue;

	// =========================================================================
	// SEO store values
	// =========================================================================

	let seoOgIsActiveValue;
	let seoFaviconValue;
	let seoOgTitleValue;
	let seoOgDescriptionValue;
	let seoOgImageValue;
	let seoOgTypeValue;
	let seoOgTwitterCardValue;
	let seoOgTwitterTitleValue;
	let seoOgTwitterDescriptionValue;
	let seoOgTwitterImageValue;
	let seoOgTwitterSiteValue;
	let seoOgTwitterCreatorValue;

	// =========================================================================
	// Oembed store values
	// =========================================================================

	let oembedIdValue;
	let oembedUrlValue;
	let oembedDataValue;
	let oembedAllValue;

	// =========================================================================
	// jsonld store values
	// =========================================================================

	let jsonldTypeVal;
	let jsonldArticleTypeVal;
	let jsonldNameVal;
	let jsonldTitleVal;
	let jsonldDescriptionVal;
	let jsonldKeywordsVal;
	let jsonldImageVal;
	let jsonldUrlVal;
	let jsonldPageVal;
	let jsonldAuthorNameVal;
	let jsonldAuthorUrlVal;
	let jsonldPublisherNameVal;
	let jsonldPublisherUrlVal;
	let jsonldPerformerNameVal;
	let jsonldPerformerUrlVal;
	let jsonldOrganizerNameVal;
	let jsonldOrganizerUrlVal;
	let jsonldJobTitleVal;
	let jsonldJobCompanyVal;
	let jsonldEmailVal;
	let jsonldTelephoneVal;
	let jsonldTwitterVal;
	let jsonldFacebookVal;
	let jsonldLinkedinVal;
	let jsonldInstagramVal;
	let jsonldYouTubeVal;
	let jsonldGitHubVal;
	let jsonldLatitudeVal;
	let jsonldLongitudeVal;
	let jsonldCurrencyValueVal;
	let jsonldCurrencyVal;
	let jsonldBusinessFunctionVal;
	let jsonldAcceptedPaymentMethodsVal;
	let jsonldIngredientVal;
	let jsonldRecipeYieldVal;
	let jsonldRecipeCategoryVal;
	let jsonldRecipeCuisineVal;
	let jsonldInstructionsVal;
	let jsonldStartTimeVal;
	let jsonldEndTimeVal;
	let jsonldAddressStreetVal;
	let jsonldAddressLocalityVal;
	let jsonldAddressPostalCodeVal;
	let jsonldAddressRegionVal;
	let jsonldAddressCountryVal;
	let jsonldBrandNameVal;
	let jsonldSkuVal;
	let jsonldReviewNameVal;
	let jsonldReviewBodyVal;
	let jsonldReviewAuthorVal;
	let jsonldReviewPublisherVal;
	let jsonldReviewRatingValueVal;
	let jsonldReviewBestRatingVal;
	let jsonldAggregateRatingValueVal;
	let jsonldAggregateRatingBestRatingVal;
	let jsonldAggregateRatingCountVal;

	// =========================================================================
	// App core
	// =========================================================================

	/**
	 * @summary
	 * Get draft key
	 * @returns {Mixed} object | undefined
	 */
	const getDraftKey = async () => {
		subdomain = await getActiveSubdomain();
		if (subdomain) {
			return subdomain;
		}
		return location.host;
	};

	/**
	 * @summary
	 * Get draft from local storage
	 * @returns {Mixed} object | undefined
	 */
	const getDraft = async () => {
		const key = await getDraftKey();
		try {
			return await localStorage.getItem(key);
		} catch (error) {
			console.log('getDraft - error fetching draft - ', error);
		}
	};

	/**
	 * @summary
	 * Get draft from local storage
	 * @returns {Mixed} object | undefined
	 */
	const saveDraft = async () => {
		if (isAutosaveDisabled) {
			return;
		}
		const payload = getPublishPayload();
		// console.log({ payload });
		updatePublishPayload(payload);
		localStorage.setItem(await getDraftKey(), payload);
	};

	/**
	 * @summary
	 * Load the current subdomain
	 *
	 * @returns {string|undefined}
	 */
	const loadSubdomain = async (domain, subdomain, otpId, userId, otp) => {
		let response;
		let data;

		if (domain && otpId && userId && otp) {
			try {
				response = await axios.post('/api/subdomain', { domain, subdomain, otpId, userId, otp });
				data = response.data;
			} catch (err) {
				console.log('Error loading subdomain', err);
				// return;
			}
		}

		const draft = await getDraft();
		if (draft) {
			// if drfat was saved atleast 10 seconds after publish then use draft
			if (!data || draft.timestamp >= data.timestamp + 10000) {
				data = draft;
			}
		}

		// no draft found and no site data found
		if (!data) {
			return;
		}

		// restore editor data
		data.editorDataValue !== undefined && updateEditorData(data.editorDataValue);

		// restore image store
		data.bodyBgTypeValue !== undefined && updateBodyBgType(data.bodyBgTypeValue);
		data.bodyBgColorValue !== undefined && updateBodyBgColor(data.bodyBgColorValue);
		data.bodyBgGradientCodeValue !== undefined && updateBodyBgGradientCode(data.bodyBgGradientCodeValue);
		data.bodyBgGradientAngleValue !== undefined && updateBodyBgGradientAngle(data.bodyBgGradientAngleValue);
		data.bodyBgGradient1Value !== undefined && updateBodyBgGradient1(data.bodyBgGradient1Value);
		data.bodyBgGradient2Value !== undefined && updateBodyBgGradient2(data.bodyBgGradient2Value);
		data.bodyBgSizeIsActiveValue !== undefined && updateBodyBgSizeIsActive(data.bodyBgSizeIsActiveValue);
		data.bodyBgWidthValue !== undefined && updateBodyBgWidth(data.bodyBgWidthValue);
		data.bodyBgHeightValue !== undefined && updateBodyBgHeight(data.bodyBgHeightValue);
		data.bodyBgPositionValue !== undefined && updateBodyBgPosition(data.bodyBgPositionValue);
		data.bodyBgSizeValue !== undefined && updateBodyBgSize(data.bodyBgSizeValue);
		data.bodyBgRepeatValue !== undefined && updateBodyBgRepeat(data.bodyBgRepeatValue);
		data.bodyBgPatternValue !== undefined && updateBodyBgPattern(data.bodyBgPatternValue);
		data.bodyTextRadiusValue !== undefined && updateBodyTextRadius(data.bodyTextRadiusValue);
		data.bodyTextColorValue !== undefined && updateBodyTextColor(data.bodyTextColorValue);
		data.bodyShadowXValue !== undefined && updateBodyShadowX(data.bodyShadowXValue);
		data.bodyShadowYValue !== undefined && updateBodyShadowY(data.bodyShadowYValue);
		data.bodyShadowBlurValue !== undefined && updateBodyShadowBlur(data.bodyShadowBlurValue);
		data.bodyShadowSpreadValue !== undefined && updateBodyShadowSpread(data.bodyShadowSpreadValue);
		data.bodyShadowColorValue !== undefined && updateBodyShadowColor(data.bodyShadowColorValue);
		data.bodyLayoutWidthValue !== undefined && updateBodyLayoutWidth(data.bodyLayoutWidthValue);

		// restore header store
		data.headerBgTypeValue !== undefined && updateHeaderBgType(data.headerBgTypeValue);
		data.headerBgColorValue !== undefined && updateHeaderBgColor(data.headerBgColorValue);
		data.headerBgGradientCodeValue !== undefined && updateHeaderBgGradientCode(data.headerBgGradientCodeValue);
		data.headerBgGradientAngleValue !== undefined && updateHeaderBgGradientAngle(data.headerBgGradientAngleValue);
		data.headerBgGradient1Value !== undefined && updateHeaderBgGradient1(data.headerBgGradient1Value);
		data.headerBgGradient2Value !== undefined && updateHeaderBgGradient2(data.headerBgGradient2Value);
		data.headerBgWidthValue !== undefined && updateHeaderBgWidth(data.headerBgWidthValue);
		data.headerBgHeightValue !== undefined && updateHeaderBgHeight(data.headerBgHeightValue);
		data.headerBgPositionValue !== undefined && updateHeaderBgPosition(data.headerBgPositionValue);
		data.headerBgRepeatValue !== undefined && updateHeaderBgRepeat(data.headerBgRepeatValue);
		data.headerBgSizeValue !== undefined && updateHeaderBgSize(data.headerBgSizeValue);
		data.headerBgPatternValue !== undefined && updateHeaderBgPattern(data.headerBgPatternValue);

		// restore logo store
		data.headerLogoStartValue !== undefined && updateHeaderLogoStart(data.headerLogoStartValue);
		data.headerLogoCenterValue !== undefined && updateHeaderLogoCenter(data.headerLogoCenterValue);
		data.headerLogoEndValue !== undefined && updateHeaderLogoEnd(data.headerLogoEndValue);
		data.headerLogoStartAltValue !== undefined && updateHeaderLogoStartAlt(data.headerLogoStartAltValue);
		data.headerLogoStartTitleValue !== undefined && updateHeaderLogoStartTitle(data.headerLogoStartTitleValue);
		data.headerLogoCenterAltValue !== undefined && updateHeaderLogoCenterAlt(data.headerLogoCenterAltValue);
		data.headerLogoCenterTitleValue !== undefined && updateHeaderLogoCenterTitle(data.headerLogoCenterTitleValue);
		data.headerLogoEndAltValue !== undefined && updateHeaderLogoEndAlt(data.headerLogoEndAltValue);
		data.headerLogoEndTitleValue !== undefined && updateHeaderLogoEndTitle(data.headerLogoEndTitleValue);

		// restore stripe store
		data.stripeAllValue !== undefined && updateStripeAll(data.stripeAllValue);
		data.stripeIdValue !== undefined && updateStripeId(data.stripeIdValue);
		data.stripeSecretKeyValue !== undefined && updateStripeSecretKey(data.stripeSecretKeyValue);
		data.stripePriceIdValue !== undefined && updateStripePriceId(data.stripePriceIdValue);
		data.stripeIsIDealEnabledValue !== undefined && updateStripeIsIDealEnabled(data.stripeIsIDealEnabledValue);
		data.stripeTitleValue !== undefined && updateStripeTitle(data.stripeTitleValue);
		data.stripeUrlValue !== undefined && updateStripeUrl(data.stripeUrlValue);
		data.stripeRadiusValue !== undefined && updateStripeRadius(data.stripeRadiusValue);
		data.stripeBgColorValue !== undefined && updateStripeBgColor(data.stripeBgColorValue);
		data.stripeFgColorValue !== undefined && updateStripeFgColor(data.stripeFgColorValue);

		// restore image store
		data.imageAllValue !== undefined && updateImageAll(data.imageAllValue);
		data.imageIdValue !== undefined && updateImageId(data.imageIdValue);
		data.imageAltValue !== undefined && updateImageAlt(data.imageAltValue);
		data.imageTitleValue !== undefined && updateImageTitle(data.imageTitleValue);
		data.imageUploadValue !== undefined && updateImageUpload(data.imageUploadValue);

		// publish store
		data.publishDomainValue !== undefined && updatePublishDomain(data.publishDomainValue);
		data.publishSubdomainValue !== undefined && updatePublishSubdomain(data.publishSubdomainValue);
		data.publishTitleValue !== undefined && updatePublishTitle(data.publishTitleValue);
		data.publishDescriptionValue !== undefined && updatePublishDescription(data.publishDescriptionValue);

		// seo store
		data.seoOgIsActiveValue !== undefined && updateSeoOgIsActive(data.seoOgIsActiveValue);
		data.seoFaviconValue !== undefined && updateSeoFavicon(data.seoFaviconValue);
		data.seoOgTitleValue !== undefined && updateSeoOgTitle(data.seoOgTitleValue);
		data.seoOgDescriptionValue !== undefined && updateSeoOgDescription(data.seoOgDescriptionValue);
		data.seoOgImageValue !== undefined && updateSeoOgImage(data.seoOgImageValue);
		data.seoOgTypeValue !== undefined && updateSeoOgType(data.seoOgTypeValue);
		data.seoOgTwitterCardValue !== undefined && updateSeoOgTwitterCard(data.seoOgTwitterCardValue);
		data.seoOgTwitterTitleValue !== undefined && updateSeoOgTwitterTitle(data.seoOgTwitterTitleValue);
		data.seoOgTwitterDescriptionValue !== undefined && updateSeoOgTwitterDescription(data.seoOgTwitterDescriptionValue);
		data.seoOgTwitterImageValue !== undefined && updateSeoOgTwitterImage(data.seoOgTwitterImageValue);
		data.seoOgTwitterSiteValue !== undefined && updateSeoOgTwitterSite(data.seoOgTwitterSiteValue);
		data.seoOgTwitterCreatorValue !== undefined && updateSeoOgTwitterCreator(data.seoOgTwitterCreatorValue);

		// oembed store
		data.oembedIdValue !== undefined && updateOembedId(data.oembedIdValue);
		data.oembedUrlValue !== undefined && updateOembedUrl(data.oembedUrlValue);
		data.oembedDataValue !== undefined && updateOembedData(data.oembedDataValue);
		data.oembedAllValue !== undefined && updateOembedAll(data.oembedAllValue);

		// jsonld store
		data.jsonldTypeVal !== undefined && updateJsonldType(data.jsonldTypeVal);
		data.jsonldArticleTypeVal !== undefined && updateJsonldArticleType(data.jsonldArticleTypeVal);
		data.jsonldNameVal !== undefined && updateJsonldName(data.jsonldNameVal);
		data.jsonldTitleVal !== undefined && updateJsonldTitle(data.jsonldTitleVal);
		data.jsonldDescriptionVal !== undefined && updateJsonldDescription(data.jsonldDescriptionVal);
		data.jsonldKeywordsVal !== undefined && updateJsonldKeywords(data.jsonldKeywordsVal);
		data.jsonldImageVal !== undefined && updateJsonldImage(data.jsonldImageVal);
		data.jsonldUrlVal !== undefined && updateJsonldUrl(data.jsonldUrlVal);
		data.jsonldPageVal !== undefined && updateJsonldPage(data.jsonldPageVal);
		data.jsonldAuthorNameVal !== undefined && updateJsonldAuthorName(data.jsonldAuthorNameVal);
		data.jsonldAuthorUrlVal !== undefined && updateJsonldAuthorUrl(data.jsonldAuthorUrlVal);
		data.jsonldPublisherNameVal !== undefined && updateJsonldPublisherName(data.jsonldPublisherNameVal);
		data.jsonldPublisherUrlVal !== undefined && updateJsonldPublisherUrl(data.jsonldPublisherUrlVal);
		data.jsonldPerformerNameVal !== undefined && updateJsonldPerformerName(data.jsonldPerformerNameVal);
		data.jsonldPerformerUrlVal !== undefined && updateJsonldPerformerUrl(data.jsonldPerformerUrlVal);
		data.jsonldOrganizerNameVal !== undefined && updateJsonldOrganizerName(data.jsonldOrganizerNameVal);
		data.jsonldOrganizerUrlVal !== undefined && updateJsonldOrganizerUrl(data.jsonldOrganizerUrlVal);
		data.jsonldJobTitleVal !== undefined && updateJsonldJobTitle(data.jsonldJobTitleVal);
		data.jsonldJobCompanyVal !== undefined && updateJsonldJobCompany(data.jsonldJobCompanyVal);
		data.jsonldEmailVal !== undefined && updateJsonldEmail(data.jsonldEmailVal);
		data.jsonldTelephoneVal !== undefined && updateJsonldTelephone(data.jsonldTelephoneVal);
		data.jsonldTwitterVal !== undefined && updateJsonldTwitter(data.jsonldTwitterVal);
		data.jsonldFacebookVal !== undefined && updateJsonldFacebook(data.jsonldFacebookVal);
		data.jsonldLinkedinVal !== undefined && updateJsonldLinkedin(data.jsonldLinkedinVal);
		data.jsonldInstagramVal !== undefined && updateJsonldInstagram(data.jsonldInstagramVal);
		data.jsonldYouTubeVal !== undefined && updateJsonldYouTube(data.jsonldYouTubeVal);
		data.jsonldGitHubVal !== undefined && updateJsonldGitHub(data.jsonldGitHubVal);
		data.jsonldLatitudeVal !== undefined && updateJsonldLatitude(data.jsonldLatitudeVal);
		data.jsonldLongitudeVal !== undefined && updateJsonldLongitude(data.jsonldLongitudeVal);
		data.jsonldCurrencyValueVal !== undefined && updateJsonldCurrencyValue(data.jsonldCurrencyValueVal);
		data.jsonldCurrencyVal !== undefined && updateJsonldCurrency(data.jsonldCurrencyVal);
		data.jsonldBusinessFunctionVal !== undefined && updateJsonldBusinessFunction(data.jsonldBusinessFunctionVal);
		data.jsonldAcceptedPaymentMethodsVal !== undefined && updateJsonldAcceptedPaymentMethods(data.jsonldAcceptedPaymentMethodsVal);
		data.jsonldIngredientVal !== undefined && updateJsonldIngredient(data.jsonldIngredientVal);
		data.jsonldRecipeYieldVal !== undefined && updateJsonldRecipeYield(data.jsonldRecipeYieldVal);
		data.jsonldRecipeCategoryVal !== undefined && updateJsonldRecipeCategory(data.jsonldRecipeCategoryVal);
		data.jsonldRecipeCuisineVal !== undefined && updateJsonldRecipeCuisine(data.jsonldRecipeCuisineVal);
		data.jsonldInstructionsVal !== undefined && updateJsonldInstructions(data.jsonldInstructionsVal);
		data.jsonldStartTimeVal !== undefined && updateJsonldStartTime(data.jsonldStartTimeVal);
		data.jsonldEndTimeVal !== undefined && updateJsonldEndTime(data.jsonldEndTimeVal);
		data.jsonldAddressStreetVal !== undefined && updateJsonldAddressStreet(data.jsonldAddressStreetVal);
		data.jsonldAddressLocalityVal !== undefined && updateJsonldAddressLocality(data.jsonldAddressLocalityVal);
		data.jsonldAddressPostalCodeVal !== undefined && updateJsonldAddressPostalCode(data.jsonldAddressPostalCodeVal);
		data.jsonldAddressRegionVal !== undefined && updateJsonldAddressRegion(data.jsonldAddressRegionVal);
		data.jsonldAddressCountryVal !== undefined && updateJsonldAddressCountry(data.jsonldAddressCountryVal);
		data.jsonldBrandNameVal !== undefined && updateJsonldBrandName(data.jsonldBrandNameVal);
		data.jsonldSkuVal !== undefined && updateJsonldSku(data.jsonldSkuVal);
		data.jsonldReviewNameVal !== undefined && updateJsonldReviewName(data.jsonldReviewNameVal);
		data.jsonldReviewBodyVal !== undefined && updateJsonldReviewBody(data.jsonldReviewBodyVal);
		data.jsonldReviewAuthorVal !== undefined && updateJsonldReviewAuthor(data.jsonldReviewAuthorVal);
		data.jsonldReviewPublisherVal !== undefined && updateJsonldReviewPublisher(data.jsonldReviewPublisherVal);
		data.jsonldReviewRatingValueVal !== undefined && updateJsonldReviewRatingValue(data.jsonldReviewRatingValueVal);
		data.jsonldReviewBestRatingVal !== undefined && updateJsonldReviewBestRating(data.jsonldReviewBestRatingVal);
		data.jsonldAggregateRatingValueVal !== undefined && updateJsonldAggregateRatingValue(data.jsonldAggregateRatingValueVal);
		data.jsonldAggregateRatingBestRatingVal !== undefined && updateJsonldAggregateRatingBestRating(data.jsonldAggregateRatingBestRatingVal);
		data.jsonldAggregateRatingCountVal !== undefined && updateJsonldAggregateRatingCount(data.jsonldAggregateRatingCountVal);

		// restore the following publish data from cache only as they are not retured via api
		draft?.publishUsernameValue !== undefined && updatePublishUsername(draft.publishUsernameValue);
		draft?.publishOtpValue !== undefined && updatePublishOtp(draft.publishOtpValue);
		draft?.publishIsOtpSentValue !== undefined && updatePublishIsOtpSent(draft.publishIsOtpSentValue);

		// updates to be run after setting variables
		const payload = getPublishPayload();
		updatePublishPayload(payload);
	};

	/**
	 * @description
	 * Disable auto save
	 * Then load subdomain and draft
	 * Then decide whether to use subdomain or draft
	 * Finally enable auto save
	 */
	const initSubdomain = async () => {
		isAutosaveDisabled = true;
		isLoading = true;
		domain = await getActiveDomain();
		subdomain = await getActiveSubdomain();
		const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
		await loadSubdomain(domain, subdomain, otpId, userId, otp);
		isLoading = false;
		isAutosaveDisabled = false;
	};

	/**
	 * @summary
	 * Init page - home / login / pricing / how-it-works / about
	 * Load subdomain
	 */
	onMount(async () => {
		initPlan();
		await initSubscription();
		if (activePage !== 'editor') {
			isLoading = false;
			return;
		}
		initSubdomain();
	});

	const initSubscription = async () => {
		const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
		try {
			const response = await axios.post('/api/subscription', { otpId, username: userId, otp, method: 'head' });
			const subscription = response.data;
			updateAuthSubscription(subscription);
		} catch (err) {
			console.log('Error fetching subscription - ', err);
			resetAuthSubscription();
		}
	};

	const initPlan = async () => {
		try {
			const response = await axios.post('/api/plans', {});
			const plan = response.data;
			updateAuthPlan(plan);
		} catch (err) {
			console.log('Error fetching plan - ', err);
			resetAuthPlan();
		}
	};

	/**
	 * @summary
	 * Update draft whenever content changes
	 */
	const onContentUpdate = () => saveDraft();

	/**
	 * @summary
	 * Get payload object
	 */
	const getPublishPayload = () => ({
		subdomain: publishDomainValue,
		subSubdomain: publishSubdomainValue,
		title: publishTitleValue,
		description: publishDescriptionValue,
		isUpdate: publishIsUpdateValue,
		timestamp: +new Date(),
		username: publishUsernameValue,
		otp: publishOtpValue,

		// page html
		html: document.documentElement.outerHTML,

		// editor data,
		editorDataValue: editorInstanceValue?.getContent(),

		// image store
		bodyBgTypeValue,
		bodyBgColorValue,
		bodyBgGradientCodeValue,
		bodyBgGradientAngleValue,
		bodyBgGradient1Value,
		bodyBgGradient2Value,
		bodyBgWidthValue,
		bodyBgHeightValue,
		bodyBgPositionValue,
		bodyBgRepeatValue,
		bodyBgSizeValue,
		bodyBgSizeIsActiveValue,
		bodyBgPatternValue,
		bodyTextColorValue,
		bodyTextRadiusValue,
		bodyShadowXValue,
		bodyShadowYValue,
		bodyShadowBlurValue,
		bodyShadowSpreadValue,
		bodyShadowColorValue,
		bodyLayoutWidthValue,

		// header store
		headerBgTypeValue,
		headerBgColorValue,
		headerBgGradientCodeValue,
		headerBgGradientAngleValue,
		headerBgGradient1Value,
		headerBgGradient2Value,
		headerBgWidthValue,
		headerBgHeightValue,
		headerBgPositionValue,
		headerBgRepeatValue,
		headerBgSizeValue,
		headerBgPatternValue,

		// logo store
		headerLogoStartValue,
		headerLogoStartAltValue,
		headerLogoStartTitleValue,
		headerLogoCenterValue,
		headerLogoCenterAltValue,
		headerLogoCenterTitleValue,
		headerLogoEndValue,
		headerLogoEndAltValue,
		headerLogoEndTitleValue,

		// stripe
		stripeAllValue,
		stripeIdValue,
		stripeSecretKeyValue,
		stripePriceIdValue,
		stripeIsIDealEnabledValue,
		stripeTitleValue,
		stripeUrlValue,
		stripeBgColorValue,
		stripeFgColorValue,
		stripeRadiusValue,

		// image
		imageAllValue,
		imageIdValue,
		imageAltValue,
		imageTitleValue,
		imageUploadValue,

		// publish
		publishDomainValue,
		publishSubdomainValue,
		publishTitleValue,
		publishDescriptionValue,
		publishUsernameValue,
		publishOtpValue,
		publishIsOtpSentValue,

		// menu
		menuItemsValue,
		menuActiveIndexValue,
		menuLinkTitleValue,
		menuLinkUrlValue,

		// seo
		seoOgIsActiveValue,
		seoFaviconValue,
		seoOgTitleValue,
		seoOgDescriptionValue,
		seoOgImageValue,
		seoOgTypeValue,
		seoOgTwitterCardValue,
		seoOgTwitterTitleValue,
		seoOgTwitterDescriptionValue,
		seoOgTwitterImageValue,
		seoOgTwitterSiteValue,
		seoOgTwitterCreatorValue,

		// oembed
		oembedIdValue,
		oembedUrlValue,
		oembedDataValue,
		oembedAllValue,

		// jsonld
		jsonldTypeVal,
		jsonldArticleTypeVal,
		jsonldNameVal,
		jsonldTitleVal,
		jsonldDescriptionVal,
		jsonldKeywordsVal,
		jsonldImageVal,
		jsonldUrlVal,
		jsonldPageVal,
		jsonldAuthorNameVal,
		jsonldAuthorUrlVal,
		jsonldPublisherNameVal,
		jsonldPublisherUrlVal,
		jsonldPerformerNameVal,
		jsonldPerformerUrlVal,
		jsonldOrganizerNameVal,
		jsonldOrganizerUrlVal,
		jsonldJobTitleVal,
		jsonldJobCompanyVal,
		jsonldEmailVal,
		jsonldTelephoneVal,
		jsonldTwitterVal,
		jsonldFacebookVal,
		jsonldLinkedinVal,
		jsonldInstagramVal,
		jsonldYouTubeVal,
		jsonldGitHubVal,
		jsonldLatitudeVal,
		jsonldLongitudeVal,
		jsonldCurrencyValueVal,
		jsonldCurrencyVal,
		jsonldBusinessFunctionVal,
		jsonldAcceptedPaymentMethodsVal,
		jsonldIngredientVal,
		jsonldRecipeYieldVal,
		jsonldRecipeCategoryVal,
		jsonldRecipeCuisineVal,
		jsonldInstructionsVal,
		jsonldStartTimeVal,
		jsonldEndTimeVal,
		jsonldAddressStreetVal,
		jsonldAddressLocalityVal,
		jsonldAddressPostalCodeVal,
		jsonldAddressRegionVal,
		jsonldAddressCountryVal,
		jsonldBrandNameVal,
		jsonldSkuVal,
		jsonldReviewNameVal,
		jsonldReviewBodyVal,
		jsonldReviewAuthorVal,
		jsonldReviewPublisherVal,
		jsonldReviewRatingValueVal,
		jsonldReviewBestRatingVal,
		jsonldAggregateRatingValueVal,
		jsonldAggregateRatingBestRatingVal,
		jsonldAggregateRatingCountVal,
	});

	// =========================================================================
	// Toolbar subscriptions
	// =========================================================================

	isToolbarSaveAsActive.subscribe((value) => {
		isToolbarSaveAsActiveValue = value;
		saveDraft();
	});

	// =========================================================================
	// Body Bg subscriptions
	// =========================================================================

	bodyBgType.subscribe((value) => {
		bodyBgTypeValue = value;
		saveDraft();
	});

	bodyBgColor.subscribe((value) => {
		bodyBgColorValue = value;
		saveDraft();
	});

	bodyBgGradientCode.subscribe((value) => {
		bodyBgGradientCodeValue = value;
		saveDraft();
	});

	bodyBgGradientAngle.subscribe((value) => {
		bodyBgGradientAngleValue = value;
		saveDraft();
	});

	bodyBgGradient1.subscribe((value) => {
		bodyBgGradient1Value = value;
		saveDraft();
	});

	bodyBgGradient2.subscribe((value) => {
		bodyBgGradient2Value = value;
		saveDraft();
	});

	bodyBgWidth.subscribe((value) => {
		bodyBgWidthValue = value;
		saveDraft();
	});

	bodyBgHeight.subscribe((value) => {
		bodyBgHeightValue = value;
		saveDraft();
	});

	bodyBgPosition.subscribe((value) => {
		bodyBgPositionValue = value;
		saveDraft();
	});

	bodyBgRepeat.subscribe((value) => {
		bodyBgRepeatValue = value;
		saveDraft();
	});

	bodyBgSize.subscribe((value) => {
		bodyBgSizeValue = value;
		saveDraft();
	});

	bodyLayoutWidth.subscribe((value) => {
		bodyLayoutWidthValue = value;
		saveDraft();
	});

	bodyBgPattern.subscribe((value) => {
		bodyBgPatternValue = value;
		saveDraft();
	});

	bodyTextColor.subscribe((value) => {
		bodyTextColorValue = value;
		saveDraft();
	});

	bodyTextRadius.subscribe((value) => {
		bodyTextRadiusValue = value;
		saveDraft();
	});

	bodyShadowX.subscribe((value) => {
		bodyShadowXValue = value;
		saveDraft();
	});

	bodyShadowY.subscribe((value) => {
		bodyShadowYValue = value;
		saveDraft();
	});

	bodyShadowBlur.subscribe((value) => {
		bodyShadowBlurValue = value;
		saveDraft();
	});

	bodyShadowSpread.subscribe((value) => {
		bodyShadowSpreadValue = value;
		saveDraft();
	});

	bodyShadowColor.subscribe((value) => {
		bodyShadowColorValue = value;
		saveDraft();
	});

	// =========================================================================
	// Header Bg Subscriptions
	// =========================================================================

	headerBgType.subscribe((value) => {
		headerBgTypeValue = value;
		saveDraft();
	});

	headerBgColor.subscribe((value) => {
		headerBgColorValue = value;
		saveDraft();
	});

	headerBgGradientCode.subscribe((value) => {
		headerBgGradientCodeValue = value;
		saveDraft();
	});

	headerBgGradientAngle.subscribe((value) => {
		headerBgGradientAngleValue = value;
		saveDraft();
	});

	headerBgGradient1.subscribe((value) => {
		headerBgGradient1Value = value;
		saveDraft();
	});

	headerBgGradient2.subscribe((value) => {
		headerBgGradient2Value = value;
		saveDraft();
	});

	bodyBgSizeIsActive.subscribe((value) => {
		bodyBgSizeIsActiveValue = value;
	});

	headerBgWidth.subscribe((value) => {
		headerBgWidthValue = value;
		saveDraft();
	});

	headerBgHeight.subscribe((value) => {
		headerBgHeightValue = value;
		saveDraft();
	});

	headerBgPosition.subscribe((value) => {
		headerBgPositionValue = value;
		saveDraft();
	});

	headerBgRepeat.subscribe((value) => {
		headerBgRepeatValue = value;
		saveDraft();
	});

	headerBgSize.subscribe((value) => {
		headerBgSizeValue = value;
		saveDraft();
	});

	headerBgPattern.subscribe((value) => {
		headerBgPatternValue = value;
		saveDraft();
	});

	// =========================================================================
	// Logo subscriptions
	// =========================================================================

	headerLogoStart.subscribe((value) => {
		headerLogoStartValue = value;
		saveDraft();
	});

	headerLogoStartAlt.subscribe((value) => {
		headerLogoStartAltValue = value;
		saveDraft();
	});

	headerLogoStartTitle.subscribe((value) => {
		headerLogoStartTitleValue = value;
		saveDraft();
	});

	headerLogoCenter.subscribe((value) => {
		headerLogoCenterValue = value;
		saveDraft();
	});

	headerLogoCenterAlt.subscribe((value) => {
		headerLogoCenterAltValue = value;
		saveDraft();
	});

	headerLogoCenterTitle.subscribe((value) => {
		headerLogoCenterTitleValue = value;
		saveDraft();
	});

	headerLogoEnd.subscribe((value) => {
		headerLogoEndValue = value;
		saveDraft();
	});

	headerLogoEndAlt.subscribe((value) => {
		headerLogoEndAltValue = value;
		saveDraft();
	});

	headerLogoEndTitle.subscribe((value) => {
		headerLogoEndTitleValue = value;
		saveDraft();
	});

	// =========================================================================
	// Stripe subscriptions
	// =========================================================================

	stripeAll.subscribe((value) => {
		stripeAllValue = value;
		saveDraft();
	});

	stripeId.subscribe((value) => {
		stripeIdValue = value;
		saveDraft();
	});

	stripeSecretKey.subscribe((value) => {
		stripeSecretKeyValue = value;
		saveDraft();
	});

	stripePriceId.subscribe((value) => {
		stripePriceIdValue = value;
		saveDraft();
	});

	stripeIsIDealEnabled.subscribe((value) => {
		stripeIsIDealEnabledValue = value;
		saveDraft();
	});

	stripeTitle.subscribe((value) => {
		stripeTitleValue = value;
		saveDraft();
	});

	stripeUrl.subscribe((value) => {
		stripeUrlValue = value;
		saveDraft();
	});

	stripeBgColor.subscribe((value) => {
		stripeBgColorValue = value;
		saveDraft();
	});

	stripeFgColor.subscribe((value) => {
		stripeFgColorValue = value;
		saveDraft();
	});

	stripeRadius.subscribe((value) => {
		stripeRadiusValue = value;
		saveDraft();
	});

	// =========================================================================
	// Image subscriptions
	// =========================================================================

	imageAll.subscribe((value) => {
		imageAllValue = value;
		saveDraft();
	});

	imageId.subscribe((value) => {
		imageIdValue = value;
		saveDraft();
	});

	imageAlt.subscribe((value) => {
		imageAltValue = value;
		saveDraft();
	});

	imageTitle.subscribe((value) => {
		imageTitleValue = value;
		saveDraft();
	});

	imageUpload.subscribe((value) => {
		imageUploadValue = value;
		saveDraft();
	});

	// =========================================================================
	// Editor subscriptions
	// =========================================================================

	editorData.subscribe((value) => {
		editorDataValue = value;
		saveDraft();
	});

	editorInstance.subscribe((value) => {
		editorInstanceValue = value;
		saveDraft();
	});

	editorFocusedEle.subscribe((value) => {
		editorFocusedEleValue = value;
		// saveDraft()
	});

	// =========================================================================
	// Publish subscriptions
	// =========================================================================

	publishDomain.subscribe((value) => {
		publishDomainValue = value;
		saveDraft();
	});

	publishSubdomain.subscribe((value) => {
		publishSubdomainValue = value;
		saveDraft();
	});

	publishIsUpdate.subscribe((value) => {
		publishIsUpdateValue = value;
		saveDraft();
	});

	publishTitle.subscribe((value) => {
		publishTitleValue = value;
		saveDraft();
	});

	publishDescription.subscribe((value) => {
		publishDescriptionValue = value;
		saveDraft();
	});

	publishUsername.subscribe((value) => {
		publishUsernameValue = value;
		saveDraft();
	});

	publishOtp.subscribe((value) => {
		publishOtpValue = value;
		saveDraft();
	});

	publishIsOtpSent.subscribe((value) => {
		publishIsOtpSentValue = value;
		saveDraft();
	});

	// =========================================================================
	// Menu subscriptions
	// =========================================================================

	menuItems.subscribe((value) => {
		menuItemsValue = value;
		saveDraft();
	});

	menuActiveIndex.subscribe((value) => {
		menuActiveIndexValue = value;
		saveDraft();
	});

	menuLinkTitle.subscribe((value) => {
		menuLinkTitleValue = value;
		saveDraft();
	});

	menuLinkUrl.subscribe((value) => {
		menuLinkUrlValue = value;
		saveDraft();
	});

	// =========================================================================
	// SEO subscriptions
	// =========================================================================

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
		saveDraft();
	});

	seoFavicon.subscribe((value) => {
		seoFaviconValue = value;
		saveDraft();
	});

	seoOgTitle.subscribe((value) => {
		seoOgTitleValue = value;
		saveDraft();
	});

	seoOgDescription.subscribe((value) => {
		seoOgDescriptionValue = value;
		saveDraft();
	});

	seoOgImage.subscribe((value) => {
		seoOgImageValue = value;
		saveDraft();
	});

	seoOgType.subscribe((value) => {
		seoOgTypeValue = value;
		saveDraft();
	});

	seoOgTwitterCard.subscribe((value) => {
		seoOgTwitterCardValue = value;
		saveDraft();
	});

	seoOgTwitterTitle.subscribe((value) => {
		seoOgTwitterTitleValue = value;
		saveDraft();
	});

	seoOgTwitterDescription.subscribe((value) => {
		seoOgTwitterDescriptionValue = value;
		saveDraft();
	});

	seoOgTwitterImage.subscribe((value) => {
		seoOgTwitterImageValue = value;
		saveDraft();
	});

	seoOgTwitterSite.subscribe((value) => {
		seoOgTwitterSiteValue = value;
		saveDraft();
	});

	seoOgTwitterCreator.subscribe((value) => {
		seoOgTwitterCreatorValue = value;
		saveDraft();
	});

	// =========================================================================
	// Oembed subscriptions
	// =========================================================================

	oembedId.subscribe((value) => {
		oembedIdValue = value;
		saveDraft();
	});

	oembedUrl.subscribe((value) => {
		oembedUrlValue = value;
		saveDraft();
	});

	oembedData.subscribe((value) => {
		oembedDataValue = value;
		saveDraft();
	});

	oembedAll.subscribe((value) => {
		oembedAllValue = value;
		saveDraft();
	});

	jsonldType.subscribe((value) => {
		jsonldTypeVal = value;
		saveDraft();
	});

	jsonldArticleType.subscribe((value) => {
		jsonldArticleTypeVal = value;
		saveDraft();
	});

	jsonldName.subscribe((value) => {
		jsonldNameVal = value;
		saveDraft();
	});

	jsonldTitle.subscribe((value) => {
		jsonldTitleVal = value;
		saveDraft();
	});

	jsonldDescription.subscribe((value) => {
		jsonldDescriptionVal = value;
		saveDraft();
	});

	jsonldKeywords.subscribe((value) => {
		jsonldKeywordsVal = value;
		saveDraft();
	});

	jsonldImage.subscribe((value) => {
		jsonldImageVal = value;
		saveDraft();
	});

	jsonldUrl.subscribe((value) => {
		jsonldUrlVal = value;
		saveDraft();
	});

	jsonldPage.subscribe((value) => {
		jsonldPageVal = value;
		saveDraft();
	});

	jsonldAuthorName.subscribe((value) => {
		jsonldAuthorNameVal = value;
		saveDraft();
	});

	jsonldAuthorUrl.subscribe((value) => {
		jsonldAuthorUrlVal = value;
		saveDraft();
	});

	jsonldPublisherName.subscribe((value) => {
		jsonldPublisherNameVal = value;
		saveDraft();
	});

	jsonldPublisherUrl.subscribe((value) => {
		jsonldPublisherUrlVal = value;
		saveDraft();
	});

	jsonldPerformerName.subscribe((value) => {
		jsonldPerformerNameVal = value;
		saveDraft();
	});

	jsonldPerformerUrl.subscribe((value) => {
		jsonldPerformerUrlVal = value;
		saveDraft();
	});

	jsonldOrganizerName.subscribe((value) => {
		jsonldOrganizerNameVal = value;
		saveDraft();
	});

	jsonldOrganizerUrl.subscribe((value) => {
		jsonldOrganizerUrlVal = value;
		saveDraft();
	});

	jsonldJobTitle.subscribe((value) => {
		jsonldJobTitleVal = value;
		saveDraft();
	});

	jsonldJobCompany.subscribe((value) => {
		jsonldJobCompanyVal = value;
		saveDraft();
	});

	jsonldEmail.subscribe((value) => {
		jsonldEmailVal = value;
		saveDraft();
	});

	jsonldTelephone.subscribe((value) => {
		jsonldTelephoneVal = value;
		saveDraft();
	});

	jsonldTwitter.subscribe((value) => {
		jsonldTwitterVal = value;
		saveDraft();
	});

	jsonldFacebook.subscribe((value) => {
		jsonldFacebookVal = value;
		saveDraft();
	});

	jsonldLinkedin.subscribe((value) => {
		jsonldLinkedinVal = value;
		saveDraft();
	});

	jsonldInstagram.subscribe((value) => {
		jsonldInstagramVal = value;
		saveDraft();
	});

	jsonldYouTube.subscribe((value) => {
		jsonldYouTubeVal = value;
		saveDraft();
	});

	jsonldGitHub.subscribe((value) => {
		jsonldGitHubVal = value;
		saveDraft();
	});

	jsonldLatitude.subscribe((value) => {
		jsonldLatitudeVal = value;
		saveDraft();
	});

	jsonldLongitude.subscribe((value) => {
		jsonldLongitudeVal = value;
		saveDraft();
	});

	jsonldCurrencyValue.subscribe((value) => {
		jsonldCurrencyValueVal = value;
		saveDraft();
	});

	jsonldCurrency.subscribe((value) => {
		jsonldCurrencyVal = value;
		saveDraft();
	});

	jsonldBusinessFunction.subscribe((value) => {
		jsonldBusinessFunctionVal = value;
		saveDraft();
	});

	jsonldAcceptedPaymentMethods.subscribe((value) => {
		jsonldAcceptedPaymentMethodsVal = value;
		saveDraft();
	});

	jsonldIngredient.subscribe((value) => {
		jsonldIngredientVal = value;
		saveDraft();
	});

	jsonldRecipeYield.subscribe((value) => {
		jsonldRecipeYieldVal = value;
		saveDraft();
	});

	jsonldRecipeCategory.subscribe((value) => {
		jsonldRecipeCategoryVal = value;
		saveDraft();
	});

	jsonldRecipeCuisine.subscribe((value) => {
		jsonldRecipeCuisineVal = value;
		saveDraft();
	});

	jsonldInstructions.subscribe((value) => {
		jsonldInstructionsVal = value;
		saveDraft();
	});

	jsonldStartTime.subscribe((value) => {
		jsonldStartTimeVal = value;
		saveDraft();
	});

	jsonldEndTime.subscribe((value) => {
		jsonldEndTimeVal = value;
		saveDraft();
	});

	jsonldAddressStreet.subscribe((value) => {
		jsonldAddressStreetVal = value;
		saveDraft();
	});

	jsonldAddressLocality.subscribe((value) => {
		jsonldAddressLocalityVal = value;
		saveDraft();
	});

	jsonldAddressPostalCode.subscribe((value) => {
		jsonldAddressPostalCodeVal = value;
		saveDraft();
	});

	jsonldAddressRegion.subscribe((value) => {
		jsonldAddressRegionVal = value;
		saveDraft();
	});

	jsonldAddressCountry.subscribe((value) => {
		jsonldAddressCountryVal = value;
		saveDraft();
	});

	jsonldBrandName.subscribe((value) => {
		jsonldBrandNameVal = value;
		saveDraft();
	});

	jsonldSku.subscribe((value) => {
		jsonldSkuVal = value;
		saveDraft();
	});

	jsonldReviewName.subscribe((value) => {
		jsonldReviewNameVal = value;
		saveDraft();
	});

	jsonldReviewBody.subscribe((value) => {
		jsonldReviewBodyVal = value;
		saveDraft();
	});

	jsonldReviewAuthor.subscribe((value) => {
		jsonldReviewAuthorVal = value;
		saveDraft();
	});

	jsonldReviewPublisher.subscribe((value) => {
		jsonldReviewPublisherVal = value;
		saveDraft();
	});

	jsonldReviewRatingValue.subscribe((value) => {
		jsonldReviewRatingValueVal = value;
		saveDraft();
	});

	jsonldReviewBestRating.subscribe((value) => {
		jsonldReviewBestRatingVal = value;
		saveDraft();
	});

	jsonldAggregateRatingValue.subscribe((value) => {
		jsonldAggregateRatingValueVal = value;
		saveDraft();
	});

	jsonldAggregateRatingBestRating.subscribe((value) => {
		jsonldAggregateRatingBestRatingVal = value;
		saveDraft();
	});

	jsonldAggregateRatingCount.subscribe((value) => {
		jsonldAggregateRatingCountVal = value;
		saveDraft();
	});
</script>

<AutoLogin />

{#if !isLoading && activePage === 'home'}
	<Home />
{/if}

{#if activePage === 'editor'}
	<Toolbar />
	<PageHeader />
	<PageNav />
	<PageEditor />
	{#if !isLoading}
		<Editor />
		<Subscribe />
	{/if}
{/if}

{#if !isLoading && activePage === 'login'}
	<Login />
{/if}

{#if !isLoading && activePage === 'register'}
	<Register />
{/if}

{#if !isLoading && activePage === 'logout'}
	<Logout />
{/if}

{#if !isLoading && activePage === 'about'}
	<About />
{/if}

{#if !isLoading && activePage === 'how-it-works'}
	<HowItWorks />
{/if}

{#if !isLoading && activePage === 'pricing'}
	<Pricing />
{/if}

{#if !isLoading && activePage === 'profile'}
	<Profile />
{/if}

{#if !isLoading && activePage === 'uploads'}
	<Uploads />
{/if}

{#if !isLoading && activePage === 'checkout'}
	<Checkout />
{/if}

{#if !isLoading && activePage === 'download'}
	<Download />
{/if}

{#if !isLoading && activePage === 'payment-success'}
	<PaymentSuccess />
{/if}

{#if !isLoading && activePage === 'payment-error'}
	<PaymentError />
{/if}

{#if !isLoading && activePage === '404'}
	<Error404 />
{/if}

{#if isLoading}
	<LoaderFullscreen position="fixed" />
{/if}

<ExitFullScreen />
