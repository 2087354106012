// background type
export const headerBgTypeDefault = '';

// color code
export const headerBgColorDefault = 'hsla(180, 50, 50, 1)';

// gradient code
export const headerBgGradientCodeDefault = '';

// custom gradient
export const headerBgGradientAngleDefault = 180;
export const headerBgGradient1Default = 'hsla(180, 50, 50, 1)';
export const headerBgGradient2Default = 'hsla(180, 50, 50, 1)';

// background
export const headerBgSizeDefault = '';
export const headerBgWidthDefault = 50;
export const headerBgHeightDefault = 50;
export const headerBgPositionDefault = '';
export const headerBgRepeatDefault = '';

// background pattern / uploded image
export const headerBgBase64Default = null;
export const headerBgPatternDefault = {};
