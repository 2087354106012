<script>
	import { jsonldAddressPostalCode } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAddressPostalCode } from '../../stores/jsonld/UpdateJsonldAddressPostalCode';

	let jsonldAddressPostalCodeValue;

	jsonldAddressPostalCode.subscribe((value) => {
		jsonldAddressPostalCodeValue = value;
	});

	const onJsonldAddressPostalCodeChange = (event) => updateJsonldAddressPostalCode(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAddressPostalCode">Address Postal Code:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAddressPostalCode"
		value={jsonldAddressPostalCodeValue}
		on:input={onJsonldAddressPostalCodeChange}
	/>
</div>
