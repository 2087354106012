<script>
	import IconSettings from '../icons/IconSettings.svelte';
	import { updateToolbarSeo } from '../stores/toolbar/UpdateToolbarSeo';

	const onClick = () => {
		updateToolbarSeo(true);
	};
</script>

<button class="proteus-toolbar-button is-settings" title="Page Settings" on:click={onClick}>
	<IconSettings />
</button>
