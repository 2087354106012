<script>
	import LoaderFullscreen from './LoaderFullscreen.svelte';
	import Message from './Message.svelte';

	export let onSubmit;

	let refs = {
		0: undefined,
		1: undefined,
		2: undefined,
		3: undefined,
		4: undefined,
		5: undefined,
	};
	let activeIndex = 0;
	let isBusy = false;
	let code = {
		0: '',
		1: '',
		2: '',
		3: '',
		4: '',
		5: '',
	};
	let value = '';
	let complete = false;

	const onFocus = (newIndex) => activeIndex = newIndex;

	const onKeyDown = (e, index) => {
		e.preventDefault();
		console.log(e);
		if (e.keyCode > 47 && e.keyCode < 58) {
			code[index] = e.key;
			if (index < 5) {
				refs[index + 1].focus();
			}
		}
		if (e.key === 'Backspace') {
			code[index] = '';
			if (index !== 0) {
				refs[index - 1].focus();
			}
		}
		if (e.key === 'Tab' && !e.shiftKey && !e.ctrlKey) {
			if (index < 5) {
				refs[index + 1].focus();
			}
		}
		if (e.key === 'Tab' && e.shiftKey) {
			if (index !== 0) {
				refs[index - 1].focus();
			}
		}
		value = Object.values(code).join('');
		if (value.trim().length === 6 && index === 5 && !e.shiftKey && !e.ctrlKey) {
			if (e.key === 'Tab' || e.key === 'Enter' || (e.keyCode > 47 && e.keyCode < 58)) {
				onSubmit(value);
			}
		}
	};

	const correctCode = '123456';
	$: success = complete && value === correctCode;
	$: error = complete && !success;
</script>

<div class="proteus-otp-group">
	<input class="proteus-otp-input" type="tel" bind:this={refs[0]} value={code[0]} maxlength={1} on:focus={() => onFocus(0)} on:keydown={(e) => onKeyDown(e, 0)} />
	<input class="proteus-otp-input" type="tel" bind:this={refs[1]} value={code[1]} maxlength={1} on:focus={() => onFocus(1)} on:keydown={(e) => onKeyDown(e, 1)} />
	<input class="proteus-otp-input" type="tel" bind:this={refs[2]} value={code[2]} maxlength={1} on:focus={() => onFocus(2)} on:keydown={(e) => onKeyDown(e, 2)} />
	<input class="proteus-otp-input" type="tel" bind:this={refs[3]} value={code[3]} maxlength={1} on:focus={() => onFocus(3)} on:keydown={(e) => onKeyDown(e, 3)} />
	<input class="proteus-otp-input" type="tel" bind:this={refs[4]} value={code[4]} maxlength={1} on:focus={() => onFocus(4)} on:keydown={(e) => onKeyDown(e, 4)} />
	<input class="proteus-otp-input" type="tel" bind:this={refs[5]} value={code[5]} maxlength={1} on:focus={() => onFocus(5)} on:keydown={(e) => onKeyDown(e, 5)} />
</div>
