<script>
	import { jsonldLatitude } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldLatitude } from '../../stores/jsonld/UpdateJsonldLatitude';

	let jsonldLatitudeValue;

	jsonldLatitude.subscribe((value) => {
		jsonldLatitudeValue = value;
	});

	const onJsonldLatitudeChange = (event) => updateJsonldLatitude(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldLatitude">Latitude:</label>
	<input class="proteus-input" type="text" name="jsonldLatitude" value={jsonldLatitudeValue} on:input={onJsonldLatitudeChange} />
</div>
