import { writable } from 'svelte/store';
import {
	stripeSecretKeyDefault,
	stripePriceIdDefault,
	stripeIsIDealEnabledDefault,
	stripeAllDefault,
	stripeIdDefault,
	stripeTitleDefault,
	stripeUrlDefault,
	stripeRadiusDefault,
	stripeBgColorDefault,
	stripeFgColorDefault,
} from './StripeStoreDefaults';

// all buttons
export const stripeAll = writable(stripeAllDefault);

// button id
export const stripeId = writable(stripeIdDefault);

// secret key
export const stripeSecretKey = writable(stripeSecretKeyDefault);

// price id
export const stripePriceId = writable(stripePriceIdDefault);

// price title
export const stripeTitle = writable(stripeTitleDefault);

// price url
export const stripeUrl = writable(stripeUrlDefault);

// is iDeal enabled
export const stripeIsIDealEnabled = writable(stripeIsIDealEnabledDefault);

// border radius
export const stripeRadius = writable(stripeRadiusDefault);

// background color code
export const stripeBgColor = writable(stripeBgColorDefault);

// foreround color code
export const stripeFgColor = writable(stripeFgColorDefault);
