<script>
	import { jsonldReviewPublisher } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldReviewPublisher } from '../../stores/jsonld/UpdateJsonldReviewPublisher';

	let jsonldReviewPublisherValue;

	jsonldReviewPublisher.subscribe((value) => {
		jsonldReviewPublisherValue = value;
	});

	const onJsonldReviewPublisherChange = (event) => updateJsonldReviewPublisher(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldReviewPublisher">Review Publisher:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldReviewPublisher"
		value={jsonldReviewPublisherValue}
		on:input={onJsonldReviewPublisherChange}
	/>
</div>
