<script>
	import { stripeRadius } from '../../stores/stripe/StripeStore';
	import { updateStripeRadius } from '../../stores/stripe/UpdateStripeRadius';
	import { resetStripeRadius } from '../../stores/stripe/ResetStripeRadius';

	let stripeRadiusValue;

	stripeRadius.subscribe((value) => {
		stripeRadiusValue = value;
	});

	const onStripeRadiusChange = (event) => updateStripeRadius(event.target.value);

	const onStripeRadiusReset = () => resetStripeRadius();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Border Radius: {stripeRadiusValue}px
		<span class="proteus-reset" on:click={onStripeRadiusReset}>Reset</span>
	</label>
	<input
		type="range"
		min={0}
		step={1}
		max={100}
		name="angle"
		value={stripeRadiusValue}
		on:input={onStripeRadiusChange}
	/>
</div>
