<script>
	import { jsonldName } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldName } from '../../stores/jsonld/UpdateJsonldName';

	let jsonldNameValue;

	jsonldName.subscribe((value) => {
		jsonldNameValue = value;
	});

	const onJsonldNameChange = (event) => updateJsonldName(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldName">Name:</label>
	<input class="proteus-input" type="text" name="jsonldName" value={jsonldNameValue} on:input={onJsonldNameChange} />
</div>
