import { writable } from 'svelte/store';
import {
	oembedIsBusyDefault,
	oembedIdDefault,
	oembedUrlDefault,
	oembedDataDefault,
	oembedAllDefault,
} from './oembedStoreDefaults';

export const oembedIsBusy = writable(oembedIsBusyDefault);

export const oembedId = writable(oembedIdDefault);

export const oembedUrl = writable(oembedUrlDefault);

export const oembedData = writable(oembedDataDefault);

export const oembedAll = writable(oembedAllDefault);
