<script>
	import classNames from 'classnames';

	import { isToolbarSaveActive, isToolbarSaveAsActive } from '../../stores/toolbar/ToolbarStore';
	import { publishIsBusy, publishIsOtpSent, publishUrl } from '../../stores/publish/PublishStore';

	import ToolbarSaveDomain from './ToolbarSaveDomain.svelte';
	import ToolbarSaveTitle from './ToolbarSaveTitle.svelte';
	import ToolbarSaveDescription from './ToolbarSaveDescription.svelte';
	import ToolbarSaveError from './ToolbarSaveError.svelte';
	import ToolbarSaveSubmit from './ToolbarSaveSubmit.svelte';
	import ToolbarSaveSuccess from './ToolbarSaveSuccess.svelte';
	import LoaderFullscreen from '../../LoaderFullscreen.svelte';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';

	let isToolbarSaveActiveValue;
	let isToolbarSaveAsActiveValue;
	let publishIsBusyValue;
	let publishIsOtpSentValue;
	let publishUrlValue;

	isToolbarSaveActive.subscribe((value) => {
		isToolbarSaveActiveValue = value;
	});

	isToolbarSaveAsActive.subscribe((value) => {
		isToolbarSaveAsActiveValue = value;
	});

	publishIsBusy.subscribe((value) => {
		publishIsBusyValue = value;
	});

	publishIsOtpSent.subscribe((value) => {
		publishIsOtpSentValue = value;
	});

	publishUrl.subscribe((value) => {
		publishUrlValue = value;
	});
</script>

<div class={classNames('proteus-modal', { 'is-active': isToolbarSaveActiveValue || isToolbarSaveAsActiveValue })}>
	<ToolbarOverlay />
	<div class="proteus-modal-dialog">
		<ToolbarSaveDomain />
		<ToolbarSaveTitle />
		<ToolbarSaveDescription />
		<ToolbarSaveError />
		<div class="proteus-control proteus-group proteus-toolbar-dropdown-footer">
			<ToolbarSaveSubmit />
		</div>
		{#if publishIsBusyValue}
			<LoaderFullscreen />
		{/if}
	</div>
</div>

{#if publishUrlValue}
	<ToolbarSaveSuccess />
{/if}