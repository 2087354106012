<script>
	import IconRemove from '../../icons/IconRemove.svelte';
	import { getIkResponseData, getImageAsBase64, getSelectedImage, uploadToImageKit } from '../../utils';

	import { headerBgBase64, headerBgPattern } from '../../stores/header-bg/HeaderBgStore';
	import { updateHeaderBgBase64 } from '../../stores/header-bg/UpdateHeaderBgBase64';
	import { updateHeaderBgPattern } from '../../stores/header-bg/UpdateHeaderBgPattern';

	let headerBgBase64Value;
	let headerBgPatternValue;
	let file;
	let isBusy = false;

	headerBgBase64.subscribe((value) => {
		headerBgBase64Value = value;
	});

	headerBgPattern.subscribe((value) => {
		headerBgPatternValue = value;
	});

	const onHeaderBgPattternClear = () => {
		updateHeaderBgBase64();
		updateHeaderBgPattern();
	};

	const onImageChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}
		try {
			const base64 = await getImageAsBase64(file);
			updateHeaderBgBase64(base64);
			const ikResponse = await uploadToImageKit(file, file.name);
			updateHeaderBgPattern(getIkResponseData(file, ikResponse));
			updateHeaderBgBase64();
		} catch (err) {
			console.log('Header bg pattern change error - ', err);
			return;
		}
	};
</script>

<div class="proteus-control proteus-group">
	<label for="header-bg-file" class="proteus-file-zone">
		<span class="proteus-file-zone-name">
			<!-- {file ? file.name : 'Select Background'} -->
			{file || headerBgPatternValue?.upload?.url || headerBgBase64Value
				? 'Change Background'
				: 'Select Background'}
		</span>
		<input
			type="file"
			on:input={onImageChange}
			name="header-bg"
			id="header-bg-file"
			disabled={isBusy}
			class="proteus-file-zone-file"
		/>
		{#if file || headerBgPatternValue?.upload?.url || headerBgBase64Value}
			<span on:click|preventDefault={onHeaderBgPattternClear} class="proteus-file-zone-remove">
				<IconRemove />
			</span>
		{/if}
	</label>
</div>
