<script>
	import localStorage from 'localforage';
	import { publishDomain } from '../stores/publish/PublishStore';

	let publishDomainValue;
	let url;

	const getUrl = async () => {
		const subdomain = await localStorage.getItem('subdomain');
		if (subdomain) {
			url = subdomain;
		}
		const domain = await localStorage.getItem('domain');
		if (domain) {
			url = domain;
		}

		if (typeof subdomain === 'string' && subdomain.trim().length > 0) {
			url = `${publishDomainValue}.${subdomain}.4t4.io`;
			return;
		}
		url = `${publishDomainValue}.4t4.io`;
	};

	publishDomain.subscribe((value) => {
		publishDomainValue = value;
		getUrl();
	});
</script>

<div class="proteus-final-url">
	<a href={`https://${url}`} target="_blank">{url}</a>
</div>

<style>
	a {
		color: #fff;
	}
</style>
