export let publishPayloadDefault = undefined;

export let publishIsBusyDefault = false;

export let publishErrorDefault = undefined;

export const publishDomainDefault = '';

export const publishSubdomainDefault = '';

export const publishIsUpdateDefault = false;

export const publishTitleDefault = '';

export const publishDescriptionDefault = '';

export const publishUsernameDefault = '';

export const publishOtpDefault = '';

export const publishIsOtpSentDefault = false;

export const publishIsOtpResentDefault = false;

export const publishUrlDefault = '';

export const publishIsPublishedDefault = false;
