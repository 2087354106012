<script>
	import { jsonldReviewName } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldReviewName } from '../../stores/jsonld/UpdateJsonldReviewName';

	let jsonldReviewNameValue;

	jsonldReviewName.subscribe((value) => {
		jsonldReviewNameValue = value;
	});

	const onJsonldReviewNameChange = (event) => updateJsonldReviewName(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldReviewName">Review Name:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldReviewName"
		value={jsonldReviewNameValue}
		on:input={onJsonldReviewNameChange}
	/>
</div>
