<script>
	import { jsonldIngredient } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldIngredient } from '../../stores/jsonld/UpdateJsonldIngredient';

	let jsonldIngredientValue;

	jsonldIngredient.subscribe((value) => {
		jsonldIngredientValue = value;
	});

	const onJsonldIngredientChange = (event) => updateJsonldIngredient(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldIngredient">Ingredient:</label>
	<textarea
		class="proteus-input"
		type="text"
		name="jsonldIngredient"
		value={jsonldIngredientValue}
		on:input={onJsonldIngredientChange}
	/>
</div>
