<script>
	export let msg;
	export let type;
</script>

{#if msg}
	<p class={`proteus-msg is-${type}`}>
		{msg}
	</p>
{/if}
