<script>
	import ColorPicker from '../../ColorPicker.svelte';

	import { stripeBgColor } from '../../stores/stripe/StripeStore';
	import { updateStripeBgColor } from '../../stores/stripe/UpdateStripeBgColor';
	import { resetStripeBgColor } from '../../stores/stripe/ResetStripeBgColor';

	let stripeBgColorValue;

	stripeBgColor.subscribe((value) => {
		stripeBgColorValue = value;
	});

	const onStripeBgColorChange = (event) => updateStripeBgColor(event.detail);

	const onStripeBgColorReset = () => resetStripeBgColor();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Background:
		<span class="proteus-reset" on:click={onStripeBgColorReset}>Reset</span>
	</label>
	<ColorPicker value={stripeBgColorValue} on:input={onStripeBgColorChange} />
</div>
