import { writable } from 'svelte/store';
import {
	headerLogoStartDefault,
	headerLogoCenterDefault,
	headerLogoEndDefault,
	headerLogoStartBase64Default,
	headerLogoCenterBase64Default,
	headerLogoEndBase64Default,
	headerLogoStartAltDefault,
	headerLogoStartTitleDefault,
	headerLogoCenterAltDefault,
	headerLogoCenterTitleDefault,
	headerLogoEndAltDefault,
	headerLogoEndTitleDefault,
} from './HeaderLogoStoreDefaults';

export const headerLogoStart = writable(headerLogoStartDefault);
export const headerLogoStartAlt = writable(headerLogoStartAltDefault);
export const headerLogoStartTitle = writable(headerLogoStartTitleDefault);
export const headerLogoStartBase64 = writable(headerLogoStartBase64Default);

export const headerLogoCenter = writable(headerLogoCenterDefault);
export const headerLogoCenterAlt = writable(headerLogoCenterAltDefault);
export const headerLogoCenterTitle = writable(headerLogoCenterTitleDefault);
export const headerLogoCenterBase64 = writable(headerLogoCenterBase64Default);

export const headerLogoEnd = writable(headerLogoEndDefault);
export const headerLogoEndAlt = writable(headerLogoEndAltDefault);
export const headerLogoEndTitle = writable(headerLogoEndTitleDefault);
export const headerLogoEndBase64 = writable(headerLogoEndBase64Default);
