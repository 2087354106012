<script>
	import { jsonldJobTitle } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldJobTitle } from '../../stores/jsonld/UpdateJsonldJobTitle';

	let jsonldJobTitleValue;

	jsonldJobTitle.subscribe((value) => {
		jsonldJobTitleValue = value;
	});

	const onJsonldJobTitleChange = (event) => updateJsonldJobTitle(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldJobTitle">Job Title:</label>
	<input class="proteus-input" type="text" name="jsonldJobTitle" value={jsonldJobTitleValue} on:input={onJsonldJobTitleChange} />
</div>
