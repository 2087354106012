<script>
	import { jsonldType } from '../../stores/jsonld/JsonldStore';

	import ToolbarJsonldName from './ToolbarJsonldName.svelte';
	import ToolbarJsonldDescription from './ToolbarJsonldDescription.svelte';
	import ToolbarJsonldEmail from './ToolbarJsonldEmail.svelte';
	import ToolbarJsonldUrl from './ToolbarJsonldUrl.svelte';
	import ToolbarJsonldTelephone from './ToolbarJsonldTelephone.svelte';
	import ToolbarJsonldTwitter from './ToolbarJsonldTwitter.svelte';
	import ToolbarJsonldFacebook from './ToolbarJsonldFacebook.svelte';
	import ToolbarJsonldLinkedin from './ToolbarJsonldLinkedin.svelte';
	import ToolbarJsonldInstagram from './ToolbarJsonldInstagram.svelte';
	import ToolbarJsonldAddressStreet from './ToolbarJsonldAddressStreet.svelte';
	import ToolbarJsonldAddressLocality from './ToolbarJsonldAddressLocality.svelte';
	import ToolbarJsonldAddressPostalCode from './ToolbarJsonldAddressPostalCode.svelte';
	import ToolbarJsonldAddressRegion from './ToolbarJsonldAddressRegion.svelte';
	import ToolbarJsonldAddressCountry from './ToolbarJsonldAddressCountry.svelte';
	import ToolbarJsonldYouTube from './ToolbarJsonldYouTube.svelte';
	import ToolbarJsonldGitHub from './ToolbarJsonldGitHub.svelte';

	let jsonldTypeValue;

	jsonldType.subscribe((value) => {
		jsonldTypeValue = value;
	});
</script>

{#if jsonldTypeValue === 'organization'}
	<ToolbarJsonldName />
	<ToolbarJsonldDescription />
	<ToolbarJsonldEmail />
	<ToolbarJsonldUrl />
	<ToolbarJsonldTelephone />
	<ToolbarJsonldTwitter />
	<ToolbarJsonldFacebook />
	<ToolbarJsonldLinkedin />
	<ToolbarJsonldInstagram />
	<ToolbarJsonldYouTube />
	<ToolbarJsonldGitHub />
	<ToolbarJsonldAddressStreet />
	<ToolbarJsonldAddressLocality />
	<ToolbarJsonldAddressPostalCode />
	<ToolbarJsonldAddressRegion />
	<ToolbarJsonldAddressCountry />
{/if}
