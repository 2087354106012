import { writable } from 'svelte/store';
import {
	authAddressDefault,
	authAvatarIdDefault,
	authAvatarThumbUrlDefault,
	authAvatarUrlDefault,
	authFirstNameDefault,
	authIsBusyDefault,
	authIsLoggedInDefault,
	authLastNameDefault,
	authUsernameDefault,
	authSubscriptionDefault,
	authPlanDefault,
} from './AuthStoreDefaults';

export const authIsBusy = writable(authIsBusyDefault);

export const authIsLoggedIn = writable(authIsLoggedInDefault);

export const authUsername = writable(authUsernameDefault);

export const authAvatarId = writable(authAvatarIdDefault);

export const authAvatarUrl = writable(authAvatarUrlDefault);

export const authAvatarThumbUrl = writable(authAvatarThumbUrlDefault);

export const authFirstName = writable(authFirstNameDefault);

export const authLastName = writable(authLastNameDefault);

export const authAddress = writable(authAddressDefault);

export const authSubscription = writable(authSubscriptionDefault);

export const authPlan = writable(authPlanDefault);
