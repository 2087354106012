<script>
	import { jsonldAddressStreet } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAddressStreet } from '../../stores/jsonld/UpdateJsonldAddressStreet';

	let jsonldAddressStreetValue;

	jsonldAddressStreet.subscribe((value) => {
		jsonldAddressStreetValue = value;
	});

	const onJsonldAddressStreetChange = (event) => updateJsonldAddressStreet(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAddressStreet">Address Street:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAddressStreet"
		value={jsonldAddressStreetValue}
		on:input={onJsonldAddressStreetChange}
	/>
</div>
