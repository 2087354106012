<script>
	import { authIsBusy, authIsLoggedIn } from './stores/auth/AuthStore';

	let authIsBusyValue;
	let authIsLoggedInValue;

	$: activePage = new URLSearchParams(location.search).get('page');

	authIsBusy.subscribe((value) => {
		authIsBusyValue = value;
	});

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});
</script>

<nav class="proteus-menu">
	<a href="https://walkthrough.pez.4t4.io">How it works</a>
	<a href="/#pricing">Pricing</a>
	{#if !authIsBusyValue}
		{#if activePage !== 'login'}
			{#if !authIsLoggedInValue}
				<a href="/?page=login">Login</a>
			{/if}
		{/if}
		{#if authIsLoggedInValue}
			{#if activePage !== 'editor'}
				<a href="/?page=editor">Editor</a>
			{/if}
			{#if activePage !== 'uploads'}
				<a href="/?page=uploads">Uploads</a>
			{/if}
			<a href="/?page=logout">Logout</a>
		{/if}
	{/if}
</nav>
