<script>
	import ColorPicker from '../../ColorPicker.svelte';

	import { stripeFgColor } from '../../stores/stripe/StripeStore';
	import { updateStripeFgColor } from '../../stores/stripe/UpdateStripeFgColor';
	import { resetStripeFgColor } from '../../stores/stripe/ResetStripeFgColor';

	let stripeFgColorValue;

	stripeFgColor.subscribe((value) => {
		stripeFgColorValue = value;
	});

	const onStripeFgColorChange = (event) => updateStripeFgColor(event.detail);

	const onStripeFgColorReset = () => resetStripeFgColor();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Text Color:
		<span class="proteus-reset" on:click={onStripeFgColorReset}>Reset</span>
	</label>
	<ColorPicker value={stripeFgColorValue} on:input={onStripeFgColorChange} />
</div>
