<svg enable-background="new 0 0 32 32" height="32px" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="website_favorite_site_star">
        <g id="response_time_1_">
            <circle cx="7.5" cy="4.5" fill="#263238" r="0.5"/>
            <circle cx="5.5" cy="4.5" fill="#263238" r="0.5"/>
            <circle cx="3.5" cy="4.5" fill="#263238" r="0.5"/>
            <path d="M30.5,7h-29C1.224,7,1,6.776,1,6.5S1.224,6,1.5,6h29C30.776,6,31,6.224,31,6.5S30.776,7,30.5,7z" fill="#263238"/>
            <path d="M29.5,28h-4c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4c0.275,0,0.5-0.225,0.5-0.5v-23    C30,3.225,29.775,3,29.5,3h-27C2.225,3,2,3.225,2,3.5v23C2,26.775,2.225,27,2.5,27h4C6.776,27,7,27.224,7,27.5S6.776,28,6.5,28h-4    C1.673,28,1,27.327,1,26.5v-23C1,2.673,1.673,2,2.5,2h27C30.327,2,31,2.673,31,3.5v23C31,27.327,30.327,28,29.5,28z" fill="#263238"/>
        </g>
        <g id="favorite">
            <g>
                <path d="M22.625,30.044c-0.076,0-0.151-0.018-0.222-0.052L16,26.818l-6.403,3.174     c-0.165,0.081-0.364,0.067-0.516-0.044c-0.15-0.109-0.228-0.293-0.201-0.477l1.04-7.07l-4.998-5.11     c-0.13-0.133-0.176-0.327-0.118-0.504s0.208-0.308,0.392-0.339l7.047-1.197l0.898-1.716c0.129-0.244,0.434-0.336,0.676-0.211     c0.244,0.129,0.339,0.431,0.211,0.676l-1.014,1.935c-0.073,0.138-0.206,0.234-0.359,0.261l-6.35,1.079l4.503,4.604     c0.109,0.111,0.16,0.268,0.138,0.422l-0.937,6.371l5.77-2.859c0.139-0.068,0.305-0.068,0.443,0l5.77,2.859l-0.937-6.371     c-0.022-0.154,0.028-0.311,0.138-0.422l4.503-4.604l-6.35-1.079c-0.153-0.026-0.286-0.123-0.359-0.261L16,10.23l-0.728,1.391     c-0.129,0.246-0.432,0.338-0.675,0.212c-0.245-0.128-0.34-0.431-0.212-0.675l1.171-2.237c0.174-0.329,0.713-0.33,0.887-0.001     l3.314,6.331l7.047,1.197c0.184,0.031,0.334,0.162,0.392,0.339s0.012,0.371-0.118,0.504l-4.998,5.11l1.04,7.07     c0.026,0.184-0.051,0.367-0.201,0.477C22.832,30.012,22.729,30.044,22.625,30.044z" fill="#263238"/>
            </g>
        </g>
    </g>
</svg>