<script>
	import Select from 'svelte-select';
	import { seoOgIsActive, seoOgTwitterCard } from '../../stores/seo/SeoStore';
	import { updateSeoOgTwitterCard } from '../../stores/seo/UpdateSeoOgTwitterCard';

	let seoOgIsActiveValue;
	let seoOgTwitterCardValue;
	let selectedValue = {};

	const options = [
		{ value: 'summary', label: 'Summary' },
		{ value: 'summary_large_image', label: 'Summary Large Image' },
	];

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
	});

	seoOgTwitterCard.subscribe((value) => {
		seoOgTwitterCardValue = value;
		selectedValue = options.find((item) => value === item.value);
	});

	const onSeoOgTwitterCardChange = (event) => updateSeoOgTwitterCard(event?.detail?.value || '');
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">OG Twitter Card Type:</label>
	<Select
		items={options}
		value={selectedValue}
		on:select={onSeoOgTwitterCardChange}
		on:clear={onSeoOgTwitterCardChange}
	/>
</div>
