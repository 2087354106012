<script>
	import Switch from '../../Switch.svelte';

	import { bodyBgSizeIsActive } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyBgSizeIsActive } from '../../stores/body-bg/UpdateBodyBgSizeIsActive';

	let bodyBgSizeIsActiveValue;

	bodyBgSizeIsActive.subscribe((value) => {
		bodyBgSizeIsActiveValue = value;
	});

	const onBodyBgSizeIsActiveChange = (event) => updateBodyBgSizeIsActive(event.detail);
</script>

<div class="proteus-control proteus-group proteus-switch-group">
	<Switch checked={bodyBgSizeIsActiveValue} label="Custom pattern size" on:input={onBodyBgSizeIsActiveChange} />
</div>
