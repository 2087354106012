<svg viewBox="0 0 32 32">
    <g id="Browser_x2C__global_x2C__network_x2C__planet_x2C__webpage_x2C__globe">
        <g>
            <g>
                <circle cx="7.5" cy="4.5" fill="#263238" r="0.5"/>
                <circle cx="5.5" cy="4.5" fill="#263238" r="0.5"/>
                <circle cx="3.5" cy="4.5" fill="#263238" r="0.5"/>
                <path d="M30.5,7h-29C1.224,7,1,6.776,1,6.5S1.224,6,1.5,6h29C30.776,6,31,6.224,31,6.5S30.776,7,30.5,7z" fill="#263238"/>
                <path d="M30.5,26c-0.276,0-0.5-0.224-0.5-0.5v-22C30,3.225,29.776,3,29.5,3h-27C2.224,3,2,3.225,2,3.5v22     C2,25.776,1.776,26,1.5,26S1,25.776,1,25.5v-22C1,2.673,1.673,2,2.5,2h27C30.327,2,31,2.673,31,3.5v22     C31,25.776,30.776,26,30.5,26z" fill="#263238"/>
            </g>
            <g>
                <circle cx="30.5" cy="28.5" fill="#263238" r="0.5"/>
                <circle cx="1.5" cy="28.5" fill="#263238" r="0.5"/>
                <g>
                    <g>
                        <path d="M28.5,28H17.929C17.971,28.161,18,28.326,18,28.5s-0.029,0.339-0.071,0.5H28.5       c0.276,0,0.5-0.224,0.5-0.5S28.776,28,28.5,28z" fill="#263238"/>
                        <path d="M14,28.5c0-0.174,0.029-0.339,0.071-0.5H3.5C3.224,28,3,28.224,3,28.5S3.224,29,3.5,29h10.571       C14.029,28.839,14,28.674,14,28.5z" fill="#263238"/>
                    </g>
                    <path d="M16,31c-1.378,0-2.5-1.121-2.5-2.5S14.622,26,16,26s2.5,1.121,2.5,2.5S17.378,31,16,31z M16,27      c-0.827,0-1.5,0.673-1.5,1.5S15.173,30,16,30s1.5-0.673,1.5-1.5S16.827,27,16,27z" fill="#263238"/>
                </g>
            </g>
        </g>
        <g>
            <g>
                <g>
                    <g>
                        <path d="M16,24.5c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S20.411,24.5,16,24.5z M16,9.5       c-3.859,0-7,3.141-7,7s3.141,7,7,7s7-3.141,7-7S19.859,9.5,16,9.5z" fill="#263238"/>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path d="M10.783,22.322c-0.185,0-0.361-0.103-0.449-0.279c-0.121-0.248-0.02-0.548,0.229-0.67      c3.422-1.68,7.492-1.82,11.02-0.382c0.256,0.104,0.379,0.396,0.275,0.651c-0.104,0.256-0.398,0.378-0.652,0.274      c-3.266-1.329-7.033-1.2-10.201,0.354C10.933,22.306,10.857,22.322,10.783,22.322z" fill="#263238"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M16.5,13c-1.433,0-2.844-0.224-4.193-0.664c-0.262-0.086-0.406-0.368-0.32-0.631s0.366-0.405,0.631-0.32      c2.723,0.89,5.749,0.808,8.416-0.233c0.256-0.102,0.547,0.027,0.647,0.284s-0.026,0.547-0.284,0.647      C19.837,12.691,18.189,13,16.5,13z" fill="#263238"/>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path d="M15.999,24.481c-0.097,0-0.194-0.028-0.278-0.085C13.078,22.626,11.5,19.674,11.5,16.5       c0-3.176,1.578-6.128,4.221-7.896c0.169-0.113,0.388-0.113,0.557,0c2.645,1.77,4.223,4.722,4.223,7.896       c0,3.174-1.578,6.126-4.223,7.896C16.193,24.453,16.096,24.481,15.999,24.481z M15.999,9.628       C13.802,11.229,12.5,13.772,12.5,16.5c0,2.727,1.302,5.271,3.499,6.872c2.198-1.602,3.501-4.146,3.501-6.872       C19.5,13.772,18.197,11.229,15.999,9.628z" fill="#263238"/>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path d="M23.083,17.041H10.521c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h12.562c0.276,0,0.5,0.224,0.5,0.5      S23.359,17.041,23.083,17.041z" fill="#263238"/>
                </g>
            </g>
        </g>
    </g>
</svg>