import {
	jsonldType,
	jsonldArticleType,
	jsonldName,
	jsonldTitle,
	jsonldDescription,
	jsonldKeywords,
	jsonldImage,
	jsonldUrl,
	jsonldPage,
	jsonldAuthorName,
	jsonldAuthorUrl,
	jsonldPublisherName,
	jsonldPublisherUrl,
	jsonldPerformerName,
	jsonldPerformerUrl,
	jsonldOrganizerName,
	jsonldOrganizerUrl,
	jsonldJobTitle,
	jsonldJobCompany,
	jsonldEmail,
	jsonldTwitter,
	jsonldFacebook,
	jsonldLinkedin,
	jsonldInstagram,
	jsonldYouTube,
	jsonldGitHub,
	jsonldTelephone,
	jsonldLatitude,
	jsonldLongitude,
	jsonldCurrencyValue,
	jsonldCurrency,
	jsonldBusinessFunction,
	jsonldAcceptedPaymentMethods,
	jsonldIngredient,
	jsonldRecipeYield,
	jsonldRecipeCategory,
	jsonldRecipeCuisine,
	jsonldInstructions,
	jsonldStartTime,
	jsonldEndTime,
	jsonldAddressStreet,
	jsonldAddressLocality,
	jsonldAddressPostalCode,
	jsonldAddressRegion,
	jsonldAddressCountry,
	jsonldBrandName,
	jsonldSku,
	jsonldReviewName,
	jsonldReviewBody,
	jsonldReviewAuthor,
	jsonldReviewPublisher,
	jsonldReviewRatingValue,
	jsonldReviewBestRating,
	jsonldAggregateRatingValue,
	jsonldAggregateRatingBestRating,
	jsonldAggregateRatingCount,
} from './JsonldStore';
import {
	jsonldTypeDefault,
	jsonldArticleTypeDefault,
	jsonldNameDefault,
	jsonldTitleDefault,
	jsonldDescriptionDefault,
	jsonldKeywordsDefault,
	jsonldImageDefault,
	jsonldUrlDefault,
	jsonldPageDefault,
	jsonldAuthorNameDefault,
	jsonldAuthorUrlDefault,
	jsonldPublisherNameDefault,
	jsonldPublisherUrlDefault,
	jsonldPerformerNameDefault,
	jsonldPerformerUrlDefault,
	jsonldOrganizerNameDefault,
	jsonldOrganizerUrlDefault,
	jsonldJobTitleDefault,
	jsonldJobCompanyDefault,
	jsonldEmailDefault,
	jsonldTwitterDefault,
	jsonldFacebookDefault,
	jsonldLinkedinDefault,
	jsonldInstagramDefault,
	jsonldYouTubeDefault,
	jsonldGitHubDefault,
	jsonldTelephoneDefault,
	jsonldLatitudeDefault,
	jsonldLongitudeDefault,
	jsonldCurrencyValueDefault,
	jsonldCurrencyDefault,
	jsonldBusinessFunctionDefault,
	jsonldAcceptedPaymentMethodsDefault,
	jsonldIngredientDefault,
	jsonldRecipeYieldDefault,
	jsonldRecipeCategoryDefault,
	jsonldRecipeCuisineDefault,
	jsonldInstructionsDefault,
	jsonldStartTimeDefault,
	jsonldEndTimeDefault,
	jsonldAddressStreetDefault,
	jsonldAddressLocalityDefault,
	jsonldAddressPostalCodeDefault,
	jsonldAddressRegionDefault,
	jsonldAddressCountryDefault,
	jsonldBrandNameDefault,
	jsonldSkuDefault,
	jsonldReviewNameDefault,
	jsonldReviewBodyDefault,
	jsonldReviewAuthorDefault,
	jsonldReviewPublisherDefault,
	jsonldReviewRatingValueDefault,
	jsonldReviewBestRatingDefault,
	jsonldAggregateRatingValueDefault,
	jsonldAggregateRatingBestRatingDefault,
	jsonldAggregateRatingCountDefault,
} from './JsonldStoreDefaults';

export const resetJsonld = () => {
	jsonldType.set(jsonldTypeDefault);
	jsonldArticleType.set(jsonldArticleTypeDefault);
	jsonldName.set(jsonldNameDefault);
	jsonldTitle.set(jsonldTitleDefault);
	jsonldDescription.set(jsonldDescriptionDefault);
	jsonldKeywords.set(jsonldKeywordsDefault);
	jsonldImage.set(jsonldImageDefault);
	jsonldUrl.set(jsonldUrlDefault);
	jsonldPage.set(jsonldPageDefault);
	jsonldAuthorName.set(jsonldAuthorNameDefault);
	jsonldAuthorUrl.set(jsonldAuthorUrlDefault);
	jsonldPublisherName.set(jsonldPublisherNameDefault);
	jsonldPublisherUrl.set(jsonldPublisherUrlDefault);
	jsonldPerformerName.set(jsonldPerformerNameDefault);
	jsonldPerformerUrl.set(jsonldPerformerUrlDefault);
	jsonldOrganizerName.set(jsonldOrganizerNameDefault);
	jsonldOrganizerUrl.set(jsonldOrganizerUrlDefault);
	jsonldJobTitle.set(jsonldJobTitleDefault);
	jsonldJobCompany.set(jsonldJobCompanyDefault);
	jsonldEmail.set(jsonldEmailDefault);
	jsonldTwitter.set(jsonldTwitterDefault);
	jsonldFacebook.set(jsonldFacebookDefault);
	jsonldLinkedin.set(jsonldLinkedinDefault);
	jsonldInstagram.set(jsonldInstagramDefault);
	jsonldYouTube.set(jsonldYouTubeDefault);
	jsonldGitHub.set(jsonldGitHubDefault);
	jsonldTelephone.set(jsonldTelephoneDefault);
	jsonldLatitude.set(jsonldLatitudeDefault);
	jsonldLongitude.set(jsonldLongitudeDefault);
	jsonldCurrencyValue.set(jsonldCurrencyValueDefault);
	jsonldCurrency.set(jsonldCurrencyDefault);
	jsonldBusinessFunction.set(jsonldBusinessFunctionDefault);
	jsonldAcceptedPaymentMethods.set(jsonldAcceptedPaymentMethodsDefault);
	jsonldIngredient.set(jsonldIngredientDefault);
	jsonldRecipeYield.set(jsonldRecipeYieldDefault);
	jsonldRecipeCategory.set(jsonldRecipeCategoryDefault);
	jsonldRecipeCuisine.set(jsonldRecipeCuisineDefault);
	jsonldInstructions.set(jsonldInstructionsDefault);
	jsonldStartTime.set(jsonldStartTimeDefault);
	jsonldEndTime.set(jsonldEndTimeDefault);
	jsonldAddressStreet.set(jsonldAddressStreetDefault);
	jsonldAddressLocality.set(jsonldAddressLocalityDefault);
	jsonldAddressPostalCode.set(jsonldAddressPostalCodeDefault);
	jsonldAddressRegion.set(jsonldAddressRegionDefault);
	jsonldAddressCountry.set(jsonldAddressCountryDefault);
	jsonldBrandName.set(jsonldBrandNameDefault);
	jsonldSku.set(jsonldSkuDefault);
	jsonldReviewName.set(jsonldReviewNameDefault);
	jsonldReviewBody.set(jsonldReviewBodyDefault);
	jsonldReviewAuthor.set(jsonldReviewAuthorDefault);
	jsonldReviewPublisher.set(jsonldReviewPublisherDefault);
	jsonldReviewRatingValue.set(jsonldReviewRatingValueDefault);
	jsonldReviewBestRating.set(jsonldReviewBestRatingDefault);
	jsonldAggregateRatingValue.set(jsonldAggregateRatingValueDefault);
	jsonldAggregateRatingBestRating.set(jsonldAggregateRatingBestRatingDefault);
	jsonldAggregateRatingCount.set(jsonldAggregateRatingCountDefault);
};
