<script>
	import { jsonldKeywords } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldKeywords } from '../../stores/jsonld/UpdateJsonldKeywords';

	let jsonldKeywordsValue;

	jsonldKeywords.subscribe((value) => {
		jsonldKeywordsValue = value;
	});

	const onJsonldKeywordsChange = (event) => updateJsonldKeywords(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldKeywords">Keywords:</label>
	<input class="proteus-input" type="text" name="jsonldKeywords" value={jsonldKeywordsValue} on:input={onJsonldKeywordsChange} />
</div>
