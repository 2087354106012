<script>
	import { publishDescription } from '../../stores/publish/PublishStore';
	import { seoOgIsActive, seoOgTwitterDescription } from '../../stores/seo/SeoStore';
	import { updateSeoOgTwitterDescription } from '../../stores/seo/UpdateSeoOgTwitterDescription';

	let publishDescriptionValue;
	let seoOgIsActiveValue;
	let seoOgTwitterDescriptionValue;

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
		if (!seoOgIsActiveValue) {
			updateSeoOgTwitterDescription(publishDescriptionValue);
		}
	});

	publishDescription.subscribe((value) => {
		publishDescriptionValue = value;
		if (!seoOgIsActiveValue) {
			updateSeoOgTwitterDescription(publishDescriptionValue);
		}
	});

	seoOgTwitterDescription.subscribe((value) => {
		seoOgTwitterDescriptionValue = value;
	});

	const onSeoOgTwitterDescriptionChange = (event) => updateSeoOgTwitterDescription(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">OG Twitter Description:</label>
	<input class="proteus-input" type="text" name="seoOgTwitterDescription" value={seoOgTwitterDescriptionValue} on:input={onSeoOgTwitterDescriptionChange} disabled={!seoOgIsActiveValue} readonly={!seoOgIsActiveValue} />
</div>
