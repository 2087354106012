import { writable } from 'svelte/store';
import {
	publishIsBusyDefault,
	publishErrorDefault,
	publishDomainDefault,
	publishSubdomainDefault,
	publishIsUpdateDefault,
	publishTitleDefault,
	publishDescriptionDefault,
	publishUsernameDefault,
	publishOtpDefault,
	publishIsOtpSentDefault,
	publishIsOtpResentDefault,
	publishPayloadDefault,
	publishUrlDefault,
	publishIsPublishedDefault,
} from './PublishStoreDefaults';

// payload - contains data of all stores - to be used during publishing
export const publishPayload = writable(publishPayloadDefault);

// is busy
export const publishIsBusy = writable(publishIsBusyDefault);

// error
export const publishError = writable(publishErrorDefault);

// subdomain
export const publishDomain = writable(publishDomainDefault);

// published domain - this is the main subdomain - example - chapter1 - other pages will be like page1.chapter1, page2.chapter2
export const publishSubdomain = writable(publishSubdomainDefault);

// is update
export const publishIsUpdate = writable(publishIsUpdateDefault);

// site title
export const publishTitle = writable(publishTitleDefault);

// site description
export const publishDescription = writable(publishDescriptionDefault);

// username
export const publishUsername = writable(publishUsernameDefault);

// otp
export const publishOtp = writable(publishOtpDefault);

// is otp sent
export const publishIsOtpSent = writable(publishIsOtpSentDefault);

// is otp resent
export const publishIsOtpResent = writable(publishIsOtpResentDefault);

// url
export const publishUrl = writable(publishUrlDefault);

// is published
export const publishIsPublished = writable(publishIsPublishedDefault);
