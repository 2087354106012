<script>
	import { onMount } from 'svelte';
	import axios from 'axios';
	import localStorage from 'localforage';
	import Message from '../Message.svelte';
	import LoaderFullscreen from '../LoaderFullscreen.svelte';
	import MobileNumber from '../MobileNumber.svelte';
	import Otp from '../Otp.svelte';
	import { getErrorMessage } from '../utils';

	let isOtpSent = false;
	let mobileNumber = '';
	let msg = '';
	let msgType = '';
	let isBusy = false;

	const otpKey = 'downloadOtp';

	const onOtpSent = (mobile) => {
		isOtpSent = mobile ? true : false;
		mobileNumber = mobile;
	};

	const onOtpResent = () => {
		isOtpSent = false;
	};

	const orderId = new URLSearchParams(location.search).get('id');

	onMount(async () => {
		try {
			const response = await axios.post('/api/user-download', { orderId });
			window.location.href = response.data;
		} catch (err) {
			console.log('Error submitting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			isBusy = false;
		}
	});

	const onRequestOtp = async (value) => {
		let response;
		try {
			response = await axios.post('/api/user-download', { username: `+${value}`, orderId });
			localStorage.setItem(otpKey, response.data);
			msg = 'code sent';
			msgType = 'success';
			onOtpSent(`+${value}`);
		} catch (err) {
			console.log('Error requesting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			onOtpSent(false);
		}
	};

	const onComplete = async (value) => {
		msg = undefined;
		isBusy = true;
		let response;
		try {
			const { id, userId } = await localStorage.getItem(otpKey);
			response = await axios.post('/api/user-download', { id, username: userId, otp: value, orderId });
			document.body.remove();
			window.location.href = response.data;
		} catch (err) {
			console.log('Error submitting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			isBusy = false;
		}
	};
</script>

<!-- <div class="proteus-container">
	<div class="proteus-login-card">
		<div class="proteus-login">
			<h2 class="proteus-login-title">simple download</h2>
			<p class="proteus-login-info">We send a secure code to your mobile number whenever you want to download</p>
			<p class="proteus-login-subtitle">
				<strong>Mobile Number</strong>
			</p>
			<MobileNumber {onRequestOtp} {onOtpSent} {msg} {msgType} />
			<p>
				<strong> download </strong>
				by entering the code we send you
			</p>
			<Otp {onComplete} {msg} {msgType} />
		</div>
	</div>
</div> -->

<div class="proteus-container">
	<Message {msg} type={msgType} />
</div>

{#if isBusy}
	<LoaderFullscreen />
{/if}
