<script>
	import axios from 'axios';
	import { Confirm } from 'svelte-confirm';
	import { bytesToSize, formatUtc, getOtpLoginData, isLocalhost, notifyError, notifySuccess } from './utils';
	import { authIsLoggedIn } from './stores/auth/AuthStore';
	import IconRemove from './icons/IconRemove.svelte';
	import IconDownload from './icons/IconDownload.svelte';
	import LoaderFullscreen from './LoaderFullscreen.svelte';
	import CopyText from './CopyText.svelte';

	import { getNotificationsContext } from 'svelte-notifications';
	const { addNotification } = getNotificationsContext();

	import { uploadItems, uploadIsBusy, uploadError } from './stores/uploads/UploadStore';
	import { updateUploadItems } from './stores/uploads/UpdateUploadItems';
	import { updateUploadIsBusy } from './stores/uploads/UpdateUploadIsBusy';
	import { updateUploadError } from './stores/uploads/UpdateUploadError';

	let authIsLoggedInValue;
	let uploadItemsValue;
	let uploadIsBusyValue;
	let uploadErrorValue;

	let isDownloading = false;

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});

	uploadItems.subscribe((value) => {
		uploadItemsValue = value;
	});

	uploadIsBusy.subscribe((value) => {
		uploadIsBusyValue = value;
	});

	uploadError.subscribe((value) => {
		uploadErrorValue = value;
	});

	const getUrl = (id) => {
		const host = isLocalhost() ? 'http://localhost:8888' : 'https://4t4.io';
		return `${host}/?page=download&id=${id}`;
	};

	const onDownload = async (id) => {
		isDownloading = true;
		const otpLoginData = await getOtpLoginData();
		try {
			axios
				.post('/api/storage', {
					...otpLoginData,
					id,
					method: 'GET',
				})
				.then((resposne) => {
					window.location = resposne.data.url;
				});
		} catch (err) {
			console.log('Error downloading file - ', err);
			notifyError(addNotification, null, 'Error downloading file');
		}
		isDownloading = false;
	};

	const onDelete = async (id) => {
		if (!authIsLoggedInValue) {
			return notifyError(addNotification, null, 'Unauthorized!');
		}
		updateUploadIsBusy(true);
		const otpLoginData = await getOtpLoginData();
		try {
			axios
				.post('/api/storage', {
					...otpLoginData,
					id,
					method: 'DELETE',
				})
				.then((resposne) => {
					notifySuccess(addNotification, 'File deleted successfully!');
				});
			updateUploadItems(uploadItemsValue.filter((item) => item.id !== id));
		} catch (err) {
			console.log('Error deleting upload - ', err);
			notifyError(addNotification, null);
		}
		updateUploadIsBusy(false);
	};
</script>

<div class="proteus-entries">
	{#each uploadItemsValue as entry}
		<div class="proteus-entry is-multiline">
			<div class="proteus-entry-col">
				<div class="proteus-entry-title">
					<strong>
						{entry.name}
					</strong>
				</div>
				<div class="proteus-entry-meta">
					<!-- <a href={getUrl(entry.id)} target="_blank">
						<small>
							{getUrl(entry.id)}
						</small>
					</a> -->
				</div>
			</div>
			<div class="proteus-entry-col">
				<div class="proteus-entry-title">{bytesToSize(entry.size)}</div>
				<div class="proteus-entry-meta">
					<small>{formatUtc(entry.createdAt)}</small>
				</div>
			</div>
			<div class="proteus-entry-col is-action">
				<span on:click={isDownloading ? null : onDownload(entry.id)} class="proteus-icon is-clickable">
					<IconDownload />
				</span>
				<Confirm confirmTitle="Yes" cancelTitle="No" let:confirm={confirmThis}>
					<span on:click={() => confirmThis(() => onDelete(entry.id))} class="proteus-icon is-clickable is-error">
						<IconRemove />
					</span>
					<span slot="title">Delete this upload?</span>
					<span slot="description"> This will delete the selected file from the server. You won't be able to revert this! </span>
				</Confirm>
			</div>
			<div class="proteus-entry-col is-fullwidth">
				<div class="is-copy-text">
					<a style="flex: 1; margin-right: 1rem;" href={getUrl(entry.id)} target="_blank">{getUrl(entry.id)}</a>
					<CopyText text={getUrl(entry.id)} url={getUrl(entry.id)} />
				</div>
			</div>
		</div>
	{/each}
</div>

{#if !uploadIsBusyValue && uploadItemsValue?.length == 0}
	<div class="proteus-block has-text-center">
		<small>No uploads are available</small>
	</div>
{/if}
