<script>
	import { jsonldType } from '../../stores/jsonld/JsonldStore';

	import ToolbarJsonldName from './ToolbarJsonldName.svelte';
	import ToolbarJsonldDescription from './ToolbarJsonldDescription.svelte';
	import ToolbarJsonldUrl from './ToolbarJsonldUrl.svelte';
	import ToolbarJsonldTelephone from './ToolbarJsonldTelephone.svelte';
	import ToolbarJsonldLatitude from './ToolbarJsonldLatitude.svelte';
	import ToolbarJsonldLongitude from './ToolbarJsonldLongitude.svelte';
	import ToolbarJsonldAddressStreet from './ToolbarJsonldAddressStreet.svelte';
	import ToolbarJsonldAddressLocality from './ToolbarJsonldAddressLocality.svelte';
	import ToolbarJsonldAddressPostalCode from './ToolbarJsonldAddressPostalCode.svelte';
	import ToolbarJsonldAddressRegion from './ToolbarJsonldAddressRegion.svelte';
	import ToolbarJsonldAddressCountry from './ToolbarJsonldAddressCountry.svelte';

	let jsonldTypeValue;

	jsonldType.subscribe((value) => {
		jsonldTypeValue = value;
	});
</script>

{#if jsonldTypeValue === 'place'}
	<ToolbarJsonldName />
	<ToolbarJsonldDescription />
	<ToolbarJsonldUrl />
	<ToolbarJsonldTelephone />
	<ToolbarJsonldLatitude />
	<ToolbarJsonldLongitude />
	<ToolbarJsonldAddressStreet />
	<ToolbarJsonldAddressLocality />
	<ToolbarJsonldAddressPostalCode />
	<ToolbarJsonldAddressRegion />
	<ToolbarJsonldAddressCountry />
{/if}
