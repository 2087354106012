<script>
	import { onMount } from 'svelte';
	import Logo from '../Logo.svelte';

	onMount(() => {
		document.body.classList.add('has-reset');
	});
</script>

<main>
	<!-- <img
		src="https://ik.imagekit.io/ymjlyrr8zyb/4t4ioGraphic_tclojBlDF.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1658391406819/tr:w-auto-600,pr-true"
		sizes="(max-width: 600px) 50vw, 100vw"
		width="180"
		height="75"
		alt=" 4t4"
		title="4t4"
	/> -->

	<div style="width: 400px; margin: auto;">
		<Logo />
	</div>

	<h2 style="text-align: center; padding-top: 0; color: gray">Error</h2>

	<div style="font-size: 1rem; text-align: center; font-weight: 400; padding: 0 1rem; line-height: 1.5">
		Something went wrong : (
		<br />
		Please try again
	</div>

	<div style="margin: 2rem auto;">
		<a href="/" class="primary-btn"> Return to 4t4 </a>
	</div>
</main>
