<script>
	import classNames from 'classnames';
	import { Tabs, TabList, TabPanel, Tab } from '../../tabs';
	import { isToolbarSeoActive } from '../../stores/toolbar/ToolbarStore';
	import { seoOgIsActive } from '../../stores/seo/SeoStore';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';
	import ToolbarSeoFavicon from './ToolbarSeoFavicon.svelte';
	import ToolbarSeoOgDescription from './ToolbarSeoOgDescription.svelte';
	import ToolbarSeoOgImage from './ToolbarSeoOgImage.svelte';
	import ToolbarSeoOgTitle from './ToolbarSeoOgTitle.svelte';
	import ToolbarSeoOgType from './ToolbarSeoOgType.svelte';
	import ToolbarSeoOgTwitterCard from './ToolbarSeoOgTwitterCard.svelte';
	import ToolbarSeoOgTwitterTitle from './ToolbarSeoOgTwitterTitle.svelte';
	import ToolbarSeoOgTwitterDescription from './ToolbarSeoOgTwitterDescription.svelte';
	import ToolbarSeoTitle from './ToolbarSeoTitle.svelte';
	import ToolbarSeoDescription from './ToolbarSeoDescription.svelte';
	import ToolbarSeoOgIsActive from './ToolbarSeoOgIsActive.svelte';
	import ToolbarSeoStyler from './ToolbarSeoStyler.svelte';
	import ToolbarJsonld from '../toolbar-jsonld/ToolbarJsonld.svelte';
	import ToolbarJsonldStyler from '../toolbar-jsonld/ToolbarJsonldStyler.svelte';
	import ToolbarSeoOgTwitterImage from './ToolbarSeoOgTwitterImage.svelte';
	import ToolbarSeoOgTwitterSite from './ToolbarSeoOgTwitterSite.svelte';
	import ToolbarSeoOgTwitterCreator from './ToolbarSeoOgTwitterCreator.svelte';

	let isToolbarSeoActiveValue;
	let seoOgIsActiveValue;

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
	});

	isToolbarSeoActive.subscribe((value) => {
		isToolbarSeoActiveValue = value;
	});
</script>

<ToolbarSeoStyler />
<ToolbarJsonldStyler />

<div class={classNames('proteus-modal', { 'is-active': isToolbarSeoActiveValue })}>
	<ToolbarOverlay />
	<div class="proteus-modal-dialog has-tabs">
		<Tabs>
			<TabList>
				<Tab>General</Tab>
				<Tab>Open Graph</Tab>
				<Tab>JSON-LD</Tab>
			</TabList>
			<TabPanel>
				<ToolbarSeoFavicon />
				<ToolbarSeoTitle />
				<ToolbarSeoDescription />
			</TabPanel>
			<TabPanel>
				<ToolbarSeoOgImage />
				<ToolbarSeoOgType />
				<ToolbarSeoOgTwitterCard />
				<ToolbarSeoOgTwitterImage />
				<ToolbarSeoOgTwitterSite />
				<ToolbarSeoOgTwitterCreator />
				<ToolbarSeoOgIsActive />
				<ToolbarSeoOgTitle />
				<ToolbarSeoOgDescription />
				<ToolbarSeoOgTwitterTitle />
				<ToolbarSeoOgTwitterDescription />
			</TabPanel>
			<TabPanel>
				<ToolbarJsonld />
			</TabPanel>
		</Tabs>
	</div>
</div>
