<script>
	import { jsonldType } from '../../stores/jsonld/JsonldStore';

	import ToolbarJsonldName from './ToolbarJsonldName.svelte';
	import ToolbarJsonldDescription from './ToolbarJsonldDescription.svelte';
	import ToolbarJsonldStartTime from './ToolbarJsonldStartTime.svelte';
	import ToolbarJsonldEndTime from './ToolbarJsonldEndTime.svelte';
	import ToolbarJsonldPerformerName from './ToolbarJsonldPerformerName.svelte';
	import ToolbarJsonldPerformerUrl from './ToolbarJsonldPerformerUrl.svelte';
	import ToolbarJsonldOrganizerName from './ToolbarJsonldOrganizerName.svelte';
	import ToolbarJsonldOrganizerUrl from './ToolbarJsonldOrganizerUrl.svelte';
	import ToolbarJsonldAddressStreet from './ToolbarJsonldAddressStreet.svelte';
	import ToolbarJsonldAddressLocality from './ToolbarJsonldAddressLocality.svelte';
	import ToolbarJsonldAddressPostalCode from './ToolbarJsonldAddressPostalCode.svelte';
	import ToolbarJsonldAddressRegion from './ToolbarJsonldAddressRegion.svelte';
	import ToolbarJsonldAddressCountry from './ToolbarJsonldAddressCountry.svelte';
	import ToolbarJsonldUrl from './ToolbarJsonldUrl.svelte';

	let jsonldTypeValue;

	jsonldType.subscribe((value) => {
		jsonldTypeValue = value;
	});
</script>

{#if jsonldTypeValue === 'event'}
	<ToolbarJsonldName />
	<ToolbarJsonldDescription />
	<!-- <ToolbarJsonldStartTime /> -->
	<!-- <ToolbarJsonldEndTime /> -->
	<ToolbarJsonldUrl />
	<ToolbarJsonldPerformerName />
	<ToolbarJsonldPerformerUrl />
	<ToolbarJsonldOrganizerName />
	<ToolbarJsonldOrganizerUrl />
	<ToolbarJsonldAddressStreet />
	<ToolbarJsonldAddressLocality />
	<ToolbarJsonldAddressPostalCode />
	<ToolbarJsonldAddressRegion />
	<ToolbarJsonldAddressCountry />
{/if}
