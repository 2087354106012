<svg
	class="svg-icon"
	viewBox="0 0 1024 1024"
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
>
	<title>Exit Fullscreen Mode</title>
	<path
		d="M128 128h256v64H192v192H128V128zM896 640v256h-256v-64h192v-192h64zM384 686.496L174.496 896 128 849.504 337.504 640H192v-64h256v256h-64v-145.504zM849.504 128L640 337.504V192h-64v256h256v-64h-145.504L896 174.496 849.504 128z"
	/>
</svg>
