<script>
	import { jsonldLinkedin } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldLinkedin } from '../../stores/jsonld/UpdateJsonldLinkedin';

	let jsonldLinkedinValue;

	jsonldLinkedin.subscribe((value) => {
		jsonldLinkedinValue = value;
	});

	const onJsonldLinkedinChange = (event) => updateJsonldLinkedin(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldLinkedin">Linkedin:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldLinkedin"
		value={jsonldLinkedinValue}
		on:input={onJsonldLinkedinChange}
	/>
</div>
