<svg
	class="svg-icon"
	viewBox="0 0 1024 1024"
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M127.792013 127.778198l447.934892 0 0 255.959579L767.698125 383.737778l0 63.990407 63.989383 0L831.687508 319.748395 575.726905 63.788815 127.792013 63.788815c-35.18229 0-63.990407 28.807093-63.990407 63.990407l0 767.881808c0 35.18229 28.808116 63.989383 63.990407 63.989383l319.949986 0 0-63.989383L127.792013 895.66103 127.792013 127.778198zM643.717416 195.767686l125.292588 125.292588L643.717416 321.060273 643.717416 195.767686z"
	/>
	<path
		d="M959.668321 741.247194l0-75.176167-66.113767-13.373589c-4.439102-16.749476-11.125385-32.558533-19.560498-47.240931l37.245276-56.178487-53.118801-53.120848-56.175417 37.247322c-14.685467-8.44023-30.43415-15.123442-47.244001-19.562545l-13.433964-66.113767-75.115791 0-13.433964 66.113767c-16.809851 4.439102-32.558533 11.122315-47.305399 19.562545l-56.116065-37.247322-53.122895 53.120848 37.249369 56.178487c-8.498558 14.682397-15.185864 30.491455-19.620873 47.240931l-66.059532 13.433964 0 75.115791 66.059532 13.433964c4.435009 16.809851 11.122315 32.618908 19.620873 47.240931l-37.249369 56.178487 53.122895 53.118801 56.116065-37.245276c14.745842 8.499581 30.495548 15.185864 47.305399 19.560498l13.433964 66.113767 75.115791 0 13.433964-66.113767c16.809851-4.374634 32.558533-11.06194 47.244001-19.560498l56.175417 37.245276 53.118801-53.118801-37.245276-56.178487c8.499581-14.622022 15.121396-30.43108 19.560498-47.240931L959.668321 741.247194zM703.644273 831.605132c-70.673619 0-127.917368-57.239656-127.917368-127.978766 0-70.676689 57.242725-127.97979 127.917368-127.97979 70.740134 0.063445 128.043235 57.366545 128.043235 127.97979C831.687508 774.3665 774.384407 831.605132 703.644273 831.605132z"
	/>
</svg>
