<script>
	import { publishTitle } from '../../stores/publish/PublishStore';
	import { seoOgIsActive, seoOgTitle } from '../../stores/seo/SeoStore';
	import { updateSeoOgTitle } from '../../stores/seo/UpdateSeoOgTitle';

	let publishTitleValue;
	let seoOgTitleValue;
	let seoOgIsActiveValue;

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
		if (!seoOgIsActiveValue) {
			updateSeoOgTitle(publishTitleValue);
		}
	});

	publishTitle.subscribe((value) => {
		publishTitleValue = value;
		if (!seoOgIsActiveValue) {
			updateSeoOgTitle(publishTitleValue);
		}
	});

	seoOgTitle.subscribe((value) => {
		seoOgTitleValue = value;
	});

	const onSeoOgTitleChange = (event) => updateSeoOgTitle(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">OG Title:</label>
	<input class="proteus-input" type="text" name="seoOgTitle" value={seoOgTitleValue} on:input={onSeoOgTitleChange} disabled={!seoOgIsActiveValue} readonly={!seoOgIsActiveValue} />
</div>
