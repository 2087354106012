<script>
	import IconOembed from '../../icons/IconOembed.svelte';

	import { editorFocusedEle } from '../../stores/editor/EditorStore';
	import { isToolbarOembedActive } from '../../stores/toolbar/ToolbarStore';
	import { updateToolbarOembed } from '../../stores/toolbar/UpdateToolbarOembed';

	let editorFocusedEleValue;
	let isToolbarOembedActiveValue;

	editorFocusedEle.subscribe((value) => {
		editorFocusedEleValue = value;
	});

	isToolbarOembedActive.subscribe((value) => {
		isToolbarOembedActiveValue = value;
	});

	const onToggle = () => updateToolbarOembed(!isToolbarOembedActiveValue);
</script>

<button
	class="proteus-toolbar-button is-oembed-image"
	on:click={onToggle}
	disabled={!editorFocusedEleValue}
	title="Add YouTube/Vimeo"
>
	<IconOembed />
</button>
