import { writable } from 'svelte/store';
import {
	jsonldTypeDefault,
	jsonldArticleTypeDefault,
	jsonldNameDefault,
	jsonldTitleDefault,
	jsonldDescriptionDefault,
	jsonldKeywordsDefault,
	jsonldImageDefault,
	jsonldUrlDefault,
	jsonldPageDefault,
	jsonldAuthorNameDefault,
	jsonldAuthorUrlDefault,
	jsonldPublisherNameDefault,
	jsonldPublisherUrlDefault,
	jsonldPerformerNameDefault,
	jsonldPerformerUrlDefault,
	jsonldOrganizerNameDefault,
	jsonldOrganizerUrlDefault,
	jsonldJobTitleDefault,
	jsonldJobCompanyDefault,
	jsonldEmailDefault,
	jsonldTelephoneDefault,
	jsonldTwitterDefault,
	jsonldFacebookDefault,
	jsonldLinkedinDefault,
	jsonldInstagramDefault,
	jsonldYouTubeDefault,
	jsonldGitHubDefault,
	jsonldLatitudeDefault,
	jsonldLongitudeDefault,
	jsonldCurrencyValueDefault,
	jsonldCurrencyDefault,
	jsonldBusinessFunctionDefault,
	jsonldAcceptedPaymentMethodsDefault,
	jsonldIngredientDefault,
	jsonldRecipeYieldDefault,
	jsonldRecipeCategoryDefault,
	jsonldRecipeCuisineDefault,
	jsonldInstructionsDefault,
	jsonldStartTimeDefault,
	jsonldEndTimeDefault,
	jsonldAddressStreetDefault,
	jsonldAddressLocalityDefault,
	jsonldAddressPostalCodeDefault,
	jsonldAddressRegionDefault,
	jsonldAddressCountryDefault,
	jsonldBrandNameDefault,
	jsonldSkuDefault,
	jsonldReviewNameDefault,
	jsonldReviewBodyDefault,
	jsonldReviewAuthorDefault,
	jsonldReviewPublisherDefault,
	jsonldReviewRatingValueDefault,
	jsonldReviewBestRatingDefault,
	jsonldAggregateRatingValueDefault,
	jsonldAggregateRatingBestRatingDefault,
	jsonldAggregateRatingCountDefault,
} from './JsonldStoreDefaults';

// type
export const jsonldType = writable(jsonldTypeDefault);

// article type
export const jsonldArticleType = writable(jsonldArticleTypeDefault);

// name
export const jsonldName = writable(jsonldNameDefault);

// title
export const jsonldTitle = writable(jsonldTitleDefault);

// description
export const jsonldDescription = writable(jsonldDescriptionDefault);

// keywords
export const jsonldKeywords = writable(jsonldKeywordsDefault);

// image
export const jsonldImage = writable(jsonldImageDefault);

// url
export const jsonldUrl = writable(jsonldUrlDefault);

// page
export const jsonldPage = writable(jsonldPageDefault);

// author name
export const jsonldAuthorName = writable(jsonldAuthorNameDefault);

// author url
export const jsonldAuthorUrl = writable(jsonldAuthorUrlDefault);

// publisher name
export const jsonldPublisherName = writable(jsonldPublisherNameDefault);

// publisher url
export const jsonldPublisherUrl = writable(jsonldPublisherUrlDefault);

// performer name
export const jsonldPerformerName = writable(jsonldPerformerNameDefault);

// performer url
export const jsonldPerformerUrl = writable(jsonldPerformerUrlDefault);

// organizer name
export const jsonldOrganizerName = writable(jsonldOrganizerNameDefault);

// organizer url
export const jsonldOrganizerUrl = writable(jsonldOrganizerUrlDefault);

// job title
export const jsonldJobTitle = writable(jsonldJobTitleDefault);

// job company
export const jsonldJobCompany = writable(jsonldJobCompanyDefault);

// email
export const jsonldEmail = writable(jsonldEmailDefault);

// twitter
export const jsonldTwitter = writable(jsonldTwitterDefault);

// facebook
export const jsonldFacebook = writable(jsonldFacebookDefault);

// linkedin
export const jsonldLinkedin = writable(jsonldLinkedinDefault);

// instagram
export const jsonldInstagram = writable(jsonldInstagramDefault);

// youtube
export const jsonldYouTube = writable(jsonldYouTubeDefault);

// github
export const jsonldGitHub = writable(jsonldGitHubDefault);

// telephone
export const jsonldTelephone = writable(jsonldTelephoneDefault);

// latitude
export const jsonldLatitude = writable(jsonldLatitudeDefault);

// longitude
export const jsonldLongitude = writable(jsonldLongitudeDefault);

// currency value
export const jsonldCurrencyValue = writable(jsonldCurrencyValueDefault);

// currency
export const jsonldCurrency = writable(jsonldCurrencyDefault);

// business function
export const jsonldBusinessFunction = writable(jsonldBusinessFunctionDefault);

// accepted payment methods
export const jsonldAcceptedPaymentMethods = writable(jsonldAcceptedPaymentMethodsDefault);

// ingredients
export const jsonldIngredient = writable(jsonldIngredientDefault);

// receipe yield
export const jsonldRecipeYield = writable(jsonldRecipeYieldDefault);

// receipe category
export const jsonldRecipeCategory = writable(jsonldRecipeCategoryDefault);

// recipe cuisine
export const jsonldRecipeCuisine = writable(jsonldRecipeCuisineDefault);

// instructions
export const jsonldInstructions = writable(jsonldInstructionsDefault);

// start time
export const jsonldStartTime = writable(jsonldStartTimeDefault);

// end time
export const jsonldEndTime = writable(jsonldEndTimeDefault);

// address street
export const jsonldAddressStreet = writable(jsonldAddressStreetDefault);

// address locality
export const jsonldAddressLocality = writable(jsonldAddressLocalityDefault);

// address code
export const jsonldAddressPostalCode = writable(jsonldAddressPostalCodeDefault);

// address region
export const jsonldAddressRegion = writable(jsonldAddressRegionDefault);

// address country
export const jsonldAddressCountry = writable(jsonldAddressCountryDefault);

// brand name
export const jsonldBrandName = writable(jsonldBrandNameDefault);

// sku
export const jsonldSku = writable(jsonldSkuDefault);

// review rating value
export const jsonldReviewName = writable(jsonldReviewNameDefault);

// review rating value
export const jsonldReviewBody = writable(jsonldReviewBodyDefault);

// review author
export const jsonldReviewAuthor = writable(jsonldReviewAuthorDefault);

// review publisher
export const jsonldReviewPublisher = writable(jsonldReviewPublisherDefault);

// review rating value
export const jsonldReviewRatingValue = writable(jsonldReviewRatingValueDefault);

// review best rating
export const jsonldReviewBestRating = writable(jsonldReviewBestRatingDefault);

// aggregate rating value
export const jsonldAggregateRatingValue = writable(jsonldAggregateRatingValueDefault);

// aggregate rating best rating
export const jsonldAggregateRatingBestRating = writable(jsonldAggregateRatingBestRatingDefault);

// aggregate rating count
export const jsonldAggregateRatingCount = writable(jsonldAggregateRatingCountDefault);
