<script>
	import { jsonldInstructions } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldInstructions } from '../../stores/jsonld/UpdateJsonldInstructions';

	let jsonldInstructionsValue;

	jsonldInstructions.subscribe((value) => {
		jsonldInstructionsValue = value;
	});

	const onJsonldInstructionsChange = (event) => updateJsonldInstructions(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldInstructions">Instructions:</label>
	<textarea
		class="proteus-input"
		type="text"
		name="jsonldInstructions"
		value={jsonldInstructionsValue}
		on:input={onJsonldInstructionsChange}
	/>
</div>
