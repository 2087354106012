<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import LoaderAlt from './LoaderAlt.svelte';
	import { authIsLoggedIn, authPlan } from './stores/auth/AuthStore';

	let isBusy = false;
	let authIsLoggedInValue;
	let authPlanValue;

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});

	authPlan.subscribe((value) => {
		authPlanValue = value;
	});

	const onSubmit = async () => {
		if (!authIsLoggedInValue) {
			window.location.href = '/?page=login';
			return;
		}
		isBusy = true;
		try {
			const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
			const response = await axios.post('/api/subscription', { otpId, username: userId, otp });
			window.location.href = response.data.url;
		} catch (err) {
			console.log('onSubmit - error - ', err);
		}
		isBusy = false;
	};
</script>

<button on:click={onSubmit} class="primary-btn">
	{#if isBusy}
		<LoaderAlt />
	{/if}
	{#if !isBusy}
		upgrade starting at €{(authPlanValue?.unit_amount / 100).toFixed(2)} / month
	{/if}
</button>
