<script>
	import { v4 } from 'uuid';

	import {
		stripeAll,
		stripeId,
		stripeSecretKey,
		stripePriceId,
		stripeTitle,
		stripeUrl,
		stripeIsIDealEnabled,
		stripeBgColor,
		stripeFgColor,
		stripeRadius,
	} from '../../stores/stripe/StripeStore';

	import { updateToolbarStripe } from '../../stores/toolbar/UpdateToolbarStripe';
	import { resetStripe } from '../../stores/stripe/ResetStripe';
	import { updateStripeAll } from '../../stores/stripe/updateStripeAll';

	let stripeAllValue;
	let stripeIdValue;
	let stripeSecretKeyValue;
	let stripePriceIdValue;
	let stripeTitleValue;
	let stripeUrlValue;
	let stripeIsIDealEnabledValue;
	let stripeBgColorValue;
	let stripeFgColorValue;
	let stripeRadiusValue;

	const clearStyles = (ele) => {
		ele.style.removeProperty('background-color');
		ele.style.removeProperty('border-radius');
	};

	const updateStripeStyles = async (ele) => {
		console.log({ stripeRadiusValue, stripeBgColorValue, stripeFgColorValue });
		if (!ele) {
			return;
		}

		// clear existing styles
		clearStyles(ele);

		// border radius
		if (stripeRadiusValue !== undefined) {
			ele.style.borderRadius = `${stripeRadiusValue}px`;
		}

		// background color
		if (stripeBgColorValue) {
			ele.style.backgroundColor = stripeBgColorValue;
		}

		// foreground color
		if (stripeFgColorValue) {
			ele.style.color = stripeFgColorValue;
		}
	};

	const updateButtonData = (id) => {
		let buttons = [...stripeAllValue];
		const index = buttons.findIndex((item) => item.id === id);
		const newBtn = {
			id,
			secretKey: stripeSecretKeyValue,
			priceId: stripePriceIdValue,
			title: stripeTitleValue,
			url: stripeUrlValue,
			isIdealEnabled: stripeIsIDealEnabledValue,
			isIdealEnabled: stripeIsIDealEnabledValue,
			bgColor: stripeBgColorValue,
			fgColor: stripeFgColorValue,
			radius: stripeRadiusValue,
		};
		if (index === -1) {
			buttons.push(newBtn);
		} else {
			buttons[index] = newBtn;
		}
		updateStripeAll(buttons);
	};

	const getElementParent = (id) => {
		let eleParent = document.getElementById(id);
		if (!eleParent) {
			eleParent = document.createElement('p');
			eleParent.setAttribute('id', id);
			eleParent.setAttribute('data-checkout', id);
			eleParent.style.textAlign = 'center';
			tinymce.activeEditor.execCommand('mceInsertContent', false, eleParent.outerHTML);
			document.activeElement.blur();
		}
		eleParent = document.getElementById(id);
		return eleParent;
	};

	const getElementButton = (eleParent) => {
		const button = document.createElement('a');
		button.setAttribute('contenteditable', false);
		button.setAttribute('data-id', eleParent.id);
		button.setAttribute('title', stripeTitleValue);
		button.setAttribute('href', stripeUrlValue);
		button.setAttribute('target', '_blank');
		button.innerHTML = stripeTitleValue;
		button.classList.add('order-btn');
		eleParent.innerHTML = '';
		eleParent.appendChild(button);
		return button;
	};

	const onClick = () => {
		const id = stripeIdValue || v4();
		const eleParent = getElementParent(id);
		const eleButton = getElementButton(eleParent);
		updateButtonData(id);
		updateStripeStyles(eleButton);
		resetStripe();
		updateToolbarStripe(false);
	};

	stripeAll.subscribe((value) => {
		stripeAllValue = value;
	});

	stripeId.subscribe((value) => {
		stripeIdValue = value;
	});

	stripeSecretKey.subscribe((value) => {
		stripeSecretKeyValue = value;
	});

	stripePriceId.subscribe((value) => {
		stripePriceIdValue = value;
	});

	stripeIsIDealEnabled.subscribe((value) => {
		stripeIsIDealEnabledValue = value;
	});

	stripeTitle.subscribe((value) => {
		stripeTitleValue = value;
	});

	stripeUrl.subscribe((value) => {
		stripeUrlValue = value;
	});

	stripeBgColor.subscribe((value) => {
		stripeBgColorValue = value;
	});

	stripeFgColor.subscribe((value) => {
		stripeFgColorValue = value;
	});

	stripeRadius.subscribe((value) => {
		stripeRadiusValue = value;
	});
</script>

<div class="proteus-control proteus-group">
	<button class="proteus-button is-primary" on:click={onClick}>Submit</button>
</div>
