<script>
	import ColorPicker from '../../ColorPicker.svelte';

	import { headerBgGradient2 } from '../../stores/header-bg/HeaderBgStore';
	import { updateHeaderBgGradient2 } from '../../stores/header-bg/UpdateHeaderBgGradient2';
	import { resetHeaderBgGradient2 } from '../../stores/header-bg/ResetHeaderBgGradient2';

	let headerBgGradient2Value;

	headerBgGradient2.subscribe((value) => {
		headerBgGradient2Value = value;
	});

	const onHeaderBgGradient2Change = (event) => updateHeaderBgGradient2(event.detail);

	const onHeaderBgGradient2Reset = () => resetHeaderBgGradient2();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Color 2:
		<span class="proteus-reset" on:click={onHeaderBgGradient2Reset}>Reset</span>
	</label>
	<ColorPicker value={headerBgGradient2Value} on:input={onHeaderBgGradient2Change} />
</div>
