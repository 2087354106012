<script>
	import classNames from 'classnames';
	import ToolbarStripeButton from './ToolbarStripeButton.svelte';
	import ToolbarStripeSubmit from './ToolbarStripeSubmit.svelte';
	import ToolbarStripeClear from './ToolbarStripeClear.svelte';

	import { isToolbarStripeActive } from '../../stores/toolbar/ToolbarStore';
	import ToolbarStripeSecretKey from './ToolbarStripeSecretKey.svelte';
	import ToolbarStripePriceId from './ToolbarStripePriceId.svelte';
	import ToolbarStripeIsIdealActive from './ToolbarStripeIsIdealActive.svelte';
	import ToolbarStripeTitle from './ToolbarStripeTitle.svelte';
	import ToolbarStripeUrl from './ToolbarStripeUrl.svelte';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';
	import ToolbarStripeRadius from './ToolbarStripeRadius.svelte';
	import ToolbarStripeBgColor from './ToolbarStripeBgColor.svelte';
	import ToolbarStripeFgColor from './ToolbarStripeFgColor.svelte';
	import ToolbarStripePreview from './ToolbarStripePreview.svelte';
	import SubscribeNotice from '../../SubscribeNotice.svelte';
	import SubscribeButton from '../../SubscribeButton.svelte';
	import { authSubscription } from '../../stores/auth/AuthStore';

	let isTrial;
	let authSubscriptionValue;
	let isToolbarStripeActiveValue;

	authSubscription.subscribe((value) => {
		authSubscriptionValue = value;
		isTrial = value?.status !== 'active';
	});

	isToolbarStripeActive.subscribe((value) => {
		isToolbarStripeActiveValue = value;
	});
</script>

<div class={classNames('proteus-modal', { 'is-active': isToolbarStripeActiveValue })}>
	<ToolbarOverlay />
	<div class="proteus-modal-dialog">
		<ToolbarStripeTitle />
		<ToolbarStripeUrl />
		<ToolbarStripeRadius />
		<ToolbarStripeBgColor />
		<ToolbarStripeFgColor />
		<ToolbarStripePreview />
		<!-- <ToolbarStripeSecretKey />
		<ToolbarStripePriceId />
		<ToolbarStripeTitle />
		<ToolbarStripeIsIdealActive /> -->
		{#if !isTrial}
			<div class="proteus-control proteus-group proteus-toolbar-dropdown-footer">
				<ToolbarStripeClear />
				<ToolbarStripeSubmit />
			</div>
		{/if}
		{#if isTrial}
			<SubscribeNotice title="Stripe digital downloads" />
			<SubscribeButton />
		{/if}
	</div>
</div>
