<script>
	import { bodyBgWidth } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyBgWidth } from '../../stores/body-bg/UpdateBodyBgWidth';
	import { resetBodyBgWidth } from '../../stores/body-bg/ResetBodyBgWidth';

	let bodyBgWidthValue;

	bodyBgWidth.subscribe((value) => {
		bodyBgWidthValue = value;
	});

	const onBodyBgWidthChange = (event) => updateBodyBgWidth(event.target.value);

	const onBodyBgWidthReset = () => resetBodyBgWidth();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Pattern Width: {bodyBgWidthValue}rem
		<span class="proteus-reset" on:click={onBodyBgWidthReset}>Reset</span>
	</label>
	<input type="range" min={1} step={1} max={10} name="width" value={bodyBgWidthValue} on:input={onBodyBgWidthChange} />
</div>
