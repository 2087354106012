<svg id="ICON" viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
        <style>.cls-1{fill:none;stroke:#5c6bc0;stroke-linecap:round;stroke-linejoin:round;stroke-width:16px;}</style>
    </defs>
    <title/>
    <circle class="cls-1" cx="256" cy="192.66" r="20.66"/>
    <circle class="cls-1" cx="256" cy="256" r="20.66"/>
    <circle class="cls-1" cx="256" cy="319.34" r="20.66"/>
    <circle class="cls-1" cx="256" cy="256" r="192"/>
</svg>