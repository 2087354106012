<script>
	import { imageTitle } from '../../stores/image/ImageStore';
	import { updateImageTitle } from '../../stores/image/UpdateImageTitle';

	let imageTitleValue;

	imageTitle.subscribe((value) => {
		imageTitleValue = value;
	});

	const onImageTitleChange = (event) => updateImageTitle(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">Title:</label>
	<input class="proteus-input" type="text" name="imageTitle" value={imageTitleValue} on:input={onImageTitleChange} />
</div>
