<script>
	import { jsonldPublisherName } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldPublisherName } from '../../stores/jsonld/UpdateJsonldPublisherName';

	let jsonldPublisherNameValue;

	jsonldPublisherName.subscribe((value) => {
		jsonldPublisherNameValue = value;
	});

	const onJsonldPublisherNameChange = (event) => updateJsonldPublisherName(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldPublisherName">Publisher Name:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldPublisherName"
		value={jsonldPublisherNameValue}
		on:input={onJsonldPublisherNameChange}
	/>
</div>
