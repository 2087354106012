<script>
	import isMobilePhone from 'validator/lib/isMobilePhone';
	import LoaderFullscreen from './LoaderFullscreen.svelte';

	export let onChange;
	export let onSubmit;

	let form;
	let isBusy = false;
	let value = '+ ';

	// msg and type - can be either error or success
	let msg;
	let msgType;

	const onValueChange = (e) => {
		const newVal = e.target.value.replace(/[^\d]/g, '').trim();
		onChange(newVal);
		if (newVal.length === 0) {
			value = '+ ';
			return;
		}
		value = `+ ${newVal}`;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log('form submitted');
		if (isBusy) {
			return;
		}
		const phone = value.replace(/[^\d\+]/gi, '');
		const isPhoneValid = isMobilePhone(phone, '', { strictMode: true });
		console.log({ value, phone, isPhoneValid });
		if (!isPhoneValid) {
			msg = 'Invalid mobile number';
			msgType = 'error';
			isBusy = false;
			return;
		}
		msg = undefined;
		isBusy = true;
		await onSubmit(phone);
		isBusy = false;
	};
</script>

<form on:submit|preventDefault={handleSubmit} bind:this={form} method="POST">
	<p class="proteus-phone">
		<input type="tel" class="proteus-input proteus-phone-input" bind:value on:input={onValueChange} maxlength={15} placeholder="+19876543210" />
	</p>
	<p class="is-flex">
		<span style={`color: ${msgType === 'error' ? 'red' : 'green'}`}>{msg || ''}</span>
		<span class="login-submit" on:click={onSubmit}>OK</span>
	</p>
</form>

<style>
	form {
		display: block;
		width: 100%;
	}
	.is-flex {
		display: flex;
		justify-content: space-between;
	}
</style>
