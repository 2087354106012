<script>
	import { jsonldType } from '../../stores/jsonld/JsonldStore';

	import ToolbarJsonldArticleType from './ToolbarJsonldArticleType.svelte';
	import ToolbarJsonldTitle from './ToolbarJsonldTitle.svelte';
	import ToolbarJsonldDescription from './ToolbarJsonldDescription.svelte';
	import ToolbarJsonldAuthorName from './ToolbarJsonldAuthorName.svelte';
	import ToolbarJsonldAuthorUrl from './ToolbarJsonldAuthorUrl.svelte';
	import ToolbarJsonldPublisherName from './ToolbarJsonldPublisherName.svelte';
	import ToolbarJsonldPublisherUrl from './ToolbarJsonldPublisherUrl.svelte';
	import ToolbarJsonldReviewName from './ToolbarJsonldReviewName.svelte';
	import ToolbarJsonldReviewBody from './ToolbarJsonldReviewBody.svelte';
	import ToolbarJsonldReviewAuthor from './ToolbarJsonldReviewAuthor.svelte';
	import ToolbarJsonldReviewPublisher from './ToolbarJsonldReviewPublisher.svelte';
	import ToolbarJsonldReviewBestRating from './ToolbarJsonldReviewBestRating.svelte';
	import ToolbarJsonldReviewRatingValue from './ToolbarJsonldReviewRatingValue.svelte';
	import ToolbarJsonldAggregateRatingValue from './ToolbarJsonldAggregateRatingValue.svelte';
	import ToolbarJsonldAggregateRatingBestRating from './ToolbarJsonldAggregateRatingBestRating.svelte';
	import ToolbarJsonldAggregateRatingCount from './ToolbarJsonldAggregateRatingCount.svelte';

	let jsonldTypeValue;

	jsonldType.subscribe((value) => {
		jsonldTypeValue = value;
	});
</script>

{#if jsonldTypeValue === 'article'}
	<ToolbarJsonldArticleType />
	<ToolbarJsonldTitle />
	<ToolbarJsonldDescription />
	<ToolbarJsonldAuthorName />
	<ToolbarJsonldAuthorUrl />
	<ToolbarJsonldPublisherName />
	<ToolbarJsonldPublisherUrl />
	<ToolbarJsonldReviewName />
	<ToolbarJsonldReviewBody />
	<ToolbarJsonldReviewAuthor />
	<ToolbarJsonldReviewPublisher />
	<ToolbarJsonldReviewRatingValue />
	<ToolbarJsonldReviewBestRating />
	<ToolbarJsonldAggregateRatingValue />
	<ToolbarJsonldAggregateRatingBestRating />
	<ToolbarJsonldAggregateRatingCount />
{/if}
