<script>
	import axios from 'axios';

	import localStorage from 'localforage';
	import { onMount } from 'svelte';
	import { resetAuthStore } from '../stores/auth/ResetAuthStore';

	onMount(async () => {
		const { id, userId } = (await localStorage.getItem('otp')) || {};
		await localStorage.removeItem('otp');
		await axios.post('/api/logout', { id, username: userId });
		resetAuthStore();
		window.location = '/';
	});
</script>
