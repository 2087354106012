<script>
	import IconRemove from '../../icons/IconRemove.svelte';
	import { getIkResponseData, getImageAsBase64, getSelectedImage, uploadToImageKit } from '../../utils';

	import { headerLogoStart, headerLogoStartBase64 } from '../../stores/header-logo/HeaderLogoStore';
	import { updateHeaderLogoStart } from '../../stores/header-logo/UpdateHeaderLogoStart';
	import { updateHeaderLogoStartBase64 } from '../../stores/header-logo/UpdateHeaderLogoStartBase64';

	let headerLogoStartValue;
	let headerLogoStartBase64Value;
	let file;
	let isBusy = false;

	headerLogoStart.subscribe((value) => {
		headerLogoStartValue = value;
	});

	headerLogoStartBase64.subscribe((value) => {
		headerLogoStartBase64Value = value;
	});

	const onHeaderLogoStartClear = () => {
		updateHeaderLogoStart();
		updateHeaderLogoStartBase64();
	};

	const onImageChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}
		try {
			const base64 = await getImageAsBase64(file);
			updateHeaderLogoStartBase64(base64);
			const ikResponse = await uploadToImageKit(file, file.name);
			updateHeaderLogoStart(getIkResponseData(file, ikResponse));
			updateHeaderLogoStartBase64();
		} catch (err) {
			console.log('Header center logo change error - ', err);
			return;
		}
	};
</script>

<div class="proteus-control proteus-group">
	<label for="header-logo-start-file" class="proteus-file-zone">
		<span class="proteus-file-zone-name">
			<!-- {file ? file.name : 'Select Left Logo'} -->
			{file || headerLogoStartValue?.upload?.url || headerLogoStartBase64Value
				? 'Change Left Logo'
				: 'Select Left Logo'}
		</span>
		<input
			type="file"
			on:input={onImageChange}
			name="header-logo-start"
			id="header-logo-start-file"
			disabled={isBusy}
			class="proteus-file-zone-file"
		/>
		{#if file || headerLogoStartValue?.upload?.url || headerLogoStartBase64Value}
			<span on:click|preventDefault={onHeaderLogoStartClear} class="proteus-file-zone-remove">
				<IconRemove />
			</span>
		{/if}
	</label>
</div>
