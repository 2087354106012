<script>
	import { headerBgGradientAngle } from '../../stores/header-bg/HeaderBgStore';
	import { updateHeaderBgGradientAngle } from '../../stores/header-bg/UpdateHeaderBgGradientAngle';
	import { resetHeaderBgGradientAngle } from '../../stores/header-bg/ResetHeaderBgGradientAngle';

	let headerBgGradientAngleValue;

	headerBgGradientAngle.subscribe((value) => {
		headerBgGradientAngleValue = value;
	});

	const onHeaderBgGradientAngleChange = (event) => updateHeaderBgGradientAngle(event.target.value);

	const onHeaderBgGradientAngleReset = () => resetHeaderBgGradientAngle();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Gradient Angle: {headerBgGradientAngleValue}deg
		<span class="proteus-reset" on:click={onHeaderBgGradientAngleReset}>Reset</span>
	</label>
	<input
		type="range"
		min={0}
		step={1}
		max={360}
		name="angle"
		value={headerBgGradientAngleValue}
		on:input={onHeaderBgGradientAngleChange}
	/>
</div>
