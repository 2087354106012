<script>
	import IconCopy from './icons/IconCopy.svelte';

	export let url;
	export let text;

	let isCopied = false;

	const onCopy = (e) => {
		e.preventDefault();
		navigator.clipboard.writeText(text);
		isCopied = true;
		setTimeout(() => {
			isCopied = false;
		}, 5000);
	};
</script>

<a href={url} title="Copy Download Link" class="proteus-icon is-clickable" style={`color: ${isCopied ? 'var(--theme-primary)' : 'var(--theme-grey)'}`} target="_blank" on:click={onCopy}>
	{#if isCopied}
		<span>Copied</span>
	{/if}
	<IconCopy />
</a>

<style>
	a {
		position: relative;
	}
	
	span {
		position: absolute;
		right: 1.5rem;
		color: green;
		font-weight: bold;
		font-size: 0.5rem;
		text-align: center;
		text-transform: uppercase;
	}
</style>
