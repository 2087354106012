<script>
	import IconSave from '../../icons/IconPublish.svelte';

	import { isToolbarSaveActive } from '../../stores/toolbar/ToolbarStore';
	import { updateToolbarSave } from '../../stores/toolbar/UpdateToolbarSave';

	let isToolbarSaveActiveValue;

	isToolbarSaveActive.subscribe((value) => {
		isToolbarSaveActiveValue = value;
	});

	const onToggle = () => {
		updateToolbarSave(!isToolbarSaveActiveValue);
	};
</script>

<div class="proteus-toolbar-item is-full">
	<button class="proteus-toolbar-button is-save" on:click={onToggle} title="Save">
		Save
	</button>
</div>
