<script>
	import classNames from 'classnames';

	import { isToolbarHeaderActive } from '../../stores/toolbar/ToolbarStore';

	import ToolbarHeaderStyler from './ToolbarHeaderStyler.svelte';
	import ToolbarHeaderButton from './ToolbarHeaderButton.svelte';
	import ToolbarHeaderLogoStart from './ToolbarHeaderLogoStart.svelte';
	import ToolbarHeaderLogoCenter from './ToolbarHeaderLogoCenter.svelte';
	import ToolbarHeaderBgColor1 from './ToolbarHeaderBgColor1.svelte';
	import ToolbarHeaderBgColor2 from './ToolbarHeaderBgColor2.svelte';
	import ToolbarHeaderBgAngle from './ToolbarHeaderBgAngle.svelte';
	import ToolbarHeaderBgPattern from './ToolbarHeaderBgPattern.svelte';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';

	let isToolbarHeaderActiveValue;

	isToolbarHeaderActive.subscribe((value) => {
		isToolbarHeaderActiveValue = value;
	});
</script>

<ToolbarHeaderStyler />
<div class={classNames('proteus-modal', { 'is-active': isToolbarHeaderActiveValue })}>
	<ToolbarOverlay />
	<div class="proteus-modal-dialog">
		<ToolbarHeaderLogoStart />
		<ToolbarHeaderLogoCenter />
		<ToolbarHeaderBgColor1 />
		<ToolbarHeaderBgColor2 />
		<ToolbarHeaderBgAngle />
		<ToolbarHeaderBgPattern />
	</div>
</div>
