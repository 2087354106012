<script>
	import { stripeUrl } from '../../stores/stripe/StripeStore';
	import { updateStripeUrl } from '../../stores/stripe/UpdateStripeUrl';

	let stripeUrlValue;

	stripeUrl.subscribe((value) => {
		stripeUrlValue = value;
	});

	const onStripeUrlChange = (event) => updateStripeUrl(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">Stripe Payment Link:</label>
	<input class="proteus-input" type="text" name="stripeUrl" value={stripeUrlValue} on:input={onStripeUrlChange} />
</div>
