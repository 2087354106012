<script>
	import { jsonldPerformerName } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldPerformerName } from '../../stores/jsonld/UpdateJsonldPerformerName';

	let jsonldPerformerNameValue;

	jsonldPerformerName.subscribe((value) => {
		jsonldPerformerNameValue = value;
	});

	const onJsonldPerformerNameChange = (event) => updateJsonldPerformerName(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldPerformerName">Performer Name:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldPerformerName"
		value={jsonldPerformerNameValue}
		on:input={onJsonldPerformerNameChange}
	/>
</div>
