// is busy
export const authIsBusyDefault = false;

// is logged in
export const authIsLoggedInDefault = undefined;

// username
export const authUsernameDefault = '';

// avatar id
export const authAvatarIdDefault = '';

// avatar url
export const authAvatarUrlDefault = '';

// avatar thumb url
export const authAvatarThumbUrlDefault = '';

// first name
export const authFirstNameDefault = '';

// last name
export const authLastNameDefault = '';

// address
export const authAddressDefault = '';

// subscription
export const authSubscriptionDefault = undefined;

// plan
export const authPlanDefault = undefined;
