<script>
	import { onMount } from 'svelte';
	import classNames from 'classnames';
	import localStorage from 'localforage';
	import { sortBy, trimEnd } from 'lodash';
	import IconNext from './icons/IconNext.svelte';
	import IconPrev from './icons/IconPrev.svelte';
	import IconMenu from './icons/IconMenu.svelte';

	let userId;
	let sites = [];
	let activeIndex;
	let isBusy = false;
	let isActive = false;

	const getUserId = async () => {
		const otpData = (await localStorage.getItem('otp')) || {};
		userId = otpData?.userId;
	};

	const getUserSites = async () => {
		if (!userId) {
			return;
		}
		try {
			const response = await fetch('/api/user-sites', {
				method: 'POST',
				body: JSON.stringify({ userId }),
			});
			sites = await response.json();
			sites = sortBy(sites, 'createdAt').reverse();
		} catch (err) {
			console.log('Error fetching user sites: ' + err);
			sites = [];
		}
	};

	const getActiveIndex = async () => {
		const domain = await localStorage.getItem('domain');
		const subdomain = await localStorage.getItem('subdomain');
		let fulldomain = domain;
		if (subdomain) {
			fulldomain = `${domain}.${subdomain}`;
		}
		activeIndex = sites.findIndex((entry) => fulldomain === entry.id);
	};

	onMount(async () => {
		await getUserId();
		await getUserSites();
		await getActiveIndex();
	});

	/**
	 * @summary
	 * Save required information - this information is required to deploy
	 * any site and to keep the user logged in
	 */
	const saveRequiredData = async () => {
		const otp = await localStorage.getItem('otp');
		await localStorage.clear();
		await localStorage.setItem('otp', otp);
	};

	const onGotoSite = async (entry) => {
		await saveRequiredData();
		// both id and subdomain are same - that means that it is the root subdomain
		if (entry.id === entry.subdomain) {
			await localStorage.setItem('domain', entry.id);
		} else {
			const newDomain = entry.id.replace(new RegExp('.' + entry.subdomain + '$'), '');
			await localStorage.setItem('domain', newDomain);
			await localStorage.setItem('subdomain', entry.subdomain);
		}
		window.location = '/?page=editor';
	};

	const onClick = async (e, entry) => {
		e.preventDefault();
		isBusy = true;
		onGotoSite(entry);
	};

	const onNext = async () => {
		isBusy = true;
		const index = (activeIndex + 1) % sites.length;
		onGotoSite(sites[index]);
	};

	const onPrev = async () => {
		isBusy = true;
		const index = (activeIndex + sites.length - 1) % sites.length;
		onGotoSite(sites[index]);
	};

	const onMenuToggle = () => (isActive = !isActive);
</script>

{#if sites.length > 1}
	<nav id="page-nav" class={classNames('page-nav', { 'is-active': isActive })}>
		<button type="button" class="page-nav-btn" on:click|preventDefault={onMenuToggle}>
			<IconMenu />
		</button>
		<div class="page-nav-menu">
			<ul class="page-nav-menu-items">
				{#each sites as entry}
					<li on:click|preventDefault={(e) => onClick(e, entry)}>{entry.id}</li>
				{/each}
			</ul>
		</div>
		<span class="page-nav-next" on:click={onNext}>
			<IconNext />
		</span>
		<span class="page-nav-prev" on:click={onPrev}>
			<IconPrev />
		</span>
	</nav>
{/if}
