<script>
	import Select from 'svelte-select';
	import { sentenceCase } from 'change-case';
	import { seoOgIsActive, seoOgType } from '../../stores/seo/SeoStore';
	import { updateSeoOgType } from '../../stores/seo/UpdateSeoOgType';

	let seoOgIsActiveValue;
	let seoOgTypeValue;
	let selectedValue = {};

	const options = [
		'website',
		'article',
		'blog',
		'book',
		'game',
		'movie',
		'food',
		'city',
		'country',
		'actor',
		'author',
		'politician',
		'company',
		'hotel',
		'restaurant',
	].map((value) => ({ value, label: sentenceCase(value) }));

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
	});

	seoOgType.subscribe((value) => {
		seoOgTypeValue = value;
		selectedValue = options.find((item) => value === item.value);
	});

	const onSeoOgTypeChange = (event) => updateSeoOgType(event?.detail?.value || '');
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">OG Type:</label>
	<Select items={options} value={selectedValue} on:select={onSeoOgTypeChange} on:clear={onSeoOgTypeChange} />
</div>
