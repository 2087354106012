import { writable } from 'svelte/store';
import {
	menuActiveIndexDefault,
	menuItemsDefault,
	menuLinkTitleDefault,
	menuLinkUrlDefault,
} from './MenuStoreDefaults';

export const menuItems = writable(menuItemsDefault);

export const menuActiveIndex = writable(menuActiveIndexDefault);

export const menuLinkTitle = writable(menuLinkTitleDefault);

export const menuLinkUrl = writable(menuLinkUrlDefault);
