<script>
	import IconFontSizePlus from '../../icons/IconFontSizePlus.svelte';

	import { bodyLayoutWidth, bodyLayoutWidthMax } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyLayoutWidth } from '../../stores/body-bg/UpdateBodyLayoutWidth';

	let bodyLayoutWidthValue;
	let bodyLayoutWidthMaxValue;

	bodyLayoutWidth.subscribe((value) => {
		bodyLayoutWidthValue = value;
	});

	bodyLayoutWidthMax.subscribe((value) => {
		bodyLayoutWidthMaxValue = value;
	});

	const onClick = () => {
		if (bodyLayoutWidthValue < bodyLayoutWidthMaxValue) {
			updateBodyLayoutWidth(bodyLayoutWidthValue + 1);
		}
	};
</script>

<button class="proteus-toolbar-button" on:click={onClick} disabled={bodyLayoutWidthValue >= bodyLayoutWidthMaxValue}>
	<IconFontSizePlus />
</button>
