<script>
	import { jsonldAddressRegion } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAddressRegion } from '../../stores/jsonld/UpdateJsonldAddressRegion';

	let jsonldAddressRegionValue;

	jsonldAddressRegion.subscribe((value) => {
		jsonldAddressRegionValue = value;
	});

	const onJsonldAddressRegionChange = (event) => updateJsonldAddressRegion(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAddressRegion">Address Region:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAddressRegion"
		value={jsonldAddressRegionValue}
		on:input={onJsonldAddressRegionChange}
	/>
</div>
