<div class="tab-list">
	<slot></slot>
</div>

<style>
	.tab-list {
		display: flex;
		box-shadow: inset 0 -2px #dae2e8;
	}
</style>
