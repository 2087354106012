<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import Header from '../Header.svelte';
	import LoaderFullscreen from '../LoaderFullscreen.svelte';
	import MobileNumber from '../MobileNumber.svelte';
	import Otp from '../Otp.svelte';
	import { updateAuthIsLoggedIn } from '../stores/auth/UpdateAuthIsLoggedIn';
	import { getErrorMessage } from '../utils';

	let isOtpSent = false;
	let mobileNumber = '';
	let msg = '';
	let msgType = '';
	let isBusy = false;

	const onOtpSent = (mobile) => {
		isOtpSent = mobile ? true : false;
		mobileNumber = mobile;
	};

	const onOtpResent = () => {
		isOtpSent = false;
	};

	const onRequestOtp = async (value) => {
		let response;
		try {
			response = await axios.post('/api/login', { username: value });
			localStorage.setItem('otp', response.data);
			msg = 'code sent';
			msgType = 'success';
			onOtpSent(`+${value}`);
		} catch (err) {
			console.log('Error requesting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			onOtpSent(false);
		}
	};

	const onSubmit = async (value) => {
		msg = undefined;
		isBusy = true;
		let response;
		try {
			const { id, userId } = await localStorage.getItem('otp');
			response = await axios.post('/api/login', { id, username: userId, otp: value });
			const data = response.data;
			await localStorage.clear();
			await localStorage.setItem('otp', data);
			await localStorage.setItem('domain', data.domain);
			updateAuthIsLoggedIn(true);
			window.location = '/?page=editor';
		} catch (err) {
			console.log('Error submitting OTP', err);
			msg = getErrorMessage(err);
			msgType = 'error';
			isBusy = false;
		}
	};
</script>

<Header />

<section class="proteus-section">
	<div class="proteus-container">
		<div class="proteus-login-card">
			<div class="proteus-login">
				<h2 class="proteus-login-title">simple log in</h2>
				<p class="proteus-login-info">We send a secure code to your mobile number whenever you want to log in</p>
				<p class="proteus-login-subtitle">
					<strong>Mobile Number</strong>
				</p>
				<MobileNumber {onRequestOtp} {onOtpSent} {msg} {msgType} />
				<p>
					<strong> log in </strong>
					by entering the code we send you
				</p>
				<Otp {onSubmit} {msg} {msgType} />
			</div>
		</div>
	</div>
</section>

{#if isBusy}
	<LoaderFullscreen />
{/if}