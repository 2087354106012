<script>
	import ColorPicker from '../../ColorPicker.svelte';

	import { bodyBgGradient1 } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyBgGradient1 } from '../../stores/body-bg/UpdateBodyBgGradient1';
	import { resetBodyBgGradient1 } from '../../stores/body-bg/ResetBodyBgGradient1';

	let bodyBgGradient1Value;

	bodyBgGradient1.subscribe((value) => {
		bodyBgGradient1Value = value;
	});

	const onBodyBgGradient1Change = (event) => updateBodyBgGradient1(event.detail);

	const onBodyBgGradient1Reset = () => resetBodyBgGradient1();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Color 1:
		<span class="proteus-reset" on:click={onBodyBgGradient1Reset}>Reset</span>
	</label>
	<ColorPicker value={bodyBgGradient1Value} on:input={onBodyBgGradient1Change} />
</div>
