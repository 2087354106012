<script>
	import { jsonldAddressLocality } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAddressLocality } from '../../stores/jsonld/UpdateJsonldAddressLocality';

	let jsonldAddressLocalityValue;

	jsonldAddressLocality.subscribe((value) => {
		jsonldAddressLocalityValue = value;
	});

	const onJsonldAddressLocalityChange = (event) => updateJsonldAddressLocality(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAddressLocality">Address Locality:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAddressLocality"
		value={jsonldAddressLocalityValue}
		on:input={onJsonldAddressLocalityChange}
	/>
</div>
