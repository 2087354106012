<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import { onMount } from 'svelte';
	import Logo from '../Logo.svelte';

	let subscription = null;

	onMount(() => {
		document.body.classList.add('has-reset');
		getSubscription();
	});

	const getSubscription = async () => {
		try {
			const txnId = new URLSearchParams(location.search).get('txnId');
			const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
			const response = await axios.post('/api/subscription', { otpId, username: userId, otp, method: 'GET', id: txnId });
			subscription = response.data;
		} catch (err) {
			console.log('onSubscribe - error - ', err);
		}
	};
</script>

<main>
	<!-- <img
		src="https://ik.imagekit.io/ymjlyrr8zyb/4t4ioGraphic_tclojBlDF.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1658391406819/tr:w-auto-600,pr-true"
		sizes="(max-width: 600px) 50vw, 100vw"
		width="180"
		height="75"
		alt=" 4t4"
		title="4t4"
	/> -->

	<div style="width: 400px; margin: auto;">
		<Logo />
	</div>

	<h2 style="text-align: center; padding-top: 0">thank you for your payment</h2>

	<div class="insert">
		<img
			src="https://ik.imagekit.io/ymjlyrr8zyb/pezq-r-hearts_hOpUAvJg9.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1659824490016"
			width="400"
			height="256"
			title="pezqi-left"
			alt="pezqi-left"
		/>
	</div>

	<div style="font-size: 1rem; text-align: center; font-weight: 400; padding: 0 1rem; line-height: 1.5">
		You will be billed €9 each month
		<br />
		You can manage your subscription at any time from your 4t4 Stripe account page
	</div>

	{#if subscription?.latest_invoice?.hosted_invoice_url}
		<div style="margin: 2rem auto;">
			<a href={subscription?.latest_invoice?.hosted_invoice_url} class="primary-btn"> Download Invoice </a>
		</div>
	{/if}

	<div style="margin: 2rem auto; text-align: center;">
		<a href="/"> Return to 4t4 </a>
	</div>

	<footer>
		<a href="https://twitter.com/4t4io?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @4t4io</a>
		<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
	</footer>
</main>

<style>
	.insert {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		padding: 1vh 0;
		background-color: transparent;
	}

	.insert > img {
		height: 3rem;
	}

	footer {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		align-items: center;
		gap: 1rem;
		padding: 1vh 0;
		/* background-color: hsla(0, 0%, 97%, 1); */
	}
</style>
