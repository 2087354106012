<script>
	import IconRemove from '../../icons/IconRemove.svelte';
	import { getIkResponseData, getImageAsBase64, getSelectedImage, uploadToImageKit } from '../../utils';

	import { headerLogoCenter, headerLogoCenterBase64 } from '../../stores/header-logo/HeaderLogoStore';
	import { updateHeaderLogoCenter } from '../../stores/header-logo/UpdateHeaderLogoCenter';
	import { updateHeaderLogoCenterBase64 } from '../../stores/header-logo/UpdateHeaderLogoCenterBase64';

	let headerLogoCenterValue;
	let headerLogoCenterBase64Value;
	let file;
	let isBusy = false;

	headerLogoCenter.subscribe((value) => {
		headerLogoCenterValue = value;
	});

	headerLogoCenterBase64.subscribe((value) => {
		headerLogoCenterBase64Value = value;
	});

	const onHeaderLogoCenterClear = () => {
		updateHeaderLogoCenter();
		updateHeaderLogoCenterBase64();
	};

	const onImageChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}
		try {
			const base64 = await getImageAsBase64(file);
			updateHeaderLogoCenterBase64(base64);
			const ikResponse = await uploadToImageKit(file, file.name);
			updateHeaderLogoCenter(getIkResponseData(file, ikResponse));
			updateHeaderLogoCenterBase64();
		} catch (err) {
			console.log('Header left logo change error - ', err);
			return;
		}
	};
</script>

<div class="proteus-control proteus-group">
	<label for="header-center-start-file" class="proteus-file-zone">
		<span class="proteus-file-zone-name">
			<!-- {file && ! ? file.name : 'Select Center Logo'} -->
			{file || headerLogoCenterValue?.upload?.url || headerLogoCenterBase64Value
				? 'Change Center Logo'
				: 'Select Center Logo'}
		</span>
		<input
			type="file"
			on:input={onImageChange}
			name="header-center-start"
			id="header-center-start-file"
			disabled={isBusy}
			class="proteus-file-zone-file"
		/>
		{#if file || headerLogoCenterValue?.upload?.url || headerLogoCenterBase64Value}
			<span on:click|preventDefault={onHeaderLogoCenterClear} class="proteus-file-zone-remove">
				<IconRemove />
			</span>
		{/if}
	</label>
</div>
