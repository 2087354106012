<script>
	import { jsonldAggregateRatingCount } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAggregateRatingCount } from '../../stores/jsonld/UpdateJsonldAggregateRatingCount';

	let jsonldAggregateRatingCountValue;

	jsonldAggregateRatingCount.subscribe((value) => {
		jsonldAggregateRatingCountValue = value;
	});

	const onJsonldAggregateRatingCountChange = (event) => updateJsonldAggregateRatingCount(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAggregateRatingCount">Aggregate Rating Count:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAggregateRatingCount"
		value={jsonldAggregateRatingCountValue}
		on:input={onJsonldAggregateRatingCountChange}
	/>
</div>
