import { writable } from 'svelte/store';
import {
	headerBgTypeDefault,
	headerBgColorDefault,
	headerBgGradientCodeDefault,
	headerBgGradientAngleDefault,
	headerBgGradient1Default,
	headerBgGradient2Default,
	headerBgWidthDefault,
	headerBgHeightDefault,
	headerBgPositionDefault,
	headerBgSizeDefault,
	headerBgRepeatDefault,
	headerBgBase64Default,
	headerBgPatternDefault,
} from './HeaderBgStoreDefaults';

// background type
export const headerBgType = writable(headerBgTypeDefault);

// color code
export const headerBgColor = writable(headerBgColorDefault);

// gradient code
export const headerBgGradientCode = writable(headerBgGradientCodeDefault);

// custom gradient
export const headerBgGradientAngle = writable(headerBgGradientAngleDefault);
export const headerBgGradient1 = writable(headerBgGradient1Default);
export const headerBgGradient2 = writable(headerBgGradient2Default);

// background
export const headerBgSize = writable(headerBgSizeDefault);
export const headerBgWidth = writable(headerBgWidthDefault);
export const headerBgHeight = writable(headerBgHeightDefault);
export const headerBgPosition = writable(headerBgPositionDefault);
export const headerBgRepeat = writable(headerBgRepeatDefault);

// background pattern
export const headerBgBase64 = writable(headerBgBase64Default);
export const headerBgPattern = writable(headerBgPatternDefault);
