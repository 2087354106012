<script>
	import { jsonldAggregateRatingValue } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAggregateRatingValue } from '../../stores/jsonld/UpdateJsonldAggregateRatingValue';

	let jsonldAggregateRatingValueValue;

	jsonldAggregateRatingValue.subscribe((value) => {
		jsonldAggregateRatingValueValue = value;
	});

	const onJsonldAggregateRatingValueChange = (event) => updateJsonldAggregateRatingValue(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAggregateRatingValue">Aggregate Rating Value:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAggregateRatingValue"
		value={jsonldAggregateRatingValueValue}
		on:input={onJsonldAggregateRatingValueChange}
	/>
</div>
