<script>
	import { createEventDispatcher } from 'svelte'
	
	export let label;
	export let checked = false;

	const dispatch = createEventDispatcher();

	const onChange = () => dispatch('input', checked);
</script>

<div class="swtich-card">
	<span class="label">{label}</span>
	<label class="switch">
		<input type="checkbox" bind:checked on:change={onChange} />
		<span class="slider" />
	</label>
</div>

<style>
	.swtich-card {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.switch {
		position: relative;
		width: 2rem;
		height: 1.2rem;
		margin: 0;
		backface-visibility: hidden;
		transform: translateZ(0);
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.label {
		/* font-size: 0.7em; */
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 34px;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 1rem;
		width: 1rem;
		left: 0.1rem;
		bottom: 0.1rem;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	input:checked + .slider {
		background-color: var(--theme-primary);
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(0.8rem);
		transform: translateX(0.8rem);
	}
</style>
