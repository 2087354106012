<script>
	import IconRemove from '../../icons/IconRemove.svelte';

	import {
		getIkResponseData,
		getImageAsBase64,
		getSelectedImage,
		removeEleFromDom,
		uploadToImageKit,
	} from '../../utils';

	import { imageId, imageBase64, imageUpload, imageIsBusy } from '../../stores/image/ImageStore';
	import { resetImage } from '../../stores/image/ResetImage';
	import { updateImageBase64 } from '../../stores/image/UpdateImageBase64';
	import { updateImageUpload } from '../../stores/image/UpdateImageUpload';
	import { updateImageIsBusy } from '../../stores/image/UpdateImageIsBusy';

	let file;
	let imageIsBusyValue;
	let imageIdValue;
	let imageBase64Value;
	let imageUploadValue;

	imageIsBusy.subscribe((value) => {
		imageIsBusyValue = value;
	});

	imageId.subscribe((value) => {
		imageIdValue = value;
	});

	imageBase64.subscribe((value) => {
		imageBase64Value = value;
	});

	imageUpload.subscribe((value) => {
		imageUploadValue = value;
	});

	const onClear = () => {
		removeEleFromDom(imageIdValue);
		resetImage();
	};

	const onImageChange = async (e) => {
		updateImageIsBusy(true);
		file = getSelectedImage(e);
		if (!file) {
			updateImageIsBusy(false);
			return;
		}
		try {
			const base64 = await getImageAsBase64(file);
			updateImageBase64(base64);
			const ikResponse = await uploadToImageKit(file, file.name);
			if (imageIdValue) {
				updateImageUpload(getIkResponseData(file, ikResponse));
			}
			updateImageBase64();
		} catch (err) {
			console.log('Image change error - ', err);
		}
		updateImageIsBusy(false);
	};
</script>

<div class="proteus-control proteus-group">
	<label for="image-file" class="proteus-file-zone">
		<span class="proteus-file-zone-name">
			{imageUploadValue?.upload?.url || imageBase64Value ? 'Change Image' : 'Select Image'}
		</span>
		<input
			type="file"
			on:input={onImageChange}
			name="image-file"
			id="image-file"
			disabled={imageIsBusyValue}
			class="proteus-file-zone-file"
		/>
		{#if imageUploadValue?.upload?.url || imageBase64Value}
			<span on:click|preventDefault={onClear} class="proteus-file-zone-remove">
				<IconRemove />
			</span>
		{/if}
	</label>
</div>
