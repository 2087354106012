<script>
	import ToolbarJsonldType from './ToolbarJsonldType.svelte';
	import ToolbarJsonldArticle from './ToolbarJsonldArticle.svelte';
	import ToolbarJsonldPerson from './ToolbarJsonldPerson.svelte';
	import ToolbarJsonldOrganization from './ToolbarJsonldOrganization.svelte';
	import ToolbarJsonldEvent from './ToolbarJsonldEvent.svelte';
	import ToolbarJsonldPlace from './ToolbarJsonldPlace.svelte';
	import ToolbarJsonldProduct from './ToolbarJsonldProduct.svelte';
	import ToolbarJsonldRecipe from './ToolbarJsonldRecipe.svelte';
</script>

<div class="jsonld-controls">
	<ToolbarJsonldType />
	<ToolbarJsonldArticle />
	<ToolbarJsonldPerson />
	<ToolbarJsonldOrganization />
	<ToolbarJsonldEvent />
	<ToolbarJsonldPlace />
	<ToolbarJsonldProduct />
	<ToolbarJsonldRecipe />
</div>

<style>
	.jsonld-controls {
		min-height: 20rem;
	}
</style>
