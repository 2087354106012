<script>
	import { jsonldReviewAuthor } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldReviewAuthor } from '../../stores/jsonld/UpdateJsonldReviewAuthor';

	let jsonldReviewAuthorValue;

	jsonldReviewAuthor.subscribe((value) => {
		jsonldReviewAuthorValue = value;
	});

	const onJsonldReviewAuthorChange = (event) => updateJsonldReviewAuthor(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldReviewAuthor">Review Author:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldReviewAuthor"
		value={jsonldReviewAuthorValue}
		on:input={onJsonldReviewAuthorChange}
	/>
</div>
