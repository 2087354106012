// is oembed store busy
export const oembedIsBusyDefault = false;

/**
 * @type oembed
 * @param {string} url the oembed url
 * @param {object} data the oembed data
 */
export const oembedAllDefault = [];

// oembed id
export const oembedIdDefault = '';

// oembed url
export const oembedUrlDefault = '';

// oembed data
export const oembedDataDefault = {};
