<script>
	import { publishDescription } from '../../stores/publish/PublishStore';
	import { updatePublishDescription } from '../../stores/publish/UpdatePublishDescription';

	let publishDescriptionValue;

	publishDescription.subscribe((value) => {
		publishDescriptionValue = value;
	});

	const onPublishDescriptionChange = (event) => updatePublishDescription(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">Description:</label>
	<input
		class="proteus-input"
		type="text"
		name="seoDescription"
		value={publishDescriptionValue}
		on:input={onPublishDescriptionChange}
	/>
</div>
