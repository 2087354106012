<script>
	import classNames from 'classnames';
	import ToolbarImageStyler from './ToolbarImageStyler.svelte';
	import ToolbarImageButton from './ToolbarImageButton.svelte';

	import { isToolbarImageActive } from '../../stores/toolbar/ToolbarStore';
	import ToolbarImageAlt from './ToolbarImageAlt.svelte';
	import ToolbarImageTitle from './ToolbarImageTitle.svelte';
	import ToolbarImageFile from './ToolbarImageFile.svelte';
	import ToolbarImageSubmit from './ToolbarImageSubmit.svelte';
	import ToolbarImageClear from './ToolbarImageClear.svelte';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';

	let isToolbarImageActiveValue;

	isToolbarImageActive.subscribe((value) => {
		isToolbarImageActiveValue = value;
	});
</script>

<ToolbarImageStyler />
<div class={classNames('proteus-modal', { 'is-active': isToolbarImageActiveValue })}>
	<ToolbarOverlay />
	<div class="proteus-modal-dialog">
		<ToolbarImageFile />
		<ToolbarImageAlt />
		<ToolbarImageTitle />
		<div class="proteus-control proteus-group proteus-toolbar-dropdown-footer">
			<ToolbarImageClear />
			<ToolbarImageSubmit />
		</div>
	</div>
</div>
