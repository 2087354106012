// background type
export const bodyBgTypeDefault = '';

// color code
export const bodyBgColorDefault = 'hsla(180, 50, 50, 1)';

// gradient code
export const bodyBgGradientCodeDefault = '';

// custom gradient
export const bodyBgGradientAngleDefault = 180;
export const bodyBgGradient1Default = 'hsla(180, 50, 50, 1)';
export const bodyBgGradient2Default = 'hsla(180, 50, 50, 1)';

// background
export const bodyBgSizeDefault = '';
export const bodyBgSizeIsActiveDefault = false;
export const bodyBgWidthDefault = 1;
export const bodyBgHeightDefault = 0;
export const bodyBgPositionDefault = '';
export const bodyBgRepeatDefault = '';

// background pattern / uploded image
export const bodyBgBase64Default = null;
export const bodyBgPatternDefault = {};

// layout width
export const bodyLayoutWidthDefault = 32;
export const bodyLayoutWidthMinDefault = 32;
export const bodyLayoutWidthMaxDefault = 55;
