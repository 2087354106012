<script>
	import { seoOgTwitterSite } from '../../stores/seo/SeoStore';
	import { updateSeoOgTwitterSite } from '../../stores/seo/UpdateSeoOgTwitterSite';

	let seoOgTwitterSiteValue;

	seoOgTwitterSite.subscribe((value) => {
		seoOgTwitterSiteValue = value;
	});

	const onSeoOgCreatorChange = (event) => updateSeoOgTwitterSite(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">OG Twitter Site:</label>
	<input class="proteus-input" type="text" name="seoOgCreator" value={seoOgTwitterSiteValue} on:input={onSeoOgCreatorChange} />
</div>
