<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import ConfirmDialog from '../../ConfirmDialog.svelte';
	import IconNew from '../../icons/IconNew.svelte';
	import LoaderFullscreen from '../../LoaderFullscreen.svelte';
	import { publishDomain, publishPayload } from '../../stores/publish/PublishStore';

	import { getNotificationsContext } from 'svelte-notifications';
	const { addNotification } = getNotificationsContext();

	let publishDomainValue;
	let publishPayloadValue;
	let isActive = false;
	let isBusy = false;

	/**
	 * @summary
	 * Get site information
	 * This is used to check if the site was published or not
	 */
	const getSite = async () => {
		try {
			const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
			const subdomain = await localStorage.getItem('subdomain');
			const response = await axios.post('/api/subdomain', {
				otpId,
				userId,
				otp,
				subdomain,
				domain: publishDomainValue,
				isCheckOnly: true,
			});
			return response;
		} catch (err) {
			console.log('publishSiteChanges - error: ' + err);
			return;
		}
	};

	/**
	 * @summary
	 * Publish site changes
	 *
	 * @todo
	 * this may need to be updated whenever the publish
	 * method is updated in  ToolbarSaveButton
	 */
	const publishSiteChanges = async () => {
		try {
			const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
			const subdomain = await localStorage.getItem('subdomain');
			const response = await axios.post('/api/publish', {
				...publishPayloadValue,
				otpId,
				userId,
				otp,
				subSubdomain: subdomain,
			});
			console.log('publishSiteChanges - success - ' + response);
			return true;
		} catch (err) {
			console.log('publishSiteChanges - error: ' + err);
			return false;
		}
	};

	/**
	 * @summary
	 * Save required information - this information is required to deploy
	 * any site and to keep the user logged in
	 */
	const saveRequiredData = async () => {
		const activeDomain = publishDomainValue;
		const otp = await localStorage.getItem('otp');
		const subdomain = (await localStorage.getItem('subdomain')) || activeDomain;

		await localStorage.clear();
		await localStorage.setItem('subdomain', subdomain);
		await localStorage.setItem('otp', otp);
	};

	const onConfirm = () => (isActive = true);

	const onCancel = async () => {
		isActive = false;
		isBusy = true;
		await saveRequiredData();
		window.location = window.location.origin + '/?page=editor';
		isBusy = false;
	};

	const onClose = () => (isActive = false);

	/**
	 * @summary
	 * backup required information
	 * clear local storage
	 * save/restore root domain information
	 * save/restore otp
	 * reload current window
	 */
	const onSubmit = async () => {
		isBusy = true;
		isActive = false;
		const isPublished = await getSite();
		if (isPublished) {
			try {
				await publishSiteChanges();
			} catch (err) {
				log.error('onSubmit - error: ' + err);
				addNotification({
					text: 'Could not save your changes. Please try again later. If you still have any issues, please contact the administrator.',
					position: 'top-right',
					type: 'danger',
					removeAfter: 5000,
				});
				isBusy = false;
				return;
			}
		}
		await saveRequiredData();
		window.location = window.location.origin + '/?page=editor';
		isBusy = false;
	};

	publishDomain.subscribe((value) => {
		publishDomainValue = value;
	});

	publishPayload.subscribe((value) => {
		publishPayloadValue = value;
	});
</script>

<button class="proteus-toolbar-button is-new" title="New Page" on:click={onConfirm}>
	New page
</button>

<ConfirmDialog
	{isActive}
	confirmTitle="Create new page"
	confirmMsg="Would you like to save your changes before continuing?"
	{onSubmit}
	{onCancel}
	{onClose}
/>

{#if isBusy}
	<LoaderFullscreen position="fixed" />
{/if}
