<script>
	import axios from 'axios';
	import { debounce } from 'lodash';

	let form;
	let isAvailable = null;
	let value = 'myname (no spaces)';
	let isCleared = false;

	export let phone;
	export let onChange;
	export let onSubmit;

	const onValueChange = (e) => {
		const newValue = e.target.value.replace(/[^A-Za-z0-9-]+/g, '');
		value = newValue;
		onChange(newValue, false);
	};

	const onFocus = (e) => {
		if (isCleared) {
			return;
		}
		value = '';
		isCleared = true;
	};

	const onInput = debounce(async () => {
		if (value.trim().length === 0 || !isCleared) {
			isAvailable = null;
			return;
		}
		isAvailable = null;
		try {
			await axios.post('/api/check-domain', { domain: value, username: phone });
			isAvailable = true;
			onChange(value, true);
		} catch (err) {
			console.log({ err });
			isAvailable = false;
			onChange(value, false);
		}
	}, 500);

	const handleSubmit = () => {
		onSubmit();
	};

	$: domainChanged(value);
	const domainChanged = (newValue) => {
		console.log(newValue);
		onInput();
	};

	$: phoneChanged(phone);
	const phoneChanged = (newValue) => {
		console.log('Phone has changed - verifying domain');
		onInput();
	};
</script>

<form on:submit|preventDefault={handleSubmit} bind:this={form} method="POST">
	<p class="domain-checker-input">
		<input type="text" class="proteus-input" bind:value on:input={onValueChange} on:focus={onFocus} maxlength={20} />
	</p>
	<h3 style="font-weight: bold; margin-bottom: 1rem;">
		{isCleared ? value : 'myname'}.4t4.io
		{#if isAvailable === true}
			<span style="color: forestgreen;">✔︎</span>
		{/if}
		{#if isAvailable === false}
			<span style="color: red;">❌</span>
		{/if}
	</h3>
</form>

<style>
	form {
		display: block;
		width: 100%;
	}
</style>
