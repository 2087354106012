<script>
	import { stripeTitle } from '../../stores/stripe/StripeStore';
	import { updateStripeTitle } from '../../stores/stripe/UpdateStripeTitle';

	let stripeTitleValue;

	stripeTitle.subscribe((value) => {
		stripeTitleValue = value;
	});

	const onStripeTitleChange = (event) => updateStripeTitle(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">Stripe Button Title:</label>
	<input class="proteus-input" type="text" name="stripeTitle" value={stripeTitleValue} on:input={onStripeTitleChange} />
</div>
