<script>
	import { jsonldPublisherUrl } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldPublisherUrl } from '../../stores/jsonld/UpdateJsonldPublisherUrl';

	let jsonldPublisherUrlValue;

	jsonldPublisherUrl.subscribe((value) => {
		jsonldPublisherUrlValue = value;
	});

	const onJsonldPublisherUrlChange = (event) => updateJsonldPublisherUrl(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldPublisherUrl">Publisher Url:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldPublisherUrl"
		value={jsonldPublisherUrlValue}
		on:input={onJsonldPublisherUrlChange}
	/>
</div>
