<script>
	import localStorage from 'localforage';
	import { onMount } from 'svelte';
	import { publishDomain } from '../../stores/publish/PublishStore';
	import { updatePublishDomain } from '../../stores/publish/UpdatePublishDomain';

	let publishDomainValue;
	let url;
	let domain;
	let subdomain;

	onMount(async () => {
		domain = await localStorage.getItem('domain');
		subdomain = await localStorage.getItem('subdomain');
		if (!subdomain) {
			updatePublishDomain(domain);
		}
	});

	const updateUrl = async () => {
		if (subdomain) {
			url = `https://${publishDomainValue}.${subdomain}.4t4.io`;
			return;
		}
		url = `https://${publishDomainValue}.4t4.io`;
	};

	publishDomain.subscribe((value) => {
		publishDomainValue = value;
		updateUrl();
	});

	const onPublishDomainChange = (event) => {
		if (subdomain) {
			updatePublishDomain(event.target.value);
		} else {
			updatePublishDomain(domain);
		}
	}
</script>

{#if subdomain}
	<div class="proteus-control proteus-group protues-ui-input">
		<label class="proteus-label" for="publishDomain">Name:</label>
		<input
			class="proteus-input"
			type="text"
			name="publishDomain"
			value={publishDomainValue}
			on:input={onPublishDomainChange}
			placeholder="Enter page name"
			maxlength={20}
		/>
		<div class="proteus-final-url">
			{#if publishDomainValue.trim().length}
				{url}
			{/if}
			{#if !publishDomainValue.trim().length}
				Enter page name to view page url
			{/if}
		</div>
	</div>
{/if}
