<script>
	import ColorPicker from '../../ColorPicker.svelte';

	import { bodyTextColor } from '../../stores/body-text/BodyTextStore';
	import { updateBodyTextColor } from '../../stores/body-text/UpdateBodyTextColor';
	import { resetBodyTextColor } from '../../stores/body-text/ResetBodyTextColor';

	let bodyTextColorValue;

	bodyTextColor.subscribe((value) => {
		bodyTextColorValue = value;
	});

	const onBodyTextColorChange = (event) => updateBodyTextColor(event.detail);

	const onBodyTextColorReset = () => resetBodyTextColor();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Background Color:
		<span class="proteus-reset" on:click={onBodyTextColorReset}>Reset</span>
	</label>
	<ColorPicker value={bodyTextColorValue} on:input={onBodyTextColorChange} />
</div>
