<script>
	import { v4 } from 'uuid';
	import { editorInstance } from '../../stores/editor/EditorStore';
	import { imageAll, imageId, imageAlt, imageTitle, imageBase64, imageUpload, imageIsBusy } from '../../stores/image/ImageStore';
	import { updateImageId } from '../../stores/image/UpdateImageId';
	import { updateImageAll } from '../../stores/image/UpdateImageAll';
	import { resetImage } from '../../stores/image/ResetImage';
	import { isToolbarImageActive } from '../../stores/toolbar/ToolbarStore';

	let isToolbarImageActiveValue;
	let editorInstanceValue;

	let imageIsBusyValue;
	let imageAllValue;
	let imageIdValue;
	let imageAltValue;
	let imageTitleValue;
	let imageBase64Value;
	let imageUploadValue;

	const updateImageData = (id) => {
		let images = [...imageAllValue];
		const index = images.findIndex((item) => item.id === id);
		const newImg = { id, alt: imageAltValue, title: imageTitleValue, upload: imageUploadValue };
		if (index === -1) {
			images.push(newImg);
		} else {
			images[index] = newImg;
		}
		updateImageAll(images);
	};

	const getElementParent = (id) => {
		let eleParent = document.getElementById(id);
		if (!eleParent) {
			eleParent = document.createElement('p');
			eleParent.setAttribute('id', id);
			editorInstanceValue.execCommand('mceInsertContent', false, eleParent.outerHTML);
			document.activeElement.blur();
		}
		eleParent = document.getElementById(id);
		return eleParent;
	};

	const getElementImage = (eleParent) => {
		let eleImage = eleParent.querySelector('img');
		if (!eleImage) {
			eleImage = document.createElement('img');
			eleParent.appendChild(eleImage);
		}
		return eleImage;
	};

	const updateImageAttributes = (eleImage) => {
		eleImage.setAttribute('alt', imageAltValue);
		eleImage.setAttribute('title', imageTitleValue);
		if (imageBase64Value) {
			eleImage.setAttribute('src', imageBase64Value);
		}
		if (imageUploadValue?.upload?.url) {
			const bbox = eleImage.getBoundingClientRect();
			eleImage.removeAttribute('width');
			eleImage.removeAttribute('height');
			eleImage.setAttribute('src', imageUploadValue.upload.url  + '?tr:w-auto-600,pr-true');
			eleImage.setAttribute('width', imageUploadValue.upload.width || bbox.width || bbox.height);
			eleImage.setAttribute('height', imageUploadValue.upload.height || bbox.height);
			eleImage.setAttribute('sizes', '(max-width: 600px) 50vw, 100vw');
		}
	};

	const insertOrUpdate = () => {
		if (!editorInstanceValue) {
			return;
		}
		if (!imageBase64Value && !imageUploadValue) {
			return;
		}
		const id = imageIdValue || v4();
		const eleParent = getElementParent(id);
		const eleImage = getElementImage(eleParent);
		updateImageAttributes(eleImage);
		updateImageId(id);
		updateImageData(id);
	};

	editorInstance.subscribe((value) => {
		editorInstanceValue = value;
	});

	imageIsBusy.subscribe((value) => {
		imageIsBusyValue = value;
		if (!isToolbarImageActiveValue && !imageIsBusyValue) {
			resetImage();
		}
	});

	imageAll.subscribe((value) => {
		imageAllValue = value;
	});

	imageId.subscribe((value) => {
		imageIdValue = value;
	});

	imageAlt.subscribe((value) => {
		imageAltValue = value;
		insertOrUpdate();
	});

	imageTitle.subscribe((value) => {
		imageTitleValue = value;
		insertOrUpdate();
	});

	imageBase64.subscribe((value) => {
		imageBase64Value = value;
		insertOrUpdate();
	});

	imageUpload.subscribe((value) => {
		imageUploadValue = value;
		insertOrUpdate();
	});

	isToolbarImageActive.subscribe((value) => {
		isToolbarImageActiveValue = value;
		if (!value && !imageIsBusyValue) {
			resetImage();
		}
	});
</script>
