import {
	stripeSecretKey,
	stripePriceId,
	stripeIsIDealEnabled,
	stripeId,
	stripeTitle,
	stripeUrl,
	stripeRadius,
	stripeBgColor,
	stripeFgColor,
} from './StripeStore';
import {
	stripeSecretKeyDefault,
	stripePriceIdDefault,
	stripeIsIDealEnabledDefault,
	stripeIdDefault,
	stripeTitleDefault,
	stripeUrlDefault,
	stripeRadiusDefault,
	stripeBgColorDefault,
	stripeFgColorDefault,
} from './StripeStoreDefaults';

export const resetStripe = () => {
	stripeId.set(stripeIdDefault);
	stripeSecretKey.set(stripeSecretKeyDefault);
	stripePriceId.set(stripePriceIdDefault);
	stripeIsIDealEnabled.set(stripeIsIDealEnabledDefault);
	stripeTitle.set(stripeTitleDefault);
	stripeUrl.set(stripeUrlDefault);
	stripeRadius.set(stripeRadiusDefault);
	stripeBgColor.set(stripeBgColorDefault);
	stripeFgColor.set(stripeFgColorDefault);
};
