<script>
	import { jsonldDescription } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldDescription } from '../../stores/jsonld/UpdateJsonldDescription';

	let jsonldDescriptionValue;

	jsonldDescription.subscribe((value) => {
		jsonldDescriptionValue = value;
	});

	const onJsonldDescriptionChange = (event) => updateJsonldDescription(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldDescription">Description:</label>
	<textarea
		class="proteus-input"
		type="text"
		name="jsonldDescription"
		value={jsonldDescriptionValue}
		on:input={onJsonldDescriptionChange}
	/>
</div>
