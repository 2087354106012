<script>
	import { oembedId } from '../../stores/oembed/OembedStore';

	import { updateToolbarOembed } from '../../stores/toolbar/UpdateToolbarOembed';
	import { resetOembed } from '../../stores/oembed/ResetOembed';
	import { removeEleFromDom } from '../../utils';

	let oembedIdValue;

	oembedId.subscribe((value) => {
		oembedIdValue = value;
	});

	const onClear = () => {
		removeEleFromDom(oembedIdValue);
		resetOembed();
		updateToolbarOembed(false);
	};
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<button class="proteus-button is-default" on:click={onClear}>Clear</button>
</div>
