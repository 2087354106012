<script>
	import { jsonldEmail } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldEmail } from '../../stores/jsonld/UpdateJsonldEmail';

	let jsonldEmailValue;

	jsonldEmail.subscribe((value) => {
		jsonldEmailValue = value;
	});

	const onJsonldEmailChange = (event) => updateJsonldEmail(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldEmail">Email:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldEmail"
		value={jsonldEmailValue}
		on:input={onJsonldEmailChange}
	/>
</div>
