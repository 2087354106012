<script>
	import { publishTitle } from '../../stores/publish/PublishStore';
	import { updatePublishTitle } from '../../stores/publish/UpdatePublishTitle';

	let publishTitleValue;

	publishTitle.subscribe((value) => {
		publishTitleValue = value;
	});

	const onPublishTitleChange = (event) => updatePublishTitle(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="publishTitle">Title:</label>
	<input
		class="proteus-input"
		type="text"
		name="publishTitle"
		value={publishTitleValue}
		on:input={onPublishTitleChange}
		placeholder="Site title"
	/>
</div>
