import { writable } from 'svelte/store';
import {
	bodyBgTypeDefault,
	bodyBgColorDefault,
	bodyBgGradientCodeDefault,
	bodyBgGradientAngleDefault,
	bodyBgGradient1Default,
	bodyBgGradient2Default,
	bodyBgWidthDefault,
	bodyBgHeightDefault,
	bodyBgPositionDefault,
	bodyBgSizeDefault,
	bodyBgSizeIsActiveDefault,
	bodyBgRepeatDefault,
	bodyBgPatternDefault,
	bodyBgBase64Default,
	bodyLayoutWidthDefault,
	bodyLayoutWidthMaxDefault,
	bodyLayoutWidthMinDefault,
} from './BodyBgStoreDefaults';

// background type
export const bodyBgType = writable(bodyBgTypeDefault);

// color code
export const bodyBgColor = writable(bodyBgColorDefault);

// gradient code
export const bodyBgGradientCode = writable(bodyBgGradientCodeDefault);

// custom gradient
export const bodyBgGradientAngle = writable(bodyBgGradientAngleDefault);
export const bodyBgGradient1 = writable(bodyBgGradient1Default);
export const bodyBgGradient2 = writable(bodyBgGradient2Default);

// background
export const bodyBgSize = writable(bodyBgSizeDefault);
export const bodyBgSizeIsActive = writable(bodyBgSizeIsActiveDefault);
export const bodyBgWidth = writable(bodyBgWidthDefault);
export const bodyBgHeight = writable(bodyBgHeightDefault);
export const bodyBgPosition = writable(bodyBgPositionDefault);
export const bodyBgRepeat = writable(bodyBgRepeatDefault);

// background pattern
export const bodyBgBase64 = writable(bodyBgBase64Default);
export const bodyBgPattern = writable(bodyBgPatternDefault);

// layout width
export const bodyLayoutWidth = writable(bodyLayoutWidthDefault);
export const bodyLayoutWidthMin = writable(bodyLayoutWidthMinDefault);
export const bodyLayoutWidthMax = writable(bodyLayoutWidthMaxDefault);
