<script>
	import classNames from 'classnames';

	export let title;
	export let subtitle = null;
	export let icon;
	export let isIconOnly;
</script>

<div class="proteus-avatar">
	<div class={classNames('proteus-avatar-icon', { 'is-icon': isIconOnly })}>
		<slot name="icon">
			{icon}
		</slot>
	</div>
</div>

<h2 class="proteus-subtitle">{title}</h2>

{#if subtitle}
	<h5 class="proteus-subtitle">{subtitle}</h5>
{/if}

<style>
	h5 {
		opacity: 0.5;
		margin: 0;
		font-size: 0.8rem;
	}
</style>