<script>
	import { updateToolbarIsFullscreen } from '../stores/toolbar/UpdateToolbarIsFullscreen';
	import IconFullscreen from '../icons/IconFullscreen.svelte';

	const onClick = () => {
		document.body.classList.add('is-fullscreen');
		updateToolbarIsFullscreen(true);
	};
</script>

<button class="proteus-toolbar-button" title="Preview Mode" on:click={onClick}>
	<IconFullscreen />
</button>
