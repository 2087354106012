<svg viewBox="0 0 64 64">
	<g>
		<path
			d="M25.26,60A3.26,3.26,0,0,1,22,56.74V30a1,1,0,0,1,1-1H34a1,1,0,0,1,1,1v3H54a1,1,0,0,1,1,1v5a1,1,0,1,1-2,0V35H34a1,1,0,0,1-1-1V31H24V56.74A1.26,1.26,0,0,0,25.26,58a1,1,0,1,1,0,2Z"
		/>
		<path
			d="M59,60H25.26a1,1,0,0,1,0-2,1.25,1.25,0,0,0,1.25-1.26V39a1,1,0,0,1,1-1H59a1,1,0,0,1,1,1V59A1,1,0,0,1,59,60ZM28.26,58H58V40H28.51V56.74A3.29,3.29,0,0,1,28.26,58Z"
		/>
		<path
			d="M19,54a1,1,0,0,1-.69-.27,5.19,5.19,0,0,1-1.11-1.57L4.56,23.83a5,5,0,0,1,2.52-6.6l20.17-9A1,1,0,0,1,28.07,10l-20.17,9a3,3,0,0,0-1.52,4L19,51.32a3,3,0,0,0,.67.94A1,1,0,0,1,19,54Z"
		/>
		<path
			d="M55.13,31a.75.75,0,0,1-.26,0,1,1,0,0,1-.7-1.22L57.9,16.11a3,3,0,0,0-.29-2.28,2.94,2.94,0,0,0-1.82-1.4L32.65,6.08A3,3,0,0,0,29,8.2L24,26.26a1,1,0,0,1-1.93-.52L27,7.67a5,5,0,0,1,6.14-3.51L56.31,10.5a4.9,4.9,0,0,1,3,2.33,5,5,0,0,1,.49,3.8L56.09,30.26A1,1,0,0,1,55.13,31Z"
		/>
	</g>
</svg>
