<script>
	import { onMount } from 'svelte';
	import localStorage from 'localforage';
	import { sortBy } from 'lodash';
	import Header from '../Header.svelte';
	import IconUser from '../icons/IconUser.svelte';
	import IconDomain from '../icons/IconDomain.svelte';
	import IconWebPage from '../icons/IconWebPage.svelte';
	import PageItems from '../PageItems.svelte';
	import SectionTitle from '../SectionTitle.svelte';
	import ProfileSummary from '../ProfileSummary.svelte';
	import DomainItems from '../DomainItems.svelte';
	import AvatarForm from '../AvatarForm.svelte';
	import { authAvatarThumbUrl, authIsLoggedIn } from '../stores/auth/AuthStore';
	import LoaderFullscreen from '../LoaderFullscreen.svelte';
	import axios from 'axios';

	let userId;
	let user = {};
	let domains = [];
	let sites = [];
	let isBusy = false;

	let authAvatarThumbUrlValue;
	let authIsLoggedInValue;

	authAvatarThumbUrl.subscribe((value) => {
		authAvatarThumbUrlValue = value;
	});

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});

	const getUserId = async () => {
		const otpData = (await localStorage.getItem('otp')) || {};
		userId = otpData?.userId;
		user = otpData?.user || {};
	};

	const getUserDomains = async () => {
		if (!userId) {
			return;
		}
		try {
			const response = await fetch('/api/user-domains', {
				method: 'POST',
				body: JSON.stringify({ userId }),
			});
			domains = await response.json();
		} catch (err) {
			console.log('Error fetching user domains: ' + err);
			domains = [];
		}
	};

	const getUserSites = async () => {
		if (!userId) {
			return;
		}
		isBusy = true;
		try {
			const response = await fetch('/api/user-sites', {
				method: 'POST',
				body: JSON.stringify({ userId }),
			});
			sites = await response.json();
			sites = sortBy(sites, 'createdAt').reverse();
		} catch (err) {
			console.log('Error fetching user sites: ' + err);
			sites = [];
		}
		isBusy = false;
	};

	onMount(async () => {
		await getUserId();
		getUserDomains();
		getUserSites();
	});

	const onGotoStripeAccount = async () => {
		isBusy = true;
		try {
			const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
			const response = await axios.post('/api/subscription', { otpId, username: userId, otp, method: 'GET' });
			window.location.href = response.data.url;
		} catch (err) {
			console.log('onGotoStripeAccount - error - ', err);
		}
		isBusy = false;
	};

	const onPublishFirstPage = async () => {
		const { domain } = (await localStorage.getItem('otp')) || {};
		await localStorage.setItem('domain', domain);
		await localStorage.removeItem('subdomain');
		window.location.href = '/?page=editor';
	};
</script>

<Header />
<section class="proteus-section">
	<div class="proteus-container">
		<div class="proteus-block">
			<SectionTitle title={user.id} subtitle={`Total pages: ${sites.length}`}>
				<span slot="icon">
					{#if authAvatarThumbUrlValue}
						<img src={authAvatarThumbUrlValue} alt="" />
					{/if}
					{#if !authAvatarThumbUrlValue}
						<IconUser />
					{/if}
					{#if authIsLoggedInValue}
						<AvatarForm />
					{/if}
				</span>
			</SectionTitle>
			<!-- <ProfileSummary {user} {domains} {sites} /> -->
		</div>

		<!-- {#if domains.length > 0}
			<div class="proteus-block has-whitespace">
				<SectionTitle title="Your Domains" hasWhiteSpace={true} isIconOnly={true}>
					<span slot="icon">
						<IconDomain />
					</span>
				</SectionTitle>
				<DomainItems {domains} {sites} />
			</div>
		{/if} -->

		{#if sites.length === 0 && !isBusy}
			<div class="proteus-block">
				<a href="/?page=editor" on:click|preventDefault={onPublishFirstPage} class="primary-btn"> Publish your first page now </a>
			</div>
		{/if}

		<div class="proteus-block">
			<a href="#" class="primary-btn is-dark" on:click|preventDefault={onGotoStripeAccount}> Manage Subscription </a>
		</div>

		{#if sites.length > 0}
			<div class="proteus-block">
				<SectionTitle title="Your Pages" hasWhiteSpace={true} isIconOnly={true}>
					<span slot="icon">
						<IconWebPage />
					</span>
				</SectionTitle>
				<PageItems {sites} />
			</div>
		{/if}
	</div>
</section>

{#if isBusy}
	<LoaderFullscreen />
{/if}
