<script>
    import Logo from "./Logo.svelte";
    import Menu from "./Menu.svelte";
</script>

<header class="proteus-header">
    <div class="proteus-container proteus-header-container">
        <span class="proteus-header-logo">
            <Logo />
        </span>
        <Menu />
    </div>
</header>
