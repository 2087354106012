<script>
	import { jsonldTitle } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldTitle } from '../../stores/jsonld/UpdateJsonldTitle';

	let jsonldTitleValue;

	jsonldTitle.subscribe((value) => {
		jsonldTitleValue = value;
	});

	const onJsonldTitleChange = (event) => updateJsonldTitle(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldTitle">Title:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldTitle"
		value={jsonldTitleValue}
		on:input={onJsonldTitleChange}
	/>
</div>
