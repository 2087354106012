<script>
	import { stripeId, stripeTitle, stripeUrl, stripeRadius, stripeBgColor, stripeFgColor } from '../../stores/stripe/StripeStore';

	let stripeIdValue;
	let stripeTitleValue;
	let stripeUrlValue;
	let stripeRadiusValue;
	let stripeBgColorValue;
	let stripeFgColorValue;

	stripeId.subscribe((value) => {
		stripeIdValue = value;
	});

	stripeTitle.subscribe((value) => {
		stripeTitleValue = value;
	});

	stripeUrl.subscribe((value) => {
		stripeUrlValue = value;
	});

	stripeRadius.subscribe((value) => {
		stripeRadiusValue = value;
	});

	stripeBgColor.subscribe((value) => {
		stripeBgColorValue = value;
	});

	stripeFgColor.subscribe((value) => {
		stripeFgColorValue = value;
	});
</script>

<p data-checkout={stripeIdValue} style="margin: 0; text-align: center; pointer-events: none;">
	<a
		href={stripeUrlValue}
		class="order-btn"
		content-editable="false"
		style={`
			background-color: ${stripeBgColorValue};
			color: ${stripeFgColorValue || '#fff'};
			border-radius: ${stripeRadiusValue}px;
		`}
	>
		{stripeTitleValue || 'Live Preview'}
	</a>
</p>
