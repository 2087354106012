export const IMAGEKIT_ID = 'ymjlyrr8zyb';
export const IMAGEKIT_ENDPOINT = 'https://ik.imagekit.io/ymjlyrr8zyb';
export const IMAGEKIT_PUBLIC_KEY = 'public_aEaOaYimggRM+4kgwtcDb4PKl9Y=';

// export const IMAGEKIT_ID = 'iadva4w6splb';
// export const IMAGEKIT_ENDPOINT = 'https://ik.imagekit.io/iadva4w6splb';
// export const IMAGEKIT_PUBLIC_KEY = 'public_BnGmzngTpoElcIXeAOsPad7vRW4=';

export const STRIPE_CLIENT_PUBLIC = 'pk_test_51JRak4BVfczYJwOujq2QNEhc9mQYO8R0ik2XskmoPePqJGmhiMTtKNSQ9IfsOmmSTItVx4mKapB7iltQ3oO0QV5400VfPSgvKX';
export const STRIPE_CLIENT_SECRET = 'sk_test_51JRak4BVfczYJwOucV4tOVUnEvhra0Ayz0tBzxhYGuUlrSz8lrq2ShC0DIV8ZlXypo0flIgmmyYo5IfmsVTEwKfS00fkSIkqdb';
export const STRIPE_PRODUCT_ID = 'prod_Mwa6L3wIbBVEnI';
export const STRIPE_PRICE_ID = 'price_1MCgvvBVfczYJwOuPwn9EAl4';

// export const STRIPE_CLIENT_PUBLIC = 'pk_test_51H0Xp3HGaVMvYLBmOJjRitb9T1VsEmFtA53S9fdSOe85Jrn122Gf7bkMPistf1eQjtdG37qJvxUq0ExVwszQ1O8400fNxV2Jf3';
// export const STRIPE_CLIENT_SECRET = 'sk_test_51H0Xp3HGaVMvYLBmexlBUnWXLKzjJyPCzHVcB9nhSA24Z0pjpdgMrAXU072Tk5Vfbfrh3o18vVn6rCt90fOVt2cP00Rm40aHH0';
// export const STRIPE_PRODUCT_ID = 'prod_LcSvuph2nf4Pbw';
// export const STRIPE_PRICE_ID = 'price_1KvDzYHGaVMvYLBmCvpEjvdp';
