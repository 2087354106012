<script>
	import { jsonldTelephone } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldTelephone } from '../../stores/jsonld/UpdateJsonldTelephone';

	let jsonldTelephoneValue;

	jsonldTelephone.subscribe((value) => {
		jsonldTelephoneValue = value;
	});

	const onJsonldTelephoneChange = (event) => updateJsonldTelephone(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldTelephone">Telephone:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldTelephone"
		value={jsonldTelephoneValue}
		on:input={onJsonldTelephoneChange}
	/>
</div>
