<script>
	import { jsonldLongitude } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldLongitude } from '../../stores/jsonld/UpdateJsonldLongitude';

	let jsonldLongitudeValue;

	jsonldLongitude.subscribe((value) => {
		jsonldLongitudeValue = value;
	});

	const onJsonldLongitudeChange = (event) => updateJsonldLongitude(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldLongitude">Longitude:</label>
	<input class="proteus-input" type="text" name="jsonldLongitude" value={jsonldLongitudeValue} on:input={onJsonldLongitudeChange} />
</div>
