<script>
	import { onMount } from 'svelte';
	import axios from 'axios';
	import slugify from 'slugify';
	import { Buffer } from 'buffer/';
	import { v4 } from 'uuid';
	import ProgressBar from 'svelte-progress-bar';
	import { getNotificationsContext } from 'svelte-notifications';
	import Loader from './Loader.svelte';
	import { getOtpLoginData, notifyError, notifySuccess } from './utils';

	const { addNotification } = getNotificationsContext();

	import { authIsLoggedIn } from './stores/auth/AuthStore';
	import { uploadItems, uploadIsBusy, uploadError } from './stores/uploads/UploadStore';
	import { updateUploadItems } from './stores/uploads/UpdateUploadItems';
	import { updateUploadIsBusy } from './stores/uploads/UpdateUploadIsBusy';
	import { updateUploadError } from './stores/uploads/UpdateUploadError';

	let authIsLoggedInValue;
	let uploadItemsValue;
	let uploadIsBusyValue;
	let uploadErrorValue;
	let progress;
	let progressMinValue = 0.00001;

	/**
	 * Start the progress bar and set the minimum value to visually show the progress bar
	 */
	onMount(async () => {
		// progress.setWidthRatio(progressMinValue);
	});

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});

	uploadItems.subscribe((value) => {
		uploadItemsValue = value;
	});

	uploadIsBusy.subscribe((value) => {
		uploadIsBusyValue = value;
	});

	uploadError.subscribe((value) => {
		uploadErrorValue = value;
	});

	// max file upload size
	const maxSize = 25 * 1024 * 1024;

	// state
	let isBusy = false;

	/**
	 * @summary
	 * Get file data as ArrayBuffer
	 *
	 * @param {File} newFile
	 * @return {ArrayBuffer}
	 */
	const getBuffer = async (newFile) =>
		new Promise((resolve, reject) => {
			console.log(newFile);
			const reader = new FileReader();
			const onReadError = () => {
				notifyError(addNotification, null, 'Failed to read file');
				reject();
			};
			reader.onload = () => {
				if (reader.result instanceof ArrayBuffer) {
					return resolve(Buffer.from(reader.result));
				}
				return onReadError();
			};
			reader.onerror = () => onReadError;
			reader.onabort = () => onReadError;
			reader.readAsArrayBuffer(newFile);
		});

	/**
	 * @summary
	 * Get file upload signed url
	 *
	 * @param key
	 * @return {string} URL
	 */
	const getSignedUploadUrl = async (key, data, name) =>
		new Promise(async (resolve, reject) => {
			try {
				const otpLoginData = await getOtpLoginData();
				const response = await axios.post('/api/storage', {
					...otpLoginData,
					key,
					name,
					method: 'POST',
					size: data.length,
				});
				resolve(response.data);
			} catch (err) {
				notifyError(addNotification, err, 'Could not connect to cloud storage, please try again later.');
				console.log('getSignedUploadUrl - error - ', err);
				reject(err);
			}
		});

	/**
	 * @summary
	 * Use axios to upload the buffer data to the signed URL
	 *
	 * @see
	 * https://stackoverflow.com/questions/41088022/how-to-get-onuploadprogress-in-axios
	 *
	 * @param {string} url the target url
	 * @param {ArrayBuffer} data the input buffer data
	 */
	const uploadToSignedUrl = async (url, data) => {
		try {
			const response = await axios({
				method: 'put',
				url,
				data,
				headers: {
					'Content-Type': 'application/octet-stream',
				},
				onUploadProgress: (progressEvent) => {
					// @see https://stackoverflow.com/questions/41088022/how-to-get-onuploadprogress-in-axios
					progress.setWidthRatio(progressEvent.loaded / progressEvent.total);
				},
			});
			return response.data;
		} catch (err) {
			console.log('uploadToSignedUrl - error - ', err);
			notifyError(addNotification, null, 'Upload failed, please try again later.');
			throw err;
		}
	};

	const uploadToS3 = async (newFile) => {
		isBusy = true;
		progress.setWidthRatio(progressMinValue);
		try {
			const data = await getBuffer(newFile);
			const slug = slugify(newFile.name);
			const key = `${v4()}/${slug}`;
			const signedUrl = await getSignedUploadUrl(key, data, newFile.name);
			const { id, url } = signedUrl;
			await uploadToSignedUrl(url, data);
			const newUpload = await completeUpload(id);
			progress.setWidthRatio(1);
			updateUploadItems([newUpload, ...uploadItemsValue]);
			notifySuccess(addNotification, 'File uploaded successfully');
		} catch (err) {
			console.log('uploadToS3 - error - ', err);
		}
		isBusy = false;
	};

	/**
	 * @summary
	 * Complete the digital upload - mark upload as complete
	 *
	 * @description
	 * If the upload fails to complete - then clean up by removing the temporary
	 * file data and the uploaded file
	 *
	 * @param id {string} the upload id
	 * @return {string} URL
	 */
	const completeUpload = async (id) =>
		new Promise(async (resolve, reject) => {
			const otpLoginData = await getOtpLoginData();
			try {
				const response = await axios.post('/api/storage', {
					...otpLoginData,
					id,
					method: 'PUT',
				});
				resolve(response.data);
			} catch (err) {
				try {
					await axios.post('/api/storage', {
						...otpLoginData,
						id,
						method: 'DELETE',
					});
				} catch (err) {
					console.log('Error deleting upload - ', err);
				}
				notifyError(addNotification, err, 'Could not validate upload, please try again.');
				console.log('getSignedUploadUrl - error - ', err);
				reject(err);
			}
		});

	/**
	 * @summary
	 * Handle file input change event - upload to cloud storage
	 *
	 * @description
	 * Upload to Digital Ocean Spaces
	 *
	 * @param {ChangeEvent<HtmlFileInput>} e
	 */
	const onInput = (e) => {
		console.log(e);
		if (!e.target || !e.target.files || !e.target.files.length) {
			return;
		}
		const selectedFile = e.target.files[0];
		if (!selectedFile) {
			notifyError(addNotification, new Error('Please select a file to upload'));
			return;
		}
		if (!selectedFile || isNaN(selectedFile.size) === true || selectedFile.size > maxSize) {
			e.target.value = '';
			notifyError(addNotification, new Error(`Maximum allowed file size is ${bytesToSize(maxSize)}`));
		}
		uploadToS3(selectedFile);
	};
</script>

<label class="dropzone">
	<input name="file" type="file" on:input={onInput} disabled={isBusy} class={isBusy ? 'hidden' : ''} />
	{#if isBusy}
		<!-- <Loader /> -->
	{/if}
	<div class={isBusy ? 'progress' : ''}>
		<ProgressBar bind:this={progress} color="#0366d6" />
	</div>
</label>

<style>
	.dropzone {
		position: relative;
		flex: 1 1 0%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem;
		border-width: 2px;
		border-radius: 2px;
		border-color: rgb(119, 136, 153);
		border-style: dashed;
		background-color: rgb(250, 250, 250);
		color: rgb(119, 136, 153);
		cursor: pointer;
		outline: currentcolor none medium;
		transition: border 0.24s ease-in-out 0s;
	}
	.progress {
		width: 90%;
		margin: 1rem auto 0;
		background-color: #cde;
	}
</style>
