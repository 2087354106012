<script>
	import { v4 } from 'uuid';
	import { getOembedData } from '../../utils';
	import { editorInstance } from '../../stores/editor/EditorStore';
	import { isToolbarOembedActive } from '../../stores/toolbar/ToolbarStore';
	import { oembedAll, oembedId, oembedUrl, oembedData } from '../../stores/oembed/OembedStore';
	import { updateOembedId } from '../../stores/oembed/UpdateOembedId';
	import { updateOembedAll } from '../../stores/oembed/UpdateOembedAll';
	import { oembedDataDefault } from '../../stores/oembed/oembedStoreDefaults';
	import { updateOembedData } from '../../stores/oembed/UpdateOembedData';
	import { resetOembed } from '../../stores/oembed/ResetOembed';
	import { authSubscription } from '../../stores/auth/AuthStore';

	let isTrial;
	let authSubscriptionValue;
	let isToolbarOembedActiveValue;
	let editorInstanceValue;
	let oembedAllValue;
	let oembedIdValue;
	let oembedUrlValue;
	let oembedDataValue;

	const updateOembed = (id) => {
		let oembeds = [...oembedAllValue];
		const index = oembeds.findIndex((item) => item.id === id);
		const newOembed = {
			id,
			url: oembedUrlValue,
			data: oembedDataValue,
		};
		if (index === -1) {
			oembeds.push(newOembed);
		} else {
			oembeds[index] = newOembed;
		}
		console.log(oembeds);
		updateOembedAll(oembeds);
	};

	const getElementParent = (id) => {
		let eleParent = document.getElementById(id);
		if (!eleParent) {
			eleParent = document.createElement('p');
			eleParent.setAttribute('contenteditable', false);
			eleParent.setAttribute('id', id);
			eleParent.setAttribute('data-oembed', id);
			eleParent.setAttribute('data-url', oembedUrlValue);
			editorInstanceValue.execCommand('mceInsertContent', false, eleParent.outerHTML);
			document.activeElement.blur();
		}
		eleParent = document.getElementById(id);
		return eleParent;
	};

	const getElementOembed = (eleParent, oembedData) => {
		eleParent.innerHTML = oembedData?.html || '';
	};

	const insertOrUpdate = async () => {
		// editor has not yet been initialized
		if (!editorInstanceValue) {
			return;
		}
		const oembedData = await getOembedData(oembedUrlValue);
		// no oembed data found for the specified url
		if (!oembedData?.html) {
			updateOembedData(oembedDataDefault);
			return;
		}
		const id = oembedIdValue || v4();
		if (!isTrial) {
			const eleParent = getElementParent(id);
			const eleOembed = getElementOembed(eleParent, oembedData);
		}
		updateOembedId(id);
		updateOembedData(oembedData);
		updateOembed(id);
	};

	authSubscription.subscribe((value) => {
		authSubscriptionValue = value;
		isTrial = value?.status !== 'active';
	});

	editorInstance.subscribe((value) => {
		editorInstanceValue = value;
	});

	oembedAll.subscribe((value) => {
		oembedAllValue = value;
	});

	oembedId.subscribe((value) => {
		oembedIdValue = value;
	});

	oembedData.subscribe((value) => {
		oembedDataValue = value;
	});

	oembedUrl.subscribe((value) => {
		oembedUrlValue = value;
		insertOrUpdate();
	});

	isToolbarOembedActive.subscribe((value) => {
		isToolbarOembedActiveValue = value;
		if (!value) {
			resetOembed();
		}
	});
</script>
