<script>
	import ToolbarNew from './toolbar-new/ToolbarNew.svelte';
	import ToolbarRefresh from './toolbar-refresh/ToolbarRefresh.svelte';
	import ToolbarBody from './toolbar-body/ToolbarBody.svelte';
	import ToolbarHeader from './toolbar-header/ToolbarHeader.svelte';
	import ToolbarImage from './toolbar-image/ToolbarImage.svelte';
	import ToolbarSaveAs from './toolbar-save-as/ToolbarSaveAs.svelte';
	import ToolbarSave from './toolbar-save/ToolbarSave.svelte';
	import ToolbarStripe from './toolbar-stripe/ToolbarStripe.svelte';
	import ToolbarOverlay from './ToolbarOverlay.svelte';
	import ToolbarBodyLayoutWidthIncrease from './toolbar-body/ToolbarBodyLayoutWidthIncrease.svelte';
	import ToolbarBodyLayoutWidthDecrease from './toolbar-body/ToolbarBodyLayoutWidthDecrease.svelte';
	import ToolbarMenu from './toolbar-menu/ToolbarMenu.svelte';
	import ToolbarImageButton from './toolbar-image/ToolbarImageButton.svelte';
	import ToolbarStripeButton from './toolbar-stripe/ToolbarStripeButton.svelte';
	import ToolbarBodyButton from './toolbar-body/ToolbarBodyButton.svelte';
	import ToolbarHeaderButton from './toolbar-header/ToolbarHeaderButton.svelte';
	import ToolbarSaveButton from './toolbar-save/ToolbarSaveButton.svelte';
	import ToolbarFullscreen from './ToolbarFullscreen.svelte';
	import ToolbarUrl from './ToolbarUrl.svelte';
	import ToolbarAvatar from './ToolbarAvatar.svelte';
	import ToolbarSeoButton from './ToolbarSeo.svelte';
	import ToolbarSeo from './toolbar-seo/ToolbarSeo.svelte';
	import ToolbarOembedButton from './toolbar-oembed/ToolbarOembedButton.svelte';
	import ToolbarOembed from './toolbar-oembed/ToolbarOembed.svelte';
</script>

<div id="proteus-toolbar" class="proteus-toolbar">
	<div id="proteus-toolbar-start" class="proteus-toolbar-start">
		<!--  -->
	</div>
	<div id="proteus-toolbar-center" class="proteus-toolbar-center">
		<div class="proteus-toolbar-row-group">
			<ToolbarBodyLayoutWidthIncrease />
			<ToolbarBodyLayoutWidthDecrease />
		</div>
		<div class="proteus-toolbar-row-group">
			<div class="proteus-toolbar-item">
				<ToolbarImageButton />
			</div>
			<div class="proteus-toolbar-item">
				<ToolbarStripeButton />
			</div>
		</div>
		<div class="proteus-toolbar-row-group">
			<div class="proteus-toolbar-item">
				<ToolbarBodyButton />
			</div>
			<div class="proteus-toolbar-item">
				<ToolbarHeaderButton />
			</div>
		</div>
	</div>
	<div class="proteus-toolbar-row-group">
		<ToolbarNew />
		<ToolbarSaveButton />
	</div>
	<div id="proteus-toolbar-end" class="proteus-toolbar-end">
		<ToolbarUrl />
		<div class="proteus-toolbar-row-group">
			<div class="proteus-toolbar-item">
				<ToolbarOembedButton />
			</div>
			<div class="proteus-toolbar-item">
				<ToolbarSeoButton />
			</div>
		</div>
		<div class="proteus-toolbar-row-group">
			<div class="proteus-toolbar-item">
				<ToolbarAvatar />
			</div>
			<div class="proteus-toolbar-item">
				<ToolbarFullscreen />
			</div>
		</div>
	</div>
</div>

<ToolbarImage />
<ToolbarStripe />
<ToolbarBody />
<ToolbarHeader />
<ToolbarSeo />
<ToolbarOembed />
<ToolbarSave />
<ToolbarSave />
