<script>
	import { jsonldSku } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldSku } from '../../stores/jsonld/UpdateJsonldSku';

	let jsonldSkuValue;

	jsonldSku.subscribe((value) => {
		jsonldSkuValue = value;
	});

	const onJsonldSkuChange = (event) => updateJsonldSku(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldSku">SKU:</label>
	<input class="proteus-input" type="text" name="jsonldSku" value={jsonldSkuValue} on:input={onJsonldSkuChange} />
</div>
