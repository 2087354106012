<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import { trimEnd } from 'lodash';
	import { Confirm } from 'svelte-confirm';
	import IconEdit from './icons/IconEdit.svelte';
	import IconRemove from './icons/IconRemove.svelte';
	import LoaderFullscreen from './LoaderFullscreen.svelte';
	import { authIsLoggedIn } from './stores/auth/AuthStore';

	import { getNotificationsContext } from 'svelte-notifications';
	const { addNotification } = getNotificationsContext();

	export let sites = [];

	let authIsLoggedInValue;
	let isBusy = false;

	/**
	 * @summary
	 * Save required information - this information is required to deploy
	 * any site and to keep the user logged in
	 */
	const saveRequiredData = async () => {
		const otp = await localStorage.getItem('otp');
		await localStorage.clear();
		await localStorage.setItem('otp', otp);
	};

	const onEdit = async (e, entry) => {
		e.preventDefault();
		isBusy = true;
		await saveRequiredData();
		if (entry.id === entry.subdomain) {
			await localStorage.setItem('domain', entry.id);
		} else {
			const newDomain = entry.id.replace(new RegExp('.' + entry.subdomain + '$'), '');
			await localStorage.setItem('domain', newDomain);
			await localStorage.setItem('subdomain', entry.subdomain);
		}
		window.location = '/?page=editor';
	};

	const onDelete = async (domain, subdomain) => {
		console.log({ domain: domain, subdomain: subdomain });
		if (!authIsLoggedInValue) {
			addNotification({
				text: 'Unauthorized!',
				position: 'top-right',
				type: 'danger',
				removeAfter: 5000,
			});
			return;
		}
		let response;
		isBusy = true;
		try {
			const { id, userId, otp } = (await localStorage.getItem('otp')) || {};
			if (!id || !userId || !otp) {
				isBusy = false;
				return;
			}
			response = await axios.post('/api/delete', { id, username: userId, otp, subdomain, domain });
			window.location.reload();
		} catch (err) {
			addNotification({
				text: err?.response?.data?.message || err?.response?.statusText || genericErrorMessage,
				position: 'top-right',
				type: 'danger',
				removeAfter: 5000,
			});
		}
		isBusy = false;
	};

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});
</script>

<div class="proteus-entries">
	{#each sites as entry}
		<div class="proteus-entry">
			<div class="proteus-entry-col" style="flex: 1;">
				<div class="proteus-entry-title">
					<strong>
						{entry.id}
					</strong>
				</div>
				<div class="proteus-entry-meta">
					<time class="proteus-entry-time">{new Date(entry.createdAt).toLocaleString()}</time>
				</div>
			</div>
			<!-- <div class="proteus-entry-col">
				<div class="proteus-entry-title">
					<strong>
						{entry.subdomain}
					</strong>
				</div>
				<div class="proteus-entry-meta">
					<time class="proteus-entry-time">Domain</time>
				</div>
			</div> -->
			<div class="proteus-entry-col is-action" style="flex: 0;">
				<span on:click={(e) => onEdit(e, entry)} class="proteus-icon is-clickable is-info">
					<IconEdit />
				</span>
				<Confirm confirmTitle="Yes" cancelTitle="No" let:confirm={confirmThis}>
					<span on:click={() => confirmThis(() => onDelete(entry.id, entry.subdomain))} class="proteus-icon is-clickable is-error">
						<IconRemove />
					</span>
					<span slot="title">Delete page?</span>
					<span slot="description">This will delete the selected page. You won't be able to revert this!</span>
				</Confirm>
			</div>
		</div>
	{/each}
</div>

{#if isBusy}
	<LoaderFullscreen position="fixed" />
{/if}
