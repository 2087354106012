<script>
	import IconRemove from '../../icons/IconRemove.svelte';
	import {
		getIkResponseData,
		getImageAsBase64,
		getImageKit,
		getSelectedImage,
		uploadImage,
		uploadToImageKit,
	} from '../../utils';

	import { bodyBgBase64, bodyBgPattern } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyBgBase64 } from '../../stores/body-bg/UpdateBodyBgBase64';
	import { updateBodyBgPattern } from '../../stores/body-bg/UpdateBodyBgPattern';

	let bodyBgBase64Value;
	let bodyBgPatternValue;
	let file;
	let isBusy = false;

	bodyBgBase64.subscribe((value) => {
		bodyBgBase64Value = value;
	});

	bodyBgPattern.subscribe((value) => {
		bodyBgPatternValue = value;
	});

	const onBodyBgPattternClear = () => {
		updateBodyBgBase64();
		updateBodyBgPattern();
	};

	const onImageChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}
		try {
			const base64 = await getImageAsBase64(file);
			updateBodyBgBase64(base64);
			const ikResponse = await uploadToImageKit(file, file.name);
			const ikResponseData = getIkResponseData(file, ikResponse);
			// console.log(ikResponseData);
			updateBodyBgPattern(ikResponseData);
			updateBodyBgBase64();
		} catch (err) {
			console.log('Body bg pattern change error - ', err);
			return;
		}
	};
</script>

<div class="proteus-control proteus-group">
	<label for="body-bg-file" class="proteus-file-zone">
		<span class="proteus-file-zone-name">
			<!-- {file ? file.name : 'Select Background'} -->
			{file || bodyBgPatternValue?.upload?.url || bodyBgBase64Value ? 'Change Background' : 'Select Background'}
		</span>
		<input
			type="file"
			on:change={onImageChange}
			name="body-bg"
			id="body-bg-file"
			disabled={isBusy}
			class="proteus-file-zone-file"
		/>
		{#if file || bodyBgPatternValue?.upload?.url || bodyBgBase64Value}
			<span on:click|preventDefault={onBodyBgPattternClear} class="proteus-file-zone-remove">
				<IconRemove />
			</span>
		{/if}
	</label>
</div>
