import { resetImageId } from './ResetImageId';
import { resetImageAlt } from './ResetImageAlt';
import { resetImageTitle } from './ResetImageTitle';
import { resetImageBase64 } from './ResetImageBase64';
import { resetImageUpload } from './ResetImageUpload';

export const resetImage = () => {
	resetImageUpload();
	resetImageBase64();
	resetImageId();
	resetImageAlt();
	resetImageTitle();
};
