<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import { onMount } from 'svelte';
	import { publishDomain } from './stores/publish/PublishStore';
	import LoaderAlt from './LoaderAlt.svelte';
	import SubscribeButton from './SubscribeButton.svelte';
	import LoaderFullscreen from './LoaderFullscreen.svelte';
	import { authSubscription } from './stores/auth/AuthStore';

	let error = null;
	let isBusy = false;
	let isTrial = true;

	let publishDomainValue;
	publishDomain.subscribe((value) => {
		publishDomainValue = value;
	});

	let authSubscriptionValue;
	authSubscription.subscribe((value) => {
		authSubscriptionValue = value;
		isTrial = value?.status !== 'active';
		if (isTrial) {
			document.body.classList.add('has-popup');
		}
	});

	const onCancel = async () => {
		document.body.classList.remove('has-popup');
		isTrial = false;
		try {
			window.tinymce.activeEditor.focus();
		} catch (err) {
			console.log('Cancel popup error - could not focus editor - ', err);
		}
	};
</script>

{#if isTrial && !isBusy}
	<div class="stripe-form">
		<em>
			<span> a warm welcome to your new webpage! </span>
		</em>

		<h1>{publishDomainValue}.4t4.io</h1>

		<p>
			With this free version you can create content and deploy changes instantly
			<br />
			<a href="https://walkthrough.4t4.io">
				<strong> click here </strong>
			</a>
			for a complete walk through
		</p>

		<p>
			<span> to visit your control panel click bottom left </span>
		</p>

		<p>If you want to create extra pages and start selling via Stripe payment links or storing and selling digital files you can upgrade at any time</p>

		<p>
			<SubscribeButton />
		</p>

		<p>Click the link above to upgrade or visit your control panel</p>

		<a href="/?page=editor" on:click|preventDefault={onCancel}>
			<h2>
				<em> click to clear page and start creating </em>
			</h2>
		</a>

		{#if error}
			<p class="error">{error.message}</p>
		{/if}
	</div>
{/if}

{#if isBusy}
	<LoaderFullscreen />
{/if}

<style>
	div {
		width: 960px;
		max-width: 90%;
		margin: auto;
		padding: 2rem;
		background-color: #e2e2e2;
		font-weight: 300;
		text-align: center;
	}

	p {
		margin: 1.5rem auto;
		font-size: 0.9rem;
	}

	span {
		color: #777;
	}

	h1 {
		margin: 1rem auto;
		color: var(--theme-primary);
		font-weight: 100;
	}

	h2 {
		color: var(--theme-secondary);
		font-weight: inherit;
	}

	.error {
		color: red;
		text-align: center;
	}
</style>
