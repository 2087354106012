<script>
	import Loader from './Loader.svelte';

	export let position = 'absolute';
</script>

<div style={`position: ${position}`}>
	<Loader />
</div>

<style>
	div {
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.66), rgba(255, 255, 255, 1));
		border-radius: inherit;
	}
</style>
