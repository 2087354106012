<script>
	import Switch from '../../Switch.svelte';

	import { seoOgIsActive } from '../../stores/seo/SeoStore';
	import { updateSeoOgIsActive } from '../../stores/seo/UpdateSeoOgIsActive';

	let seoOgIsActiveValue;

	seoOgIsActive.subscribe((value) => {
		seoOgIsActiveValue = value;
	});

	const onSeoOgIsActiveChange = (event) => updateSeoOgIsActive(event.detail);
</script>

<div class="proteus-control proteus-group proteus-switch-group">
	<Switch checked={seoOgIsActiveValue} label="Custom OG Settings" on:input={onSeoOgIsActiveChange} />
</div>
