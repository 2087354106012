import { writable } from 'svelte/store';
import {
	seoFaviconDefault,
	seoOgIsActiveDefault,
	seoOgTitleDefault,
	seoOgDescriptionDefault,
	seoOgImageDefault,
	seoOgTypeDefault,
	seoOgTwitterCardDefault,
	seoOgTwitterTitleDefault,
	seoOgTwitterDescriptionDefault,
	seoOgTwitterSiteDefault,
	seoOgTwitterCreatorDefault,
	seoOgTwitterImageDefault,
} from './SeoStoreDefaults';

// favicon
export const seoFavicon = writable(seoFaviconDefault);

// is custom override
export const seoOgIsActive = writable(seoOgIsActiveDefault);

// og title
export const seoOgTitle = writable(seoOgTitleDefault);

// og description
export const seoOgDescription = writable(seoOgDescriptionDefault);

// og image
export const seoOgImage = writable(seoOgImageDefault);

// og locale
export const seoOgType = writable(seoOgTypeDefault);

// og twitter card
export const seoOgTwitterCard = writable(seoOgTwitterCardDefault);

// og twitter title
export const seoOgTwitterTitle = writable(seoOgTwitterTitleDefault);

// og twitter description
export const seoOgTwitterDescription = writable(seoOgTwitterDescriptionDefault);

// og twitter site
export const seoOgTwitterSite = writable(seoOgTwitterSiteDefault);

// og twitter creator
export const seoOgTwitterCreator = writable(seoOgTwitterCreatorDefault);

// og twitter image
export const seoOgTwitterImage = writable(seoOgTwitterImageDefault);
