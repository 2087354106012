// type
export const jsonldTypeDefault = 'article';

// article type
export const jsonldArticleTypeDefault = 'BlogPosting';

// name
export const jsonldNameDefault = '';

// title
export const jsonldTitleDefault = '';

// description
export const jsonldDescriptionDefault = '';

// keywords
export const jsonldKeywordsDefault = '';

// image
export const jsonldImageDefault = '';

// url
export const jsonldUrlDefault = '';

// page
export const jsonldPageDefault = '';

// author name
export const jsonldAuthorNameDefault = '';

// author url
export const jsonldAuthorUrlDefault = '';

// publisher name
export const jsonldPublisherNameDefault = '';

// publisher url
export const jsonldPublisherUrlDefault = '';

// performer name
export const jsonldPerformerNameDefault = '';

// performer url
export const jsonldPerformerUrlDefault = '';

// organizer name
export const jsonldOrganizerNameDefault = '';

// organizer url
export const jsonldOrganizerUrlDefault = '';

// job title
export const jsonldJobTitleDefault = '';

// job company
export const jsonldJobCompanyDefault = '';

// email
export const jsonldEmailDefault = '';

// telephone
export const jsonldTelephoneDefault = '';

// twitter
export const jsonldTwitterDefault = '';

// facebook
export const jsonldFacebookDefault = '';

// linkedin
export const jsonldLinkedinDefault = '';

// instagram
export const jsonldInstagramDefault = '';

// youtube
export const jsonldYouTubeDefault = '';

// github
export const jsonldGitHubDefault = '';

// latitude
export const jsonldLatitudeDefault = '';

// longitude
export const jsonldLongitudeDefault = '';

// currency value
export const jsonldCurrencyValueDefault = '';

// currency
export const jsonldCurrencyDefault = 'USD';

// business function
export const jsonldBusinessFunctionDefault = '';

// accepted payment methods
export const jsonldAcceptedPaymentMethodsDefault = '';

// ingredient
export const jsonldIngredientDefault = '';

// receipe yield
export const jsonldRecipeYieldDefault = '';

// receipe category
export const jsonldRecipeCategoryDefault = '';

// receipe cuisine
export const jsonldRecipeCuisineDefault = '';

// instructions
export const jsonldInstructionsDefault = '';

// start time
export const jsonldStartTimeDefault = '';

// end time
export const jsonldEndTimeDefault = '';

// address street
export const jsonldAddressStreetDefault = '';

// address locality
export const jsonldAddressLocalityDefault = '';

// address code
export const jsonldAddressPostalCodeDefault = '';

// address region
export const jsonldAddressRegionDefault = '';

// address country
export const jsonldAddressCountryDefault = '';

// brand name
export const jsonldBrandNameDefault = '';

// sku
export const jsonldSkuDefault = '';

// review name
export const jsonldReviewNameDefault = '';

// review body
export const jsonldReviewBodyDefault = '';

// review author
export const jsonldReviewAuthorDefault = '';

// review publisher
export const jsonldReviewPublisherDefault = '';

// review rating value
export const jsonldReviewRatingValueDefault = '';

// review best rating
export const jsonldReviewBestRatingDefault = '';

// aggregate rating value
export const jsonldAggregateRatingValueDefault = '';

// aggregate rating count
export const jsonldAggregateRatingCountDefault = '';

// aggregate rating best rating
export const jsonldAggregateRatingBestRatingDefault = '';
