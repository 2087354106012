<script>
	import { jsonldAggregateRatingBestRating } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldAggregateRatingBestRating } from '../../stores/jsonld/UpdateJsonldAggregateRatingBestRating';

	let jsonldAggregateRatingBestRatingValue;

	jsonldAggregateRatingBestRating.subscribe((value) => {
		jsonldAggregateRatingBestRatingValue = value;
	});

	const onJsonldAggregateRatingBestRatingChange = (event) =>
		updateJsonldAggregateRatingBestRating(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldAggregateRatingBestRating">Aggregate Rating Best Rating:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldAggregateRatingBestRating"
		value={jsonldAggregateRatingBestRatingValue}
		on:input={onJsonldAggregateRatingBestRatingChange}
	/>
</div>
