<script>
	import ColorPicker from '../../ColorPicker.svelte';

	import { headerBgGradient1 } from '../../stores/header-bg/HeaderBgStore';
	import { updateHeaderBgGradient1 } from '../../stores/header-bg/UpdateHeaderBgGradient1';
	import { resetHeaderBgGradient1 } from '../../stores/header-bg/ResetHeaderBgGradient1';

	let headerBgGradient1Value;

	headerBgGradient1.subscribe((value) => {
		headerBgGradient1Value = value;
	});

	const onHeaderBgGradient1Change = (event) => updateHeaderBgGradient1(event.detail);

	const onHeaderBgGradient1Reset = () => resetHeaderBgGradient1();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Color 1:
		<span class="proteus-reset" on:click={onHeaderBgGradient1Reset}>Reset</span>
	</label>
	<ColorPicker value={headerBgGradient1Value} on:input={onHeaderBgGradient1Change} />
</div>
