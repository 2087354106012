<script>
	import { jsonldFacebook } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldFacebook } from '../../stores/jsonld/UpdateJsonldFacebook';

	let jsonldFacebookValue;

	jsonldFacebook.subscribe((value) => {
		jsonldFacebookValue = value;
	});

	const onJsonldFacebookChange = (event) => updateJsonldFacebook(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldFacebook">Facebook:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldFacebook"
		value={jsonldFacebookValue}
		on:input={onJsonldFacebookChange}
	/>
</div>
