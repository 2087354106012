<script>
	import colorParse from 'color-parse';
	import colorSpace from 'color-space';
	import { createEventDispatcher } from 'svelte'
	export let label = 'Code';
	export let value = '';
	let h = 180;
	let s = 50;
	let l = 50;
	let a = 1;

	const dispatch = createEventDispatcher()

	$: dataChanged(value);
	function dataChanged(value) {
		onCodeChange();
	}

	const onChage = () => {
		value = `hsla(${h}, ${s}%, ${l}%, ${a})`;
		dispatch('input', value);
	}

	const onCodeChange = () => {
		if (!value) {
			return dispatch('input', value);
		}
		try {
			const color = colorParse(value);
			const alpha = isNaN(color.alpha) ? 1 : color.alpha;
			const hsl = colorSpace[color.space]['hsl'](color.values);
			h = parseFloat(hsl[0].toFixed(2));
			s = parseFloat(hsl[1].toFixed(2));
			l = parseFloat(hsl[2].toFixed(2));
			a = parseFloat(alpha.toFixed(2));
			dispatch('input', value);
		} catch (err) {
			console.log('onCodeChange - error while converting color space', err);
		}
	}
</script>

<div style={`--h: ${h}; --l: ${l}%; --s: ${s}%; --a: ${a};`} class="proteus-color-picker">
	<label class="proteus-control proteus-color-picker-input">
		<!-- <span>{label}</span> -->
		<input type="text" name="code" bind:value={value} on:input={onCodeChange} />
	</label>

	<label class="proteus-control">
		<span>Hue: {h}</span>
		<input type="range" bind:value={h} min="0" max="360" step="0.1" name="h" on:input={onChage} />
	</label>

	<label class="proteus-control">
		<span>Saturation: {s}%</span>
		<input type="range" bind:value={s} min="0" max="100" step="0.1" name="s" on:input={onChage} />
	</label>

	<label class="proteus-control">
		<span>Lightness: {l}%</span>
		<input type="range" bind:value={l} min="0" max="100" step="0.1" name="l" on:input={onChage} />
	</label>

	<label class="proteus-control">
		<span>Alpha: {a}</span>
		<input type="range" bind:value={a} min="0" max="1" step="0.01" name="a" on:input={onChage} />
	</label>
</div>

<style>
	.proteus-color-picker {
		position: relative;
	}

	input[type="range"] {
		background-image: var(--background-image);
	}

	input[type="range"]::-webkit-slider-runnable-track {
		background-image: var(--background-image);
	}

	input[type="range"]:focus::-webkit-slider-runnable-track {
		background-image: var(--background-image);
	}

	input[type="range"]::-moz-range-track {
		background-image: var(--background-image);
	}

	input[type="range"]::-ms-track {
		background-image: var(--background-image);
	}

	input[type="range"]::-ms-fill-lower {
		background-image: var(--background-image);
	}

	input[type="range"]::-ms-fill-upper {
		background-image: var(--background-image);
	}

	input[type="range"]:focus::-ms-fill-lower {
		background-image: var(--background-image);
	}

	input[type="range"]:focus::-ms-fill-upper {
		background-image: var(--background-image);
	}

	[name="h"] {
		--s: 100%;
		--l: 50%;
	}

	input[name="h"] {
		/* credit to Jamie Kudla: https://codepen.io/JKudla/pen/GpYXxZ */
		--background-image: linear-gradient(
			to right,
			hsl(0, 100%, 50%) 0%,
			hsl(30, 100%, 50%) 8.3%,
			hsl(60, 100%, 50%) 16.6%,
			hsl(90, 100%, 50%) 25%,
			hsl(120, 100%, 50%) 33.3%,
			hsl(150, 100%, 50%) 41.6%,
			hsl(180, 100%, 50%) 50%,
			hsl(210, 100%, 50%) 58.3%,
			hsl(240, 100%, 50%) 66.6%,
			hsl(270, 100%, 50%) 75%,
			hsl(300, 100%, 50%) 83.3%,
			hsl(330, 100%, 50%) 91.6%,
			hsl(360, 100%, 50%) 100%
		);
	}

	[name="s"] {
		--background-image: linear-gradient(
			to right,
			hsl(var(--h), 0%, var(--l)) 0%,
			hsl(var(--h), 100%, var(--l)) 100%
		);
		filter: invert(10%);
	}

	[name="l"] {
		--background-image: linear-gradient(
			to right,
			hsl(var(--h), var(--s), 0%) 0%,
			hsl(var(--h), var(--s), 50%) 50%,
			hsl(var(--h), var(--s), 100%) 100%
		);
	}

	[name="a"] {
		--background-image: linear-gradient(
			to right,
			hsla(var(--h), var(--s), var(--l), 0%) 00%,
			hsla(var(--h), var(--s), var(--l), 100%) 100%
		);
	}

	[type='range']::-moz-range-thumb {

		height: var(--range-thumb-size);
		width: var(--range-thumb-size);
		background-color: hsl(var(--h), var(--s), var(--l));
		/* border: 2px solid #fff; */
		border: none;
		border-radius: 50%;
		box-shadow: var(--box-shadow);
	}
	[type='range']::-webkit-slider-thumb {

		height: var(--range-thumb-size);
		width: var(--range-thumb-size);
		background-color: hsl(var(--h), var(--s), var(--l));
		/* border: 2px solid #fff; */
		border: none;
		border-radius: 50%;
		box-shadow: var(--box-shadow);
	}
	[type='range']::-ms-thumb {
		height: var(--range-thumb-size);
		width: var(--range-thumb-size);
		background-color: hsl(var(--h), var(--s), var(--l));
		/* border: 2px solid #fff; */
		border: none;
		border-radius: 50%;
		box-shadow: var(--box-shadow);
	}
</style>
