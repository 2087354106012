<script>
	import IconCheckmarAnimated from '../../icons/IconCheckmarAnimated.svelte';

	import { publishUrl } from '../../stores/publish/PublishStore';
	import { resetPublishUrl } from '../../stores/publish/ResetPublishUrl';
	import { updateToolbarSave } from '../../stores/toolbar/UpdateToolbarSave';
	import { updateToolbarSaveAs } from '../../stores/toolbar/UpdateToolbarSaveAs';
	import ToolbarOverlay from '../ToolbarOverlay.svelte';

	let publishUrlValue;

	publishUrl.subscribe((value) => {
		publishUrlValue = value;
	});

	const onSubmit = () => {
		updateToolbarSave(false);
		updateToolbarSaveAs(false);
		resetPublishUrl();
	};
</script>

<div class="proteus-modal is-active">
	<!-- <ToolbarOverlay /> -->
	<div class="proteus-modal-dialog">
		<IconCheckmarAnimated />
		<h3 class="title">Success</h3>
		<p>Your site was successfully deployed to</p>
		<p>
			<a target="_blank" href={publishUrlValue}>{publishUrlValue}</a>
		</p>
		<div class="proteus-control proteus-group proteus-toolbar-dropdown-footer">
			<button class="proteus-button is-primary" on:click={onSubmit} type="button"> Continue </button>
		</div>
	</div>
</div>

<style>
	.proteus-modal-dialog {
		padding-top: 2rem;
		text-align: center;
	}

	.proteus-modal-dialog :global(svg) {
		display: block;
		width: 96px;
		height: 96px;
		margin: 0 auto;
	}

	.title {
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-family: inherit;
		font-size: 2rem;
		font-weight: bold;
	}

	a {
		color: var(--theme-primary);
	}

	button {
		margin-top: 2rem;
	}
</style>
