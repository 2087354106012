<script>
	import IconStripe from '../../icons/IconStripe.svelte';

	import { editorFocusedEle } from '../../stores/editor/EditorStore';
	import { isToolbarStripeActive } from '../../stores/toolbar/ToolbarStore';
	import { updateToolbarStripe } from '../../stores/toolbar/UpdateToolbarStripe';

	let editorFocusedEleValue;
	let isToolbarStripeActiveValue;

	editorFocusedEle.subscribe((value) => {
		editorFocusedEleValue = value;
	});

	isToolbarStripeActive.subscribe((value) => {
		isToolbarStripeActiveValue = value;
	});

	const onToggle = () => updateToolbarStripe(!isToolbarStripeActiveValue);
</script>

<button class="proteus-toolbar-button" on:click={onToggle} disabled={!editorFocusedEleValue}>
	<IconStripe />
</button>
