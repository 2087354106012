// favicon default
export const seoFaviconDefault = {};

// custom og data
export const seoOgIsActiveDefault = false;

// og title default
export const seoOgTitleDefault = '';

// og description default
export const seoOgDescriptionDefault = '';

// og image default
export const seoOgImageDefault = {};

// og type default
export const seoOgTypeDefault = 'website';

// og twitter card default
export const seoOgTwitterCardDefault = 'summary';

// og twitter title default
export const seoOgTwitterTitleDefault = '';

// og twitter description default
export const seoOgTwitterDescriptionDefault = '';

// og twitter title default
export const seoOgTwitterSiteDefault = '';

// og twitter title default
export const seoOgTwitterCreatorDefault = '';

// og twitter image default
export const seoOgTwitterImageDefault = {};
