import { writable } from 'svelte/store';
import {
	imageIsBusyDefault,
	imageAllDefault,
	imageIdDefault,
	imageAltDefault,
	imageTitleDefault,
	imageBase64Default,
	imageUploadDefault,
} from './ImageStoreDefaults';

export const imageIsBusy = writable(imageIsBusyDefault);

export const imageAll = writable(imageAllDefault);

export const imageId = writable(imageIdDefault);

export const imageAlt = writable(imageAltDefault);

export const imageTitle = writable(imageTitleDefault);

export const imageBase64 = writable(imageBase64Default);

export const imageUpload = writable(imageUploadDefault);
