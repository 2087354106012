<script>
	import { jsonldType } from '../../stores/jsonld/JsonldStore';

	import ToolbarJsonldName from './ToolbarJsonldName.svelte';
	import ToolbarJsonldDescription from './ToolbarJsonldDescription.svelte';
	import ToolbarJsonldKeywords from './ToolbarJsonldKeywords.svelte';
	import ToolbarJsonldAuthorName from './ToolbarJsonldAuthorName.svelte';
	import ToolbarJsonldAuthorUrl from './ToolbarJsonldAuthorUrl.svelte';
	import ToolbarJsonldRecipeYield from './ToolbarJsonldRecipeYield.svelte';
	import ToolbarJsonldRecipeCategory from './ToolbarJsonldRecipeCategory.svelte';
	import ToolbarJsonldRecipeCuisine from './ToolbarJsonldRecipeCuisine.svelte';
	import ToolbarJsonldIngredient from './ToolbarJsonldIngredient.svelte';
	import ToolbarJsonldInstructions from './ToolbarJsonldInstructions.svelte';
	import ToolbarJsonldAggregateRatingValue from './ToolbarJsonldAggregateRatingValue.svelte';
	import ToolbarJsonldAggregateRatingBestRating from './ToolbarJsonldAggregateRatingBestRating.svelte';
	import ToolbarJsonldAggregateRatingCount from './ToolbarJsonldAggregateRatingCount.svelte';

	let jsonldTypeValue;

	jsonldType.subscribe((value) => {
		jsonldTypeValue = value;
	});
</script>

{#if jsonldTypeValue === 'recipe'}
	<ToolbarJsonldName />
	<ToolbarJsonldDescription />
	<ToolbarJsonldKeywords />
	<ToolbarJsonldAuthorName />
	<ToolbarJsonldAuthorUrl />
	<ToolbarJsonldRecipeYield />
	<ToolbarJsonldRecipeCategory />
	<ToolbarJsonldRecipeCuisine />
	<ToolbarJsonldIngredient />
	<ToolbarJsonldInstructions />
	<ToolbarJsonldAggregateRatingValue />
	<ToolbarJsonldAggregateRatingBestRating />
	<ToolbarJsonldAggregateRatingCount />
{/if}
