<script>
	import IconHeaderBg from '../../icons/IconHeaderBg.svelte';

	import { isToolbarHeaderActive } from '../../stores/toolbar/ToolbarStore';
	import { updateToolbarHeader } from '../../stores/toolbar/UpdateToolbarHeader';

	let isToolbarHeaderActiveValue;

	isToolbarHeaderActive.subscribe((value) => {
		isToolbarHeaderActiveValue = value;
	});

	const onToggle = () => updateToolbarHeader(!isToolbarHeaderActiveValue);
</script>

<button class="proteus-toolbar-button is-logo" on:click={onToggle} title="Logo">
	<IconHeaderBg />
</button>
