<script>
	import { jsonldOrganizerUrl } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldOrganizerUrl } from '../../stores/jsonld/UpdateJsonldOrganizerUrl';

	let jsonldOrganizerUrlValue;

	jsonldOrganizerUrl.subscribe((value) => {
		jsonldOrganizerUrlValue = value;
	});

	const onJsonldOrganizerUrlChange = (event) => updateJsonldOrganizerUrl(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldOrganizerUrl">Organizer Url:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldOrganizerUrl"
		value={jsonldOrganizerUrlValue}
		on:input={onJsonldOrganizerUrlChange}
	/>
</div>
