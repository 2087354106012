<script>
	import { updateToolbarIsFullscreen } from './stores/toolbar/UpdateToolbarIsFullscreen';
	import IconExitFullscreen from './icons/IconExitFullscreen.svelte';

	const onClick = () => {
		document.body.classList.remove('is-fullscreen');
		updateToolbarIsFullscreen(false);
	};
</script>

<button class="proteus-toolbar-button is-exit-fullscreen" title="New Page" on:click={onClick}>
	<IconExitFullscreen />
</button>
