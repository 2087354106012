// all buttons
export const stripeAllDefault = [];

// unique id
export const stripeIdDefault = '';

// secret key
export const stripeSecretKeyDefault = '';

// price id
export const stripePriceIdDefault = '';

// label / title
export const stripeTitleDefault = '';

// url
export const stripeUrlDefault = '';

// ideal
export const stripeIsIDealEnabledDefault = false;

// radius
export const stripeRadiusDefault = 0;

// background color
export const stripeBgColorDefault = 'hsla(180, 50, 50, 1)';

// foreground color
export const stripeFgColorDefault = 'hsla(180, 50, 50, 1)';
