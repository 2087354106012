<script>
	import { jsonldRecipeCategory } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldRecipeCategory } from '../../stores/jsonld/UpdateJsonldRecipeCategory';

	let jsonldRecipeCategoryValue;

	jsonldRecipeCategory.subscribe((value) => {
		jsonldRecipeCategoryValue = value;
	});

	const onJsonldRecipeCategoryChange = (event) => updateJsonldRecipeCategory(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldRecipeCategory">Category:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldRecipeCategory"
		value={jsonldRecipeCategoryValue}
		on:input={onJsonldRecipeCategoryChange}
	/>
</div>
