<script>
	import { jsonldOrganizerName } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldOrganizerName } from '../../stores/jsonld/UpdateJsonldOrganizerName';

	let jsonldOrganizerNameValue;

	jsonldOrganizerName.subscribe((value) => {
		jsonldOrganizerNameValue = value;
	});

	const onJsonldOrganizerNameChange = (event) => updateJsonldOrganizerName(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldOrganizerName">Organizer Name:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldOrganizerName"
		value={jsonldOrganizerNameValue}
		on:input={onJsonldOrganizerNameChange}
	/>
</div>
