// start logo
export const headerLogoStartDefault = {};
export const headerLogoStartAltDefault = '';
export const headerLogoStartTitleDefault = '';
export const headerLogoStartBase64Default = '';

// center logo
export const headerLogoCenterDefault = {};
export const headerLogoCenterAltDefault = '';
export const headerLogoCenterTitleDefault = '';
export const headerLogoCenterBase64Default = '';

// end logo
export const headerLogoEndDefault = {};
export const headerLogoEndAltDefault = '';
export const headerLogoEndTitleDefault = '';
export const headerLogoEndBase64Default = '';
