<script>
	import { seoOgTwitterCreator } from '../../stores/seo/SeoStore';
	import { updateSeoOgTwitterCreator } from '../../stores/seo/UpdateSeoOgTwitterCreator';

	let seoOgTwitterCreatorValue;

	seoOgTwitterCreator.subscribe((value) => {
		seoOgTwitterCreatorValue = value;
	});

	const onSeoOgCreatorChange = (event) => updateSeoOgTwitterCreator(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">OG Twitter Creator:</label>
	<input class="proteus-input" type="text" name="seoOgCreator" value={seoOgTwitterCreatorValue} on:input={onSeoOgCreatorChange} />
</div>
