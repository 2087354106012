<script>
	import { jsonldReviewRatingValue } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldReviewRatingValue } from '../../stores/jsonld/UpdateJsonldReviewRatingValue';

	let jsonldReviewRatingValueValue;

	jsonldReviewRatingValue.subscribe((value) => {
		jsonldReviewRatingValueValue = value;
	});

	const onJsonldRatingChangeValue = (event) => updateJsonldReviewRatingValue(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldReviewRatingValue">Review Rating Value:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldReviewRatingValue"
		value={jsonldReviewRatingValueValue}
		on:input={onJsonldRatingChangeValue}
	/>
</div>
