<script>
	import { imageId } from '../../stores/image/ImageStore';

	import { updateToolbarImage } from '../../stores/toolbar/UpdateToolbarImage';
	import { resetImage } from '../../stores/image/ResetImage';
	import { removeEleFromDom } from '../../utils';

	let imageIdValue;

	imageId.subscribe((value) => {
		imageIdValue = value;
	});

	const onClear = () => {
		removeEleFromDom(imageIdValue);
		resetImage();
        updateToolbarImage(false);
	};
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<button class="proteus-button is-default" on:click={onClear}>Clear</button>
</div>
