<script>
    import { authAvatarThumbUrl, authIsLoggedIn } from '../stores/auth/AuthStore';
    import IconProfile from '../icons/IconProfile.svelte';
	
    let authAvatarThumbUrlValue;
	let authIsLoggedInValue;

	authAvatarThumbUrl.subscribe((value) => {
		authAvatarThumbUrlValue = value;
	});

	authIsLoggedIn.subscribe((value) => {
		authIsLoggedInValue = value;
	});
</script>

<a class="proteus-toolbar-button is-profile" href="/?page=profile">
	{#if authAvatarThumbUrlValue}
		<img src={authAvatarThumbUrlValue} alt="" />
	{/if}
	{#if !authAvatarThumbUrlValue}
		<IconProfile />
	{/if}
</a>
