<script>
	import { oembedUrl } from '../../stores/oembed/OembedStore';
	import { updateOembedUrl } from '../../stores/oembed/UpdateOembedUrl';

	let oembedUrlValue;

	oembedUrl.subscribe((value) => {
		oembedUrlValue = value;
	});

	const onOembedUrlChange = (event) => updateOembedUrl(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="angle">YouTube/Vimeo Url:</label>
	<input class="proteus-input" type="text" name="oembedUrl" value={oembedUrlValue} on:input={onOembedUrlChange} />
</div>
