<script>
	import { bodyBgGradientAngle } from '../../stores/body-bg/BodyBgStore';
	import { updateBodyBgGradientAngle } from '../../stores/body-bg/UpdateBodyBgGradientAngle';
	import { resetBodyBgGradientAngle } from '../../stores/body-bg/ResetBodyBgGradientAngle';

	let bodyBgGradientAngleValue;

	bodyBgGradientAngle.subscribe((value) => {
		bodyBgGradientAngleValue = value;
	});

	const onBodyBgGradientAngleChange = (event) => updateBodyBgGradientAngle(event.target.value);

	const onBodyBgGradientAngleReset = () => resetBodyBgGradientAngle();
</script>

<div class="proteus-control proteus-group">
	<label class="proteus-label" for="angle">
		Gradient Angle: {bodyBgGradientAngleValue}deg
		<span class="proteus-reset" on:click={onBodyBgGradientAngleReset}>Reset</span>
	</label>
	<input
		type="range"
		min={0}
		step={1}
		max={360}
		name="angle"
		value={bodyBgGradientAngleValue}
		on:input={onBodyBgGradientAngleChange}
	/>
</div>
