import { writable } from 'svelte/store';
import { uploadErrorDefault, uploadIsBusyDefault, uploadItemsDefault } from './UploadStoreDefaults';

// all items
export const uploadItems = writable(uploadItemsDefault);

// is busy
export const uploadIsBusy = writable(uploadIsBusyDefault);

// error
export const uploadError = writable(uploadErrorDefault);
