<script>
	import axios from 'axios';
	import localStorage from 'localforage';
	import { getImageAsBase64, getSelectedImage, uploadToImageKit } from './utils';
	import IconEdit from './icons/IconEdit.svelte';
	import Loader from './Loader.svelte';

	import { getNotificationsContext } from 'svelte-notifications';
	const { addNotification } = getNotificationsContext();

	import { updateAuthAvatarUrl } from './stores/auth/UpdateAuthAvatarUrl';
	import { updateAuthAvatarThumbUrl } from './stores/auth/UpdateAuthAvatarThumbUrl';

	let file;
	let base64;
	let isBusy = false;

	const onChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}

		// check file
		if (!file || ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(file.type) === false) {
			addNotification({
				text: 'Please select a valid jpg / png / svg image',
				position: 'top-right',
				type: 'danger',
				removeAfter: 5000,
			});
			return false;
		}

		const { id: otpId, userId, otp } = (await localStorage.getItem('otp')) || {};
		if (!otpId || !userId || !otp) {
			addNotification({
				text: 'You must login before you can upload profile image.',
				position: 'top-right',
				type: 'danger',
				removeAfter: 5000,
			});
			return false;
		}

		// valid image - invoke file change
		base64 = await getImageAsBase64(file);

		// try and upload image
		isBusy = true;
		let response;
		try {
			const ikResponse = await uploadToImageKit(file, file.name);
			const { fileId: avatarId, url: avatarUrl, thumbnailUrl: avatarThumbUrl } = ikResponse;
			response = await axios.post('/api/profile', {
				otpId,
				userId,
				otp,
				avatarId,
				avatarUrl,
				avatarThumbUrl,
			});
			const user = response.data;
			updateAuthAvatarUrl(user.avatarUrl);
			updateAuthAvatarThumbUrl(user.avatarThumbUrl);
		} catch (err) {
			console.log('Failed to upload the avatar image. Please try again later.', err);
			addNotification({
				text: 'Failed to upload the avatar image. Please try again later.',
				position: 'top-right',
				type: 'danger',
				removeAfter: 5000,
			});
		}
		isBusy = false;
	};
</script>

<label for="avatar-file" class="proteus-avatar-file">
	<input type="file" id="avatar-file" on:input={onChange} class="hidden" />
	<span class="proteus-icon is-clickable is-info">
		<IconEdit />
	</span>
</label>

{#if isBusy}
	<Loader />
{/if}
