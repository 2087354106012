<script>
	import IconRemove from '../../icons/IconRemove.svelte';
	import { getIkResponseData, getSelectedImage, uploadToImageKit } from '../../utils';

	import { seoOgImage } from '../../stores/seo/SeoStore';
	import { seoOgImageDefault } from '../../stores/seo/SeoStoreDefaults';
	import { updateSeoOgImage } from '../../stores/seo/UpdateSeoOgImage';

	let seoOgImageValue;
	let file;
	let isBusy = false;

	seoOgImage.subscribe((value) => {
		seoOgImageValue = value;
	});

	const onClear = () => {
		updateSeoOgImage(seoOgImageDefault);
	};

	const onImageChange = async (e) => {
		file = getSelectedImage(e);
		if (!file) {
			return;
		}
		try {
			const ikResponse = await uploadToImageKit(file, file.name);
			updateSeoOgImage(getIkResponseData(file, ikResponse));
		} catch (err) {
			console.log('OG Image change error - ', err);
			return;
		}
	};
</script>

<div class="proteus-control proteus-group">
	<label for="seo-og-image-file" class="proteus-file-zone">
		<span class="proteus-file-zone-name">
			{file || seoOgImageValue?.upload?.url ? 'Change OG Image' : 'Select OG Image'}
		</span>
		<input
			type="file"
			on:input={onImageChange}
			name="seo-og-image"
			id="seo-og-image-file"
			disabled={isBusy}
			class="proteus-file-zone-file"
		/>
		{#if file || seoOgImageValue?.upload?.url}
			<span on:click|preventDefault={onClear} class="proteus-file-zone-remove">
				<IconRemove />
			</span>
		{/if}
	</label>
</div>
