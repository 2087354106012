<script>
	import { jsonldReviewBestRating } from '../../stores/jsonld/JsonldStore';
	import { updateJsonldReviewBestRating } from '../../stores/jsonld/UpdateJsonldReviewBestRating';

	let jsonldReviewBestRatingValue;

	jsonldReviewBestRating.subscribe((value) => {
		jsonldReviewBestRatingValue = value;
	});

	const onJsonldReviewBestRatingChange = (event) => updateJsonldReviewBestRating(event.target.value);
</script>

<div class="proteus-control proteus-group protues-ui-input">
	<label class="proteus-label" for="jsonldReviewBestRating">Review Best Rating:</label>
	<input
		class="proteus-input"
		type="text"
		name="jsonldReviewBestRating"
		value={jsonldReviewBestRatingValue}
		on:input={onJsonldReviewBestRatingChange}
	/>
</div>
